import { Edit } from '@material-ui/icons';
import MUIDataTable from 'mui-datatables';
import React, { Fragment, useContext } from 'react';

import EditRoutes from '../../components/Forms/EditRoutes';
import { ModalContext } from '../../components/Modal/ModalContext';

import Arrows from './table-arrows';
import ViewSites from './table-view-sites';

const RoutesTable = ({
  count,
  loading,
  page,
  rowsPerPage,
  setPage,
  setRowsPerPage,
  setSortColumn,
  setSortDirection,
  sortedData,
  setSortedData,
  setPageContent,
  setSelectedRoute,
}: any) => {
  const modal = useContext(ModalContext);

  return (
    <Fragment>
      <MUIDataTable
        title="Routes"
        data={sortedData || []}
        columns={[
          {
            name: 'id',
            options: {
              display: false,
              sort: false,
              searchable: false,
            },
          },
          {
            name: 'loadingOrder',
            label: 'Loading Dock Order',
            options: {
              display: false,
              customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
              ) => {
                return (
                  <Arrows
                    count={count}
                    sortedData={sortedData}
                    setSortedData={setSortedData}
                    value={value}
                    tableMeta={tableMeta}
                  />
                );
              },
            },
          },
          {
            name: 'name',
            label: 'Route Number',
            // options: {
            //   sort: false,
            // },
          },
          {
            name: 'defaultTruckNumber',
            label: 'Assigned Truck',
          },
          {
            name: 'sites',
            label: 'Assigned Sites',
            options: {
              searchable: false,
              sort: false,
              customBodyRender: (value: any, tableMeta: any) => {
                return (
                  <ViewSites
                    sortedData={sortedData}
                    value={value}
                    tableMeta={tableMeta}
                    setPageContent={setPageContent}
                    setSelectedRoute={setSelectedRoute}
                  />
                );
              },
            },
          },
          {
            name: '',
            label: '',
            options: {
              sort: false,
              customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
              ) => {
                return (
                  <Edit
                    color="primary"
                    className="edit"
                    onClick={() => {
                      modal.showModal(EditRoutes, {
                        initialValues: {
                          ...sortedData.find(
                            ({ id }: any) => id === tableMeta.rowData[0],
                          ),
                        },
                      });
                    }}
                  />
                );
              },
            },
          },
        ]}
        options={{
          count,
          download: false,
          elevation: 1,
          filter: false,
          page,
          print: false,
          rowsPerPage: Number(rowsPerPage),
          rowsPerPageOptions: [50, 100, 200],
          search: false,
          selectableRows: 'none',
          serverSide: true,
          sort: false,
          viewColumns: false,
          textLabels: {
            body: {
              noMatch: loading
                ? 'Loading data, please wait'
                : 'Sorry, no matching records found',
            },
          },
          onChangePage: (p: number) => setPage(p),
          onChangeRowsPerPage: (num: number) => {
            setRowsPerPage(num);
            setPage(0);
          },
          onColumnSortChange: (col: string, dir: string) => {
            setSortColumn(col);
            setSortDirection(dir);
          },
        }}
      />
    </Fragment>
  );
};

export default RoutesTable;
