import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
import {
  makeStyles,
  createTheme,
  ThemeProvider,
} from '@material-ui/core/styles';
import {
  Fastfood,
  Group,
  LocalShipping,
  LocalDining,
} from '@material-ui/icons';
import React, { useState } from 'react';

import Header from '../../components/CalendarDatePicker';
import { Layout } from '../../components/Layout';
import {
  useCountOrdersAndMealsSubscription,
  useCountMealTypesSubscription,
} from '../../hasura/generated';

import DashboardTable from './tables';

const Dashboard = () => {
  const currentDate = new Date();
  const oneWeeksDate = new Date(Date.now() + 604800000);
  const [startDate, setStartDate] = useState<any>(currentDate);
  const [endDate, setEndDate] = useState<any>(oneWeeksDate);
  const orderAndMealCountRes = useCountOrdersAndMealsSubscription({
    variables: {
      where: {
        archived: { _is_null: true },
        deliveryDay: { _gte: startDate, _lte: endDate },
      },
    },
  });

  const orderCount = orderAndMealCountRes.data?.orders?.aggregate?.count || 0;
  const mealCount =
    orderAndMealCountRes.data?.orders?.aggregate?.sum?.quantityOrdered || 0;
  const mealsNotLoadedCount =
    (orderAndMealCountRes.data?.orders?.aggregate?.sum?.quantityOrdered || 0) -
    (orderAndMealCountRes.data?.orders?.aggregate?.sum?.quantityLoaded || 0);

  const mealTypeCountRes = useCountMealTypesSubscription();
  const mealTypeCount = mealTypeCountRes.data?.count?.aggregate?.count || 0;

  const useStyles = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
    cardRoot: {
      height: '100%',
      minHeight: 100,
    },
    cardTitle: {
      fontWeight: 700,
    },
    cardAvatar: {
      backgroundColor: theme.palette.primary.main,
      height: 56,
      width: 56,
    },
    cardIcon: {
      height: 32,
      width: 32,
    },
  }));

  const cardsTheme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 530,
        md: 1000,
        lg: 1200,
        xl: 1536,
      },
    },
  });

  const classes = useStyles();
  return (
    <Layout>
      <div className={classes.root}>
        <Header
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
        <ThemeProvider theme={cardsTheme}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <Card className="">
                <CardContent>
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Typography
                        className={classes.cardTitle}
                        color="textSecondary"
                        gutterBottom
                        variant="body2"
                      >
                        MEALS
                      </Typography>
                      <Typography variant="h3">
                        {orderAndMealCountRes.loading ? '-' : mealCount}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Avatar className={classes.cardAvatar}>
                        <LocalDining className={classes.cardIcon} />
                      </Avatar>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card className="">
                <CardContent>
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Typography
                        className={classes.cardTitle}
                        color="textSecondary"
                        gutterBottom
                        variant="body2"
                      >
                        MEALS NOT LOADED
                      </Typography>
                      <Typography variant="h3">
                        {orderAndMealCountRes.loading
                          ? '-'
                          : mealsNotLoadedCount >= 0
                          ? mealsNotLoadedCount
                          : 0}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Avatar className={classes.cardAvatar}>
                        <LocalShipping className={classes.cardIcon} />
                      </Avatar>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card className="">
                <CardContent>
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Typography
                        className={classes.cardTitle}
                        color="textSecondary"
                        gutterBottom
                        variant="body2"
                      >
                        ORDERS
                      </Typography>
                      <Typography variant="h3">
                        {orderAndMealCountRes.loading ? '-' : orderCount}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Avatar className={classes.cardAvatar}>
                        <Group className={classes.cardIcon} />
                      </Avatar>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card className="">
                <CardContent>
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Typography
                        className={classes.cardTitle}
                        color="textSecondary"
                        gutterBottom
                        variant="body2"
                      >
                        MEAL TYPES
                      </Typography>
                      <Typography variant="h3">
                        {mealTypeCountRes.loading ? '-' : mealTypeCount}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Avatar className={classes.cardAvatar}>
                        <Fastfood className={classes.cardIcon} />
                      </Avatar>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </ThemeProvider>
        <DashboardTable />
      </div>
    </Layout>
  );
};

export default Dashboard;
