import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { useAsync } from 'react-async';
import { Router as RRouter, Route as RRoute, RouteProps } from 'react-router';
import { Switch, Redirect } from 'react-router-dom';

import { history } from './history';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import MealTypes from './pages/MealTypes';
import Orders from './pages/Orders';
import Routes from './pages/Routes';
import Users from './pages/Users';
import { authentication, readyPromise } from './stores';

const Route: FC<
  {
    auth?: 'authenticated' | 'non-authenticated';
    path: string;
    role?: string;
  } & RouteProps
> = observer(({ auth, path, role, ...props }) => {
  const isAuthenticated = authentication.isAuthenticated;

  if (
    (auth === 'authenticated' && !isAuthenticated) ||
    (auth === 'non-authenticated' && isAuthenticated)
  ) {
    return <Redirect to="/" />;
  }

  // else, lets render a normal route
  return <RRoute path={path} {...props} />;
});

export const Router: FC = () => {
  const { isPending } = useAsync({
    promise: readyPromise,
  });

  // await for the stores to load
  if (isPending) {
    return <>Loading...</>;
  }

  return (
    <RRouter history={history}>
      <Switch>
        {/* authenticated */}
        <Route
          auth="authenticated"
          exact
          path="/dashboard"
          component={Dashboard}
        />
        <Route auth="authenticated" exact path="/orders" component={Orders} />
        <Route
          auth="authenticated"
          exact
          path="/meal-types"
          component={MealTypes}
        />
        <Route auth="authenticated" exact path="/routes" component={Routes} />
        <Route auth="authenticated" exact path="/users" component={Users} />

        {/* non-authenticated */}
        <Route auth="non-authenticated" exact path="/login" component={Login} />

        <RRoute
          component={() => (
            <Redirect
              to={authentication.isAuthenticated ? '/dashboard' : '/login'}
            />
          )}
        />
      </Switch>
    </RRouter>
  );
};
