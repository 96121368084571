import MUIDataTable from 'mui-datatables';
import React from 'react';

const MenuServicesTable = ({ options }: any) => {
  const data = [
    ['Joe James', 'Test Corp', 'Yonkers', 'NY'],
    ['John Walsh', 'Test Corp', 'Hartford', 'CT'],
    ['Bob Herm', 'Test Corp', 'Tampa', 'FL'],
    ['James Houston', 'Test Corp', 'Dallas', 'TX'],
  ];

  const columns = ['Menu Service', 'Company', 'City', 'State'];

  return (
    <MUIDataTable title="" data={data} columns={columns} options={options} />
  );
};

export default MenuServicesTable;
