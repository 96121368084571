import MUIDataTable from 'mui-datatables';
import React from 'react';

const SitesTable = ({ data }: any) => {
  return (
    <MUIDataTable
      title=""
      data={data}
      columns={[
        { name: 'name', label: 'Site' },
        { name: 'address1', label: 'Address' },
        { name: 'address2', label: 'Address 2' },
        { name: 'city', label: 'City' },
        { name: 'state', label: 'State' },
        { name: 'zip', label: 'Zip Code' },
      ]}
      options={{
        search: false,
        elevation: 1,
        pagination: true,
        rowsPerPage: 100,
        rowsPerPageOptions: [25, 50, 100],
        selectableRows: 'none',
      }}
    />
  );
};

export default SitesTable;
