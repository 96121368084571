import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import React from 'react';
import { toast } from 'react-toastify';

import { useArchiveMealTypeMutation } from '../../../hasura/generated';
import handleToastError from '../../../helpers/handleToastError';
import ResponsiveDialog from '../../ResponsiveDialog';

const ArchiveMealTypesWrapper = (props: any) => {
  const [archiveMealType] = useArchiveMealTypeMutation({
    onCompleted() {
      toast('Meal Type Archived');
    },
    onError(error) {
      handleToastError(error);
    },
  });

  const archiveFunc = () => {
    const id = props?.initialValues?.id;
    const archived = new Date();

    return archiveMealType({
      variables: {
        id,
        archived,
      },
    });
  };

  return <ArchiveMealTypesForm archiveFunc={archiveFunc} {...props} />;
};

export default ArchiveMealTypesWrapper;

const ArchiveMealTypesForm = (props: any) => {
  const { archiveFunc, onRequestClose } = props;

  const archiveAndReset = () => {
    archiveFunc().then((resData: any) => {
      if (resData) {
        onRequestClose();
      }
    });
  };

  return (
    <ResponsiveDialog open onClose={onRequestClose}>
      <form>
        <DialogTitle id="form-dialog-title">Archive Meal Type</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to archive this meal type?
          </DialogContentText>
          <DialogActions
            style={{
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 10,
              margin: 'auto',
            }}
          >
            <Button
              onClick={onRequestClose}
              color="primary"
              // disabled={formik.isSubmitting}
            >
              Cancel
            </Button>
            <Button
              onClick={archiveAndReset}
              color="secondary"
              variant="outlined"
              // disabled={formik.isSubmitting}
              // style={{ marginRight: 'auto' }}
            >
              Archive
            </Button>
          </DialogActions>
        </DialogContent>
      </form>
    </ResponsiveDialog>
  );
};
