import {
  FormControl,
  FormHelperText,
  Select,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
// import PropTypes from 'prop-types';
import React from 'react';

export interface SelectOption {
  value: string;
  label: string;
  disabled?: boolean;
}

const DropDownMenuV2 = ({
  name,
  nullable = true,
  label: formLabel,
  helperText,
  required,
  options,
  value,
  touched,
  onChange,
  error,
  ...custom
}: any) => {
  return (
    <FormControl
      {...custom}
      variant="standard"
      error={Boolean(touched && error)}
      size="small"
    >
      <InputLabel required={required} error={Boolean(touched && error)}>
        {formLabel}
      </InputLabel>
      <Select name={name} onChange={onChange} value={value}>
        {nullable && <MenuItem aria-label="None" value="" />}
        {options.map(({ label, value, disabled }: SelectOption) => (
          <MenuItem key={value} value={value} disabled={disabled}>
            {label}
          </MenuItem>
        ))}
      </Select>
      {(helperText || (touched && error)) && (
        <FormHelperText error={Boolean(touched && error)}>
          {touched || error ? error : helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

// DropDownMenuV2.propTypes = {
//   label: PropTypes.string.isRequired,
//   options: PropTypes.array.isRequired,
//   helperText: PropTypes.string,
//   required: PropTypes.bool,
//   name: PropTypes.string.isRequired,
//   error: PropTypes.object,
// };

DropDownMenuV2.defaultProps = {
  helperText: undefined,
  required: false,
};

export default DropDownMenuV2;
