import {
  PublicClientApplication,
  LogLevel,
  SilentRequest,
} from '@azure/msal-browser';
import jwtDecode from 'jwt-decode';
import mem, { memClear } from 'mem/dist';
import { action, computed, observable } from 'mobx';
import { persist } from 'mobx-persist';
import { toast } from 'react-toastify';

import {
  APP_ENV,
  AUTH_PROACTIVE_SESSION_RENEWAL_INTERVAL_MS,
  AZURE_AUTHORITY_URL,
  AZURE_CLIENT_ID,
} from '../config';
import { isEdge, isIE, isFirefox } from '../constants';
import { apolloClient, resetClient } from '../hasura/client';
import {
  AuthAzureAdSignInDocument,
  AuthAzureAdSignInMutation,
  AuthAzureAdSignInMutationVariables,
  AuthSessionRenewDocument,
  AuthSessionRenewMutation,
  AuthSessionRenewMutationVariables,
  GetUserDocument,
  GetUserQuery,
  GetUserQueryVariables,
} from '../hasura/generated';

// TODO: change this to something that gets feeded from the graphql codegen interfaces
export type User = any;

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: SilentRequest = {
  scopes: ['openid', 'profile', 'User.Read', 'offline_access'],
};

const pca = new PublicClientApplication({
  auth: {
    clientId: AZURE_CLIENT_ID,
    authority: AZURE_AUTHORITY_URL,
    redirectUri: '/',
    postLogoutRedirectUri: '/',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
  system: {
    loggerOptions:
      (APP_ENV !== 'production' && {
        piiLoggingEnabled: false,
        logLevel: LogLevel.Info,
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              console.info(message);
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
            default:
              return;
          }
        },
      }) ||
      undefined,
  },
});

export default class Authentication {
  constructor() {
    // Authentication Proactive Session Renewal
    setInterval(async () => {
      await this.memoizedRenewSession();
    }, AUTH_PROACTIVE_SESSION_RENEWAL_INTERVAL_MS);
  }

  @observable public isLoading = true;
  @observable public isReady = false;

  // @persist('object')
  @observable
  public userData?: User;

  @persist @observable public accessToken?: string;
  @persist @observable public refreshToken?: string;

  @computed public get isAuthenticated(): boolean {
    const accessToken = this.accessToken;
    const refreshToken = this.refreshToken;

    return !!accessToken && !!refreshToken;
  }

  @computed public get user() {
    if (!this.accessToken) {
      return undefined;
    }

    return this.userData;
  }

  @computed public get decodedAccessToken() {
    if (!this.accessToken) {
      return null;
    }

    return jwtDecode<HFBJWTPayload>(this.accessToken);
  }

  @computed public get currentRole(): string | undefined {
    return (this.decodedAccessToken?.rl as string) || undefined;
  }

  @computed public get userId(): string | null {
    return this.decodedAccessToken?.uid || null;
  }

  public async setup() {
    if (this.isAuthenticated) {
      try {
        this.loadUserData();
      } catch {
        // do nothing
      }
    }

    this.isLoading = false;
    this.isReady = true;
  }

  public getHeaders(prevHeaders?: Record<string, string>) {
    const headers: Record<string, string> = {
      Authorization: this.accessToken ? `Bearer ${this.accessToken}` : '',
      ...prevHeaders,
    };

    // delete undefined headers
    for (const [key, value] of Object.entries(headers)) {
      if (!value) {
        delete headers[key];
      }
    }

    return headers;
  }

  @action public async loadUserData(userId: string | null = this.userId) {
    if (!userId) {
      return;
    }

    const res = await apolloClient.query<GetUserQuery, GetUserQueryVariables>({
      query: GetUserDocument,
      variables: { userId },
      fetchPolicy: 'network-only',
    });

    this.setUserData(res.data.user as unknown as User);
  }

  @action public setUserData(userData?: User) {
    this.userData = userData || undefined;
  }
  @action public setTokens(tokens: {
    accessToken?: string;
    refreshToken?: string;
  }) {
    this.accessToken = tokens.accessToken || undefined;
    this.refreshToken = tokens.refreshToken || undefined;
    memClear(this.memoizedRenewSession);
  }

  @action public async login() {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const azureRes = await pca.acquireTokenPopup(loginRequest);

      // pass on the access token to the graphql azure auth api
      const hasuraRes = await apolloClient.mutate<
        AuthAzureAdSignInMutation,
        AuthAzureAdSignInMutationVariables
      >({
        mutation: AuthAzureAdSignInDocument,
        variables: {
          azureAdAccessToken: azureRes.accessToken,
          azureAdIdToken: azureRes.idToken,
        },
      });

      if (hasuraRes.data?.tokens) {
        this.setTokens(hasuraRes.data.tokens);
        await this.loadUserData();
      }

      await resetClient();
    } catch (error) {
      if (String(error).includes('AADSTS50105')) {
        toast(
          "You don't have access to this application, contact your administrator",
          {
            toastId: 'No application access',
            autoClose: 4000,
            type: 'warning',
          },
        );
      }
    } finally {
      this.isLoading = false;
    }
  }

  memoizedRenewSession = mem(this.renewSession.bind(this), {
    maxAge: 60 * 1000,
  });

  public async renewSession() {
    if (!this.refreshToken) {
      return;
    }

    try {
      // take the refresh token and execute the AuthSessionRenew operation to get a new set of tokens
      const hasuraRes = await apolloClient.mutate<
        AuthSessionRenewMutation,
        AuthSessionRenewMutationVariables
      >({
        mutation: AuthSessionRenewDocument,
        variables: {
          refreshToken: this.refreshToken,
        },
      });

      if (hasuraRes.data?.tokens) {
        this.setTokens(hasuraRes.data.tokens);
        await this.loadUserData();
      }

      // await resetClient();
    } catch (error) {
      this._logout();
      toast(`Your session has expired, please login again`, {
        toastId: 'Session expired',
        autoClose: 4000,
        type: 'warning',
      });
    }
  }

  public async logout() {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      //   await apolloClient.mutate<
      //     AuthSessionRevokeMutation,
      //     AuthSessionRevokeMutationVariables
      //   >({
      //     mutation: AuthSessionRevokeDocument,
      //   });

      this._logout();
    } catch {
      // do nothing
    } finally {
      this.isLoading = false;
    }
  }

  @action private _logout() {
    this.setTokens({});
    this.setUserData();
    resetClient();
  }
}

export const authentication = new Authentication();

// standard claims https://datatracker.ietf.org/doc/html/rfc7519#section-4.1
export interface JwtPayload {
  [key: string]: any;
  iss?: string | undefined;
  sub?: string | undefined;
  aud?: string | string[] | undefined;
  exp?: number | undefined;
  nbf?: number | undefined;
  iat?: number | undefined;
  jti?: string | undefined;
}

export interface HFBJWTPayload extends JwtPayload {
  uid: string;
  sid: string;
  // role related
  rl?: string; // selected role
  rls?: string[]; // allowed roles
}
