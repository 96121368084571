/* eslint-disable */
import ms from 'ms'

export const DEVELOPMENT_MODE = process.env.NODE_ENV === "development"

export const env = process.env.REACT_APP_ENV || 'development';
export const APP_ENV = env;

export const isProduction = env === 'production';
export const isStaging = env === 'staging';
export const isNext = env === 'next';
export const isDevelopment = env === 'development';

export const LABELS_API_URL = process.env.REACT_APP_LABELS_API_URL || 'http://localhost:2001';
export const HASURA_GRAPHQL_URI = process.env.REACT_APP_HASURA_GRAPHQL_URI || 'http://localhost:9000/v1/graphql';
export const AZURE_AUTHORITY_URL = process.env.REACT_APP_AZURE_AUTHORITY_URL || '';
export const AZURE_CLIENT_ID = process.env.REACT_APP_AZURE_CLIENT_ID || '';

export const LS_HYDRATE_PREFIX = process.env.REACT_APP_LS_HYDRATE_PREFIX || 'hfb-kc';

export const AUTH_PROACTIVE_SESSION_RENEWAL_INTERVAL_MS = Number(process.env.REACT_APP_AUTH_PROACTIVE_SESSION_RENEWAL_INTERVAL_MS) || ms('5 minutes');
