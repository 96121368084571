import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';

const AutocompleteField = ({
  error,
  name,
  label,
  helperText = undefined,
  required = false,
  options,
  touched,
  value,
  onBlur,
  handleChange,
  fullWidth = false,
}: any) => {
  return (
    <Autocomplete
      defaultValue={value || { name: '', id: null }}
      id={name}
      options={options}
      onBlur={onBlur}
      onChange={(e, value) => handleChange(name, value)}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          size="small"
          variant="outlined"
          label={label}
          placeholder={label}
          fullWidth={fullWidth}
          required={required}
          error={touched && Boolean(error)}
          helperText={touched && Boolean(error) ? error : helperText}
        />
      )}
      getOptionLabel={(option) => option.name}
      getOptionSelected={(option, value) => option.id === value.id}
    />
  );
};

export default AutocompleteField;
