import startCase from 'lodash/startCase';

import { OrderStatusEnum_enum } from '../hasura/generated';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const OrderStatusEnumValuesToLabels: Record<
  OrderStatusEnum_enum,
  string
> = {};
export const OrderStatusEnumLabelsToValues: Record<
  string,
  OrderStatusEnum_enum
> = {};

Object.keys(OrderStatusEnum_enum).forEach((val) => {
  const value = val as OrderStatusEnum_enum;
  const label = startCase(value);
  OrderStatusEnumValuesToLabels[value] = label;
  OrderStatusEnumLabelsToValues[label] = value;
});
