import MUIDataTable from 'mui-datatables';
import React from 'react';

const MealTypesTable = ({ data, setSortColumn, setSortDirection }: any) => {
  return (
    <MUIDataTable
      title=""
      data={data}
      columns={[
        { name: 'name', label: 'Name' },
        {
          name: 'orderCount.aggregate.count',
          label: 'Assigned Orders',
          options: {
            searchable: false,
          },
        },
      ]}
      options={{
        // download: false,
        elevation: 1,
        enableNestedDataAccess: '.',
        filter: false,
        pagination: false,
        // print: false,
        search: false,
        selectableRows: 'none',
        viewColumns: false,
        // serverSide: true,
        // onColumnSortChange: (col: string, dir: string) => {
        //   setSortColumn(col);
        //   setSortDirection(dir);
        // },
      }}
    />
  );
};

export default MealTypesTable;
