import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React from 'react';

const DashTabs = ({ value, setValue }: any) => {
  return (
    <Box style={{ width: '100%' }}>
      <Tabs
        value={value}
        textColor="primary"
        indicatorColor="primary"
        aria-label="secondary tabs example"
      >
        <Tab label="Sites" value="sites" onClick={() => setValue('sites')} />
        {/* <Tab
          label="Menu Services"
          value="menuServices"
          onClick={() => setValue('menuServices')}
        />
        <Tab label="Notes" value="notes" onClick={() => setValue('notes')} /> */}
        <Tab
          label="Meal Types"
          value="mealTypes"
          onClick={() => setValue('mealTypes')}
        />
      </Tabs>
    </Box>
  );
};

export default DashTabs;
