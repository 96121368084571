import { from, split } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { getMainDefinition } from 'apollo-utilities';

import { HASURA_GRAPHQL_URI } from '../../config';

import authLink from './authLink';
import errorLink from './errorLink';
import { wsLink } from './wsLink';

const httpLink = new HttpLink({
  uri: HASURA_GRAPHQL_URI,
});

const wsAndHttpSplitter = split(
  ({ query, getContext }) => {
    const definition = getMainDefinition(query);
    const context = getContext();

    if (context?.link === 'http') {
      return false;
    }

    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

export const link = from([errorLink, authLink, wsAndHttpSplitter]);
