import { WebSocketLink } from 'apollo-link-ws';
import { SubscriptionClient } from 'subscriptions-transport-ws';

import { HASURA_GRAPHQL_URI } from '../../config';
import { authentication, readyPromise } from '../../stores';

export const subscriptionClient = new SubscriptionClient(
  HASURA_GRAPHQL_URI.replace(/^http/, 'ws'),
  {
    lazy: true,
    reconnect: true,
    connectionParams: async () => {
      await readyPromise;

      return { headers: await authentication.getHeaders() };
    },
  },
);
export const wsLink = new WebSocketLink(subscriptionClient);
