import { List, ListItem, Button, colors, ButtonProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Dashboard as DashboardIcon } from '@material-ui/icons';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import GroupIcon from '@material-ui/icons/Group';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import ViewListIcon from '@material-ui/icons/ViewList';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { forwardRef, Fragment } from 'react';
import { NavLink as RouterLink, LinkProps } from 'react-router-dom';

import { authentication } from '../../../stores';

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
    border: 'none !important',
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
}));

const CustomRouterLink = forwardRef((props: LinkProps, ref: any) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink exact {...props} />
  </div>
));

interface LinkButtonProps extends ButtonProps {
  activeClassName?: string;
}

const LinkButton = (props: LinkButtonProps & LinkProps) => (
  <Button {...props} component={CustomRouterLink as any} />
);

const SidebarNav: React.FC<any> = observer((props) => {
  const { className, dispatch, ...rest } = props;

  const classes = useStyles();

  return (
    <>
      <List {...rest} className={clsx(classes.root, className)}>
        <Fragment>
          <ListItem className={classes.item} disableGutters>
            <LinkButton
              activeClassName={classes.active}
              className={classes.button}
              to="/"
            >
              <div className={classes.icon}>
                <DashboardIcon />
              </div>
              Dashboard
            </LinkButton>
          </ListItem>

          <ListItem className={classes.item} disableGutters>
            <LinkButton
              activeClassName={classes.active}
              className={classes.button}
              to="/orders"
            >
              <div className={classes.icon}>
                <ViewListIcon />
              </div>
              Orders
            </LinkButton>
          </ListItem>

          {authentication.currentRole === 'KIDS_CAFE_ADMIN' && (
            <ListItem className={classes.item} disableGutters>
              <LinkButton
                activeClassName={classes.active}
                className={classes.button}
                to="/meal-types"
              >
                <div className={classes.icon}>
                  <FastfoodIcon />
                </div>
                Meal Types
              </LinkButton>
            </ListItem>
          )}

          {authentication.currentRole === 'KIDS_CAFE_ADMIN' && (
            <ListItem className={classes.item} disableGutters>
              <LinkButton
                activeClassName={classes.active}
                className={classes.button}
                to="/routes"
              >
                <div className={classes.icon}>
                  <LocalShippingIcon />
                </div>
                Routes
              </LinkButton>
            </ListItem>
          )}
          {authentication.currentRole === 'KIDS_CAFE_ADMIN' && (
            <ListItem className={classes.item} disableGutters>
              <LinkButton
                activeClassName={classes.active}
                className={classes.button}
                to="/users"
              >
                <div className={classes.icon}>
                  <GroupIcon />
                </div>
                Users
              </LinkButton>
            </ListItem>
          )}
        </Fragment>
      </List>
    </>
  );
});

SidebarNav.propTypes = {
  className: PropTypes.string,
};

export default SidebarNav;
