import { Button, Paper, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';

import AppleBackground from '../../images/apples.jpg';
import { authentication } from '../../stores';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `linear-gradient(180deg, rgba(118, 185, 0, 0.4), rgba(118, 185, 0, 0.4)), url(${AppleBackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  heading: {
    marginBottom: 30,
  },
}));

const LoginPage: FC = observer(() => {
  const classes = useStyles();
  const canAuthenticate = authentication.isReady || authentication.isLoading;
  const loggingIn = authentication.isLoading;

  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={false} sm={4} className={classes.image} />
      <Grid item xs={12} sm={8} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Typography
            style={{ fontWeight: 700, fontSize: 25 }}
            variant="h1"
            className={classes.heading}
          >
            Kids Cafe Portal
          </Typography>
          <Typography
            variant="h4"
            style={{ fontWeight: 700, fontSize: 32 }}
            color="secondary"
          >
            Welcome back!
          </Typography>
          <Typography
            variant="h4"
            style={{ fontWeight: 700, fontSize: 32 }}
            color="secondary"
          >
            Log in to your account.
          </Typography>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              authentication.login();
            }}
            className={classes.form}
          >
            <Button
              size="large"
              type="submit"
              variant="outlined"
              color="secondary"
              className={classes.submit}
              disabled={!canAuthenticate || loggingIn}
            >
              {(loggingIn && 'Signing in...') || 'Sign in'}
            </Button>
          </form>
        </div>
      </Grid>
    </Grid>
  );
});

export default LoginPage;
