import React from 'react';
import { toast } from 'react-toastify';

import {
  useAddRouteMutation,
  useEditRouteTruckNumberMutation,
  useArchiveRouteMutation,
} from '../../../hasura/generated';
import handleToastError from '../../../helpers/handleToastError';

import EditRoutesForm from './edit-routes-form';

const EditRoutesWrapper = (props: any) => {
  const [addRoute] = useAddRouteMutation({
    onCompleted() {
      toast('Route Added');
    },
    onError(error) {
      handleToastError(error);
    },
  });

  const [editRoute] = useEditRouteTruckNumberMutation({
    onCompleted() {
      toast('Truck Number Updated');
    },
    onError(error) {
      handleToastError(error);
    },
  });

  const [archiveRoute] = useArchiveRouteMutation({
    onCompleted() {
      toast('Route Archived');
    },
    onError(error) {
      handleToastError(error);
    },
  });

  const editFunc = (data: any) => {
    const id = props?.initialValues?.id;
    const { truckNumber } = data;

    return editRoute({
      variables: {
        id,
        truckNumber,
      },
    });
  };

  const archiveFunc = () => {
    const id = props?.initialValues?.id;
    const loadingOrder = props?.initialValues?.loadingOrder;
    const archived = new Date();

    return archiveRoute({
      variables: {
        id,
        loadingOrder,
        archived,
      },
    });
  };

  const addFunc = (data: any) => {
    const loadingOrder = props.count + 1;
    const { name, truckNumber } = data;
    return addRoute({ variables: { name, loadingOrder, truckNumber } });
  };

  return (
    <EditRoutesForm
      addFunc={addFunc}
      archiveFunc={archiveFunc}
      editFunc={editFunc}
      {...props}
    />
  );
};

export default EditRoutesWrapper;
