import React from 'react';
import { toast } from 'react-toastify';

import {
  useAddMealTypeMutation,
  useEditMealTypeMutation,
  useArchiveMealTypeMutation,
  MealType_set_input,
  MealType_insert_input,
} from '../../../hasura/generated';
import handleToastError from '../../../helpers/handleToastError';

import EditMealTypesForm from './edit-meal-type-form';

const EditMealTypesWrapper = (props: any) => {
  const [addMealType] = useAddMealTypeMutation({
    onCompleted() {
      toast('Meal Type Added');
    },
    onError(error) {
      handleToastError(error);
    },
  });

  const [editMealType] = useEditMealTypeMutation({
    onCompleted() {
      toast('Name Updated');
    },
    onError(error) {
      handleToastError(error);
    },
  });

  const [archiveMealType] = useArchiveMealTypeMutation({
    onCompleted() {
      toast('Meal Type Archived');
    },
    onError(error) {
      handleToastError(error);
    },
  });

  return (
    <EditMealTypesForm
      addFunc={(data: MealType_insert_input) =>
        addMealType({ variables: { data } })
      }
      editFunc={(data: MealType_set_input) => {
        return editMealType({
          variables: {
            id: props?.initialValues?.id,
            data,
          },
        });
      }}
      archiveFunc={() => {
        const id = props?.initialValues?.id;
        const archived = new Date();

        return archiveMealType({
          variables: {
            id,
            archived,
          },
        });
      }}
      {...props}
    />
  );
};

export default EditMealTypesWrapper;
