import {
  IconButton,
  Paper,
  Popper,
  MenuList,
  MenuItem,
  ClickAwayListener,
  Grow,
} from '@material-ui/core';
import {
  ArrowLeft,
  ArrowRight,
  ArrowDropDown,
  Archive,
  Edit,
  CloudDownload,
} from '@material-ui/icons';
import snakeCase from 'lodash/snakeCase';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import React, { FC, useEffect, useState, useMemo } from 'react';

import { LABELS_API_URL } from '../../config';
import {
  useListOrdersSubscription,
  useOrdersFilterOptionsQuery,
  order_by,
  useListSitesQuery,
  OrderStatusEnum_enum,
  OrderFullFragment,
  useEditOrderMutation,
} from '../../hasura/generated';
import { OrderStatusEnumValuesToLabels } from '../../helpers/mappings';
import usePagination from '../../helpers/usePagination';
import { authentication } from '../../stores';

export const OrdersTable: FC<{
  count: number;
  startDate: Date;
  endDate: Date;
  onEdit: (id: string) => void;
}> = ({ count, startDate, endDate, onEdit }) => {
  const { data: sitesData } = useListSitesQuery();
  const sites = sitesData?.sites || [];

  const [rowsPerPage, setRowsPerPage] = useState<number>(100);
  const [filterList, setFilterList] = useState({});
  const [sortColumn, setSortColumn] = useState<string>('deliveryDay');
  const [sortDirection, setSortDirection] = useState<order_by>(order_by['asc']);
  const getSort = () => {
    switch (sortColumn) {
      case 'mealType.name':
        return { MealType: { name: order_by[sortDirection] } };
      case 'status.label':
        return { OrderStatusEnum: { label: order_by[sortDirection] } };
      case 'route.name':
        return { Route: { name: order_by[sortDirection] } };
      default:
        return { [sortColumn]: sortDirection };
    }
  };
  const order = getSort();
  const pagination = usePagination(count, rowsPerPage);
  const { data, loading } = useListOrdersSubscription({
    variables: {
      offset: pagination.offset,
      limit: rowsPerPage,
      order_by: order,
      where: {
        ...filterList,
        archived: { _is_null: true },
        deliveryDay: { _gte: startDate, _lte: endDate },
      },
    },
  });

  const [orders, setOrders] = useState<any>([]);
  useEffect(() => {
    if (data) {
      setOrders(data.orders);
    }
  }, [data]);

  const fullOrders = useMemo(
    () =>
      orders.map((order: any) => {
        const site = sites.find(
          (site: any) => site.siteId === order.siteId,
        )?.name;
        return { ...order, site };
      }),
    [orders, sites],
  );

  const filterColumns = [
    'id',
    'siteId',
    'mealTypeId',
    'status',
    'deliveryDay',
    'routeId',
    'truckNumber',
    'updatedAt',
    'lastEditedByUserId',
    '',
  ];
  const filterOptions = useOrdersFilterOptionsQuery();

  return (
    <MUIDataTable
      title={'Orders'}
      data={fullOrders}
      options={{
        count,
        elevation: 1,
        enableNestedDataAccess: '.',
        filterType: 'multiselect',
        page: pagination.page,
        pagination: true,
        rowsPerPage: Number(rowsPerPage),
        rowsPerPageOptions: [50, 100, 200],
        search: false,
        selectableRows: 'none',
        serverSide: true,
        textLabels: {
          body: {
            noMatch: loading
              ? 'Loading data, please wait'
              : 'Sorry, no matching records found',
          },
        },
        onChangePage: (p) => pagination.setPage(p),
        onChangeRowsPerPage: (num) => {
          setRowsPerPage(num);
          pagination.setPage(0);
        },
        onColumnSortChange: (col, dir) => {
          setSortColumn(col);
          setSortDirection(order_by[dir]);
        },
        onFilterChange: (col, list) => {
          const formattedList = list
            .map((filters, index) => ({ index, filters }))
            .filter((filter) => filter.filters.length > 0)
            .reduce(
              (obj, item) =>
                Object.assign(obj, {
                  [filterColumns[item.index]]: { _in: item.filters },
                }),
              {},
            );
          setFilterList(formattedList);
        },
      }}
      columns={[
        {
          name: 'id',
          options: {
            display: false,
            filter: false,
            searchable: false,
            sort: false,
            viewColumns: false,
          },
        },
        {
          name: 'site',
          label: 'Site',
          options: {
            sort: false,
            filterOptions: {
              names: (filterOptions.data?.siteIds?.nodes || []).map(
                (node: any) => node.siteId,
              ),
              renderValue: (v) => {
                const node = (filterOptions.data?.siteIds?.nodes || []).find(
                  (node: any) => node.siteId === v,
                );
                return (node?.site?.length && node?.site[0]?.name) || '';
              },
            },
            customFilterListOptions: {
              render: (v) => {
                const node = (filterOptions.data?.siteIds?.nodes || []).find(
                  (node: any) => node.siteId === v,
                );
                return (node?.site?.length && node?.site[0]?.name) || '';
              },
            },
          },
        },
        {
          name: 'mealType.name',
          label: 'Meal Type',
          options: {
            filterOptions: {
              names: (filterOptions.data?.mealTypeIds?.nodes || []).map(
                (node: any) => node.mealTypeId,
              ),
              renderValue: (v) => {
                const node = (
                  filterOptions.data?.mealTypeIds?.nodes || []
                ).find((node: any) => node.mealTypeId === v);
                return node?.mealType?.name || '';
              },
            },
            customFilterListOptions: {
              render: (v) => {
                const node = (
                  filterOptions.data?.mealTypeIds?.nodes || []
                ).find((node: any) => node.mealTypeId === v);
                return node?.mealType?.name || '';
              },
            },
          },
        },
        {
          name: 'status.label',
          label: 'Status',
          options: {
            filterOptions: {
              names: (filterOptions.data?.statuses?.nodes || []).map(
                (node: any) => node.status,
              ),
              renderValue: (v) => {
                const node = (filterOptions.data?.statuses?.nodes || []).find(
                  (node: any) => node.status === v,
                );
                return node?.statusEnum?.label || '';
              },
            },
            customFilterListOptions: {
              render: (v) => {
                const node = (filterOptions.data?.statuses?.nodes || []).find(
                  (node: any) => node.status === v,
                );
                return node?.statusEnum?.label || '';
              },
            },
          },
        },
        {
          name: 'deliveryDay',
          label: 'Delivery Day',
          options: {
            customBodyRender: (value: any) =>
              moment(value).format('MM/DD/YYYY'),
            filterOptions: {
              names: (filterOptions.data?.deliveryDays?.nodes || []).map(
                (node: any) => node.deliveryDay,
              ),
              renderValue: (v) => moment(v).format('MM/DD/YYYY'),
            },
            customFilterListOptions: {
              render: (v) => moment(v).format('MM/DD/YYYY'),
            },
          },
        },
        {
          name: 'route.name',
          label: 'Route Number',
          options: {
            filterOptions: {
              names: (filterOptions.data?.routeIds?.nodes || []).map(
                (node: any) => node.routeId,
              ),
              renderValue: (v) => {
                const node = (filterOptions.data?.routeIds?.nodes || []).find(
                  (node: any) => node.routeId === v,
                );
                return node?.route?.name || '';
              },
            },
            customFilterListOptions: {
              render: (v) => {
                const node = (filterOptions.data?.routeIds?.nodes || []).find(
                  (node: any) => node.routeId === v,
                );
                return node?.route?.name || '';
              },
            },
          },
        },
        {
          name: 'truckNumber',
          label: 'Truck Number',
          options: {
            filterOptions: {
              names: (filterOptions.data?.truckNumbers?.nodes || []).map(
                (node: any) => node.truckNumber,
              ),
            },
          },
        },
        {
          name: 'updatedAt',
          label: 'Last Updated',
          options: {
            filter: false,
            customBodyRender: (value: any) =>
              moment(value).format('MM/DD/YYYY, hh:mma'),
          },
        },
        {
          name: 'user',
          label: 'Last Edited By',
          options: {
            filter: false,
            sort: false,
            customBodyRender: (value: any) =>
              value ? `${value.firstName} ${value.lastName}` : '',
          },
        },
        {
          name: '',
          label: '',
          options: {
            filter: false,
            print: false,
            sort: false,
            viewColumns: false,
            customBodyRender: (value: any, tableMeta: any) => {
              const orderId = tableMeta.rowData[0];
              const orderStatus = snakeCase(
                tableMeta.rowData[3],
              ) as OrderStatusEnum_enum;

              return (
                !!orderId && (
                  <MenuDropdown>
                    {authentication.currentRole ===
                      'KIDS_CAFE_KITCHEN_STAFF' && (
                      <StatusChangeMenuList
                        order={{ id: orderId, status: orderStatus }}
                        options={[
                          // Pending kitchen staff approval > ready to prepare
                          {
                            Icon: ArrowRight,
                            current:
                              OrderStatusEnum_enum.pending_kitchen_staff_approval,
                            next: OrderStatusEnum_enum.ready_to_prep,
                          },

                          // ready to prepare > ready for truck loading
                          {
                            Icon: ArrowRight,
                            current: OrderStatusEnum_enum.ready_to_prep,
                            next: OrderStatusEnum_enum.ready_for_truck_loading,
                          },

                          // ready for truck loading > loaded on truck
                          {
                            Icon: ArrowRight,
                            current:
                              OrderStatusEnum_enum.ready_for_truck_loading,
                            next: OrderStatusEnum_enum.loaded_on_truck,
                          },

                          // loaded on truck > delivered
                          {
                            Icon: ArrowRight,
                            current: OrderStatusEnum_enum.loaded_on_truck,
                            next: OrderStatusEnum_enum.delivered,
                          },
                        ]}
                      />
                    )}

                    {authentication.currentRole === 'KIDS_CAFE_ADMIN' && (
                      <StatusChangeMenuList
                        order={{ id: orderId, status: orderStatus }}
                        options={[
                          // draft
                          {
                            Icon: ArrowRight,
                            current: OrderStatusEnum_enum.draft,
                            next: OrderStatusEnum_enum.ready_to_prep,
                          },
                          {
                            Icon: ArrowRight,
                            current: OrderStatusEnum_enum.draft,
                            next: OrderStatusEnum_enum.pending_kitchen_staff_approval,
                          },

                          // pending_kitchen_staff_approval
                          {
                            Icon: ArrowRight,
                            current:
                              OrderStatusEnum_enum.pending_kitchen_staff_approval,
                            next: OrderStatusEnum_enum.ready_to_prep,
                          },

                          // ready_to_prep
                          {
                            Icon: ArrowLeft,
                            current: OrderStatusEnum_enum.ready_to_prep,
                            next: OrderStatusEnum_enum.draft,
                          },
                          {
                            Icon: ArrowRight,
                            current: OrderStatusEnum_enum.ready_to_prep,
                            next: OrderStatusEnum_enum.ready_for_truck_loading,
                          },

                          // ready_to_prep
                          {
                            Icon: ArrowLeft,
                            current:
                              OrderStatusEnum_enum.ready_for_truck_loading,
                            next: OrderStatusEnum_enum.ready_to_prep,
                          },
                          {
                            Icon: ArrowRight,
                            current:
                              OrderStatusEnum_enum.ready_for_truck_loading,
                            next: OrderStatusEnum_enum.loaded_on_truck,
                          },

                          // loaded_on_truck
                          {
                            Icon: ArrowLeft,
                            current: OrderStatusEnum_enum.loaded_on_truck,
                            next: OrderStatusEnum_enum.ready_for_truck_loading,
                          },
                          {
                            Icon: ArrowRight,
                            current: OrderStatusEnum_enum.loaded_on_truck,
                            next: OrderStatusEnum_enum.delivered,
                          },

                          // delivered
                          {
                            Icon: ArrowLeft,
                            current: OrderStatusEnum_enum.delivered,
                            next: OrderStatusEnum_enum.loaded_on_truck,
                          },
                        ]}
                      />
                    )}

                    {/* Links to render PDF labels */}
                    <MenuList>
                      <MenuItem
                        component="a"
                        href={`${LABELS_API_URL}/labels/${orderId}/individual?token=${authentication.accessToken}`}
                        target="_blank"
                      >
                        <CloudDownload
                          color="primary"
                          fontSize="small"
                          style={{ marginRight: '0.5rem' }}
                        />{' '}
                        Individual Labels
                      </MenuItem>
                      <MenuItem
                        component="a"
                        href={`${LABELS_API_URL}/labels/${orderId}/package?token=${authentication.accessToken}`}
                        target="_blank"
                      >
                        <CloudDownload
                          color="primary"
                          fontSize="small"
                          style={{ marginRight: '0.5rem' }}
                        />{' '}
                        Package Labels
                      </MenuItem>
                    </MenuList>

                    {authentication.currentRole === 'KIDS_CAFE_ADMIN' && (
                      <MenuList>
                        <MenuItem onClick={() => onEdit(orderId)}>
                          <Edit
                            color="primary"
                            fontSize="small"
                            style={{ marginRight: '0.5rem' }}
                          />{' '}
                          Edit
                        </MenuItem>

                        <ArchiveMenuItem orderId={orderId} />
                      </MenuList>
                    )}
                  </MenuDropdown>
                )
              );
            },
          },
        },
      ]}
    />
  );
};

const ArchiveMenuItem: FC<{
  orderId: string;
}> = ({ orderId }) => {
  const [mutation] = useEditOrderMutation();
  return (
    <MenuItem
      onClick={async () => {
        await mutation({
          variables: { id: orderId, data: { archived: new Date() } },
        });
      }}
    >
      <Archive
        color="primary"
        fontSize="small"
        style={{ marginRight: '0.5rem' }}
      />{' '}
      Archive
    </MenuItem>
  );
};

const StatusChangeMenuList: FC<{
  order: Pick<OrderFullFragment, 'id' | 'status'>;
  options: Array<{
    Icon: FC<any>;
    current: OrderStatusEnum_enum;
    next: OrderStatusEnum_enum;
  }>;
}> = ({ order, options }) => {
  const [mutation] = useEditOrderMutation();

  const childrens = options.map(
    ({ Icon, current, next }, i) =>
      order.status === current && (
        <MenuItem
          key={i}
          onClick={async () => {
            await mutation({
              variables: {
                id: order.id,
                data: {
                  status: next,
                },
              },
            });
          }}
        >
          {' '}
          <Icon
            color="primary"
            fontSize="small"
            style={{ marginRight: '0.5rem' }}
          />{' '}
          <b>{OrderStatusEnumValuesToLabels[next]}</b>
        </MenuItem>
      ),
  );

  return (childrens.length > 0 && <MenuList>{childrens}</MenuList>) || null;
};

const MenuDropdown: FC = ({ children }) => {
  const anchorRef = React.useRef(null);
  const [open, setOpen] = useState(false);

  return (
    <div>
      <IconButton
        ref={anchorRef}
        aria-label="open options"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={() => setOpen(!open)}
      >
        <ArrowDropDown color="primary" />
      </IconButton>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="top-end"
        style={{ zIndex: 101 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <MenuList>{children}</MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};
