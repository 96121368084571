import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { FC } from 'react';

import { authentication } from '../../../stores';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
  },
  name: {
    marginTop: theme.spacing(1),
  },
}));

const Profile: FC<any> = observer((props) => {
  const { className, ...rest } = props;
  const user = authentication.user;
  const firstName = user?.firstName || '';

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      {(!!firstName && (
        <Typography variant="body2">
          Hey {firstName}!{' '}
          <span role="img" aria-label="wave emoji">
            👋
          </span>
        </Typography>
      )) || (
        <Typography variant="body2">
          Hey there!{' '}
          <span role="img" aria-label="wave emoji">
            👋
          </span>
        </Typography>
      )}
    </div>
  );
});

Profile.propTypes = {
  className: PropTypes.string,
};

export default Profile;
