import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';

import { link } from './links';
import { subscriptionClient } from './links/wsLink';

export const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

export async function resetClient() {
  await subscriptionClient.close();
  await apolloClient.resetStore();
}
