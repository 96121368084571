import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import React, { FC } from 'react';
import 'react-datepicker/dist/react-datepicker.css';

import {
  useOrderStatusesOptions,
  useRoutesOptions,
  useMealTypesOptions,
  useSitesOptions,
} from '../../../../hooks/form-select-options';
import { IFormikObject } from '../../../../typings';
import ResponsiveDialog from '../../../ResponsiveDialog';
import DropDownMenuClassicV2 from '../../form-fields/DropDownMenuClassicV2';

export const OrderForm: FC<{
  title: string;
  open: boolean;
  onClose: () => void;
  formik: IFormikObject<any>;
}> = ({ title, open, formik, onClose }) => {
  const orderStatuses = useOrderStatusesOptions();
  const sitesOptions = useSitesOptions();
  const mealTypesOptions = useMealTypesOptions();
  const routesOptions = useRoutesOptions({
    RouteDeliverySites: {
      siteId: { _eq: formik.values.siteId },
    },
  });

  const hasErrors = Object.keys(formik.errors).length > 0;

  return (
    <ResponsiveDialog open={open}>
      <form
        onSubmit={formik.handleSubmit}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the required information, submit to save.
          </DialogContentText>
          <Grid container spacing={2}>
            {/* start fields */}

            {/* status */}
            <Grid item xs={12} md={6}>
              <DropDownMenuClassicV2
                fullWidth
                nullable={false}
                label="Status"
                name="status"
                value={formik.values.status}
                error={formik.errors.status}
                touched={formik.touched.status}
                onChange={formik.handleChange}
                options={orderStatuses}
                required
              />
            </Grid>

            {/* meal type */}
            <Grid item xs={12} md={6}>
              <DropDownMenuClassicV2
                fullWidth
                nullable={false}
                label="Meal Type"
                name="mealTypeId"
                value={formik.values.mealTypeId || ''}
                error={formik.errors.mealTypeId}
                touched={formik.touched.mealTypeId}
                onChange={formik.handleChange}
                options={mealTypesOptions}
                required
              />
            </Grid>

            {/* site */}
            <Grid item xs={12} md={8}>
              <DropDownMenuClassicV2
                fullWidth
                nullable={false}
                label="Site"
                name="siteId"
                value={formik.values.siteId || ''}
                error={formik.errors.siteId}
                touched={formik.touched.siteId}
                onChange={formik.handleChange}
                options={sitesOptions}
                required
              />
            </Grid>

            {/* quantityOrdered */}
            <Grid item xs={12} md={4}>
              <TextField
                id="quantityOrdered"
                size="small"
                variant="standard"
                label="Quantity Ordered"
                required
                fullWidth
                value={formik.values.quantityOrdered}
                error={formik.touched && Boolean(formik.errors.quantityOrdered)}
                helperText={
                  formik.touched.quantityOrdered &&
                  Boolean(formik.errors.quantityOrdered) &&
                  formik.errors.quantityOrdered
                }
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
            </Grid>

            {/* route */}
            {/* todo: validate later that the route actually contains the siteId */}
            <Grid item xs={12} md={4}>
              <DropDownMenuClassicV2
                fullWidth
                nullable={false}
                label="Route"
                name="routeId"
                value={formik.values.routeId || ''}
                error={formik.errors.routeId}
                touched={formik.touched.routeId}
                onChange={formik.handleChange}
                options={routesOptions}
              />
            </Grid>

            {/* truckNumber */}
            <Grid item xs={12} md={4}>
              <TextField
                id="truckNumber"
                size="small"
                variant="standard"
                label="Truck Number"
                fullWidth
                value={formik.values.truckNumber}
                error={formik.touched && Boolean(formik.errors.truckNumber)}
                helperText={
                  formik.touched.truckNumber &&
                  Boolean(formik.errors.truckNumber) &&
                  formik.errors.truckNumber
                }
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
            </Grid>

            {/* deliveryDay */}
            <Grid item xs={12} md={4}>
              <DatePicker
                label="Delivery Day"
                name="deliveryDay"
                variant="inline"
                value={formik.values.deliveryDay}
                error={!!formik.errors.deliveryDay}
                onChange={(date) => formik.setFieldValue('deliveryDay', date)}
                format="dd/MM/yyyy"
                fullWidth
                autoOk
                required
              />
            </Grid>

            {/* end fields */}
          </Grid>
          <DialogActions
            style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 32 }}
          >
            <Button
              onClick={onClose}
              color="primary"
              disabled={formik.isSubmitting}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={
                !formik.dirty ||
                formik.isSubmitting ||
                formik.isValidating ||
                hasErrors
              }
            >
              {formik.isSubmitting ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <CircularProgress size={20} />
                  &nbsp; Saving...
                </div>
              ) : (
                'Save'
              )}
            </Button>
          </DialogActions>
        </DialogContent>
      </form>
    </ResponsiveDialog>
  );
};
