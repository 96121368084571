import { Grid, Button } from '@material-ui/core';
import { ArrowBackOutlined, LocationOnOutlined } from '@material-ui/icons';
import React, { useContext, useEffect, useState, useMemo } from 'react';

import EditSites from '../../../components/Forms/EditSites';
import { ModalContext } from '../../../components/Modal/ModalContext';
import { useListRouteSitesSubscription } from '../../../hasura/generated';

import SitesTable from './sites-table';

const Sites = ({ setPageContent, sites, route }: any) => {
  const modal = useContext(ModalContext);

  const [sortColumn, setSortColumn] = useState<string>('siteDeliveryOrder');
  const [sortDirection, setSortDirection] = useState<string>('asc');
  const [routeSites, setRouteSites] = useState<any>([]);
  const count = routeSites?.length || 0;

  const { data, loading } = useListRouteSitesSubscription({
    variables: {
      routeId: route.id,
      order_by: {
        [sortColumn]: sortDirection,
      },
    },
  });
  useEffect(() => {
    if (data) setRouteSites(data.sites);
  }, [data]);
  // Sort sites
  const sortedSites = useMemo(
    () =>
      [...routeSites].sort(
        (a: any, b: any) => a.siteDeliveryOrder - b.siteDeliveryOrder,
      ),
    [routeSites],
  );
  // Attach site data to sites assigned to a route
  const sortedRouteSites = sortedSites.map((sortedSite) => {
    const siteMatch = sites.find(
      (site: any) => site.siteId === sortedSite.siteId,
    );
    const name = siteMatch?.name;
    const address = `${
      siteMatch
        ? `${siteMatch?.address1}, ${
            (siteMatch?.address2 && `${siteMatch.address2}, `) || ''
          } ${siteMatch?.city}, ${siteMatch?.state} ${siteMatch?.zip}`
        : ''
    }`;
    return { ...sortedSite, name, address };
  });

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item xs={8}>
          <Button
            color="primary"
            startIcon={<ArrowBackOutlined />}
            onClick={() => setPageContent('routes')}
          >
            Back to All Routes
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button
            color="primary"
            startIcon={<LocationOnOutlined />}
            onClick={() =>
              modal.showModal(EditSites, {
                sites,
                routeId: route.id,
                count,
              })
            }
          >
            ASSIGN SITE
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <SitesTable
          count={count}
          route={route}
          loading={loading}
          sortedSites={sortedRouteSites}
          setSortedSites={setRouteSites}
          setSortColumn={setSortColumn}
          setSortDirection={setSortDirection}
        />
      </Grid>
    </>
  );
};

export default Sites;
