import { Button, Grid } from '@material-ui/core';
import { LocationOn } from '@material-ui/icons';
import React from 'react';

const Edit = ({
  sortedData,
  value,
  tableMeta,
  setPageContent,
  setSelectedRoute,
}: any) => {
  return (
    <>
      <Grid container>
        <Grid item xs={1}>
          <span>{value.aggregate.count}</span>
        </Grid>
        <Grid item xs={11}>
          <Button
            color="primary"
            onClick={() => {
              const route = sortedData.find(
                ({ id }: any) => id === tableMeta.rowData[0],
              );
              setSelectedRoute(route);
              setPageContent('sites');
            }}
          >
            VIEW SITES <LocationOn />
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default Edit;
