import React from 'react';
import { toast } from 'react-toastify';

import { useAssignSiteToRouteMutation } from '../../../hasura/generated';
import handleToastError from '../../../helpers/handleToastError';

import EditSitesForm from './edit-sites-form';

const EditSitesWrapper = ({ routeId, count, ...props }: any) => {
  const [assignSite] = useAssignSiteToRouteMutation({
    onCompleted() {
      toast('Site Assigned');
    },
    onError(error) {
      handleToastError(error);
    },
  });

  const addFunc = (data: any) => {
    const siteDeliveryOrder = count + 1;
    const siteId = data.site.id;
    return assignSite({ variables: { siteId, routeId, siteDeliveryOrder } });
  };

  return <EditSitesForm addFunc={addFunc} {...props} />;
};

export default EditSitesWrapper;
