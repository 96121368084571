import ms from 'ms';
import { useMemo } from 'react';

import { SelectOption } from '../components/Forms/form-fields/DropDownMenuClassicV2';
import {
  useGetOrderStatusEnumOptionsQuery,
  useGetSitesOptionsQuery,
  useGetRoutesOptionsQuery,
  useGetMealTypesOptionsQuery,
  GetSitesOptionsQueryVariables,
  GetRoutesOptionsQueryVariables,
  GetMealTypesOptionsQueryVariables,
  GetOrderStatusEnumOptionsQueryVariables,
} from '../hasura/generated';

const emptyOption = {
  value: '',
  label: '',
  disabled: true,
};

function useAddEmptyOption(options?: SelectOption[] | any): SelectOption[] {
  const opts = Array.isArray(options) ? options : [];
  return useMemo(() => [emptyOption, ...opts], [opts]);
}

export function useOrderStatusesOptions(
  whereFilters?: GetOrderStatusEnumOptionsQueryVariables['whereFilters'],
) {
  const { data, loading } = useGetOrderStatusEnumOptionsQuery({
    variables: { whereFilters },
    fetchPolicy: 'cache-and-network',
    pollInterval: ms('5 minutes'),
  });

  return useAddEmptyOption(!loading && data?.orderStatuses);
}

export function useSitesOptions(
  whereFilters?: GetSitesOptionsQueryVariables['whereFilters'],
) {
  const { data, loading } = useGetSitesOptionsQuery({
    variables: { whereFilters },
    fetchPolicy: 'cache-and-network',
    pollInterval: ms('5 minutes'),
  });

  return useAddEmptyOption(!loading && data?.sites);
}

export function useRoutesOptions(
  whereFilters: GetRoutesOptionsQueryVariables['whereFilters'] = {},
) {
  const { data, loading } = useGetRoutesOptionsQuery({
    variables: { whereFilters },
    fetchPolicy: 'cache-and-network',
    pollInterval: ms('5 minutes'),
  });

  const options = useMemo(() => {
    return ((!loading && data?.routes) || []).map((route) => ({
      value: route.value,
      label: `Route Number ${route.label}`,
    }));
  }, [data, loading]);

  return useAddEmptyOption(options);
}

export function useMealTypesOptions(
  whereFilters: GetMealTypesOptionsQueryVariables['whereFilters'] = {},
) {
  const { data, loading } = useGetMealTypesOptionsQuery({
    variables: { whereFilters },
    fetchPolicy: 'cache-and-network',
    pollInterval: ms('5 minutes'),
  });

  return useAddEmptyOption(!loading && data?.mealTypes);
}
