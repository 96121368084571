import { Grid } from '@material-ui/core';
import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Header = ({ startDate, endDate, setStartDate, setEndDate }: any) => {
  return (
    <>
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item>
          <Grid container>
            <Grid item style={{ marginRight: '0.5rem' }}>
              Date interval:
            </Grid>
            <Grid item style={{ marginRight: '0.5rem' }}>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
              />
            </Grid>
            <Grid item>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Header;
