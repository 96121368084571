import { HighlightOff } from '@material-ui/icons';
import MUIDataTable from 'mui-datatables';
import React, { Fragment } from 'react';
import { toast } from 'react-toastify';

import { useUnassignSiteToRouteMutation } from '../../../hasura/generated';
import handleToastError from '../../../helpers/handleToastError';

import Arrows from './site-table-arrows';

const SitesTable = ({
  count,
  loading,
  route,
  sortedSites,
  setSortColumn,
  setSortDirection,
  setSortedSites,
}: any) => {
  const [unassignSite] = useUnassignSiteToRouteMutation({
    onCompleted() {
      toast('Site removed from route');
    },
    onError(error) {
      handleToastError(error);
    },
  });

  return (
    <Fragment>
      <MUIDataTable
        title={`Sites for Route ${route.name}`}
        data={sortedSites}
        columns={[
          {
            name: 'siteDeliveryOrder',
            label: 'Site Delivery Order',
            options: {
              sort: false,
              customBodyRender: (value: any, tableMeta: any) => (
                <Arrows
                  count={count}
                  sortedData={sortedSites}
                  setSortedData={setSortedSites}
                  value={value}
                  tableMeta={tableMeta}
                  routeId={route.id}
                />
              ),
            },
          },
          {
            name: 'name',
            label: 'Name',
            options: {
              sort: false,
            },
          },
          {
            name: 'address',
            label: 'Address',
            options: {
              sort: false,
            },
          },
          {
            name: 'id',
            label: ' ',
            options: {
              sort: false,
              searchable: false,
              customBodyRender: (value: any) => (
                <HighlightOff
                  className="remove"
                  color="primary"
                  onClick={() => {
                    const { id, siteDeliveryOrder } = sortedSites.find(
                      ({ id }: any) => id === value,
                    );
                    unassignSite({
                      variables: {
                        id,
                        siteDeliveryOrder,
                      },
                    });
                  }}
                />
              ),
            },
          },
        ]}
        options={{
          filter: false,
          elevation: 1,
          pagination: false,
          search: false,
          serverSide: true,
          sort: false,
          viewColumns: false,
          selectableRows: 'none',
          textLabels: {
            body: {
              noMatch: loading
                ? 'Loading data, please wait'
                : 'Sorry, no matching records found',
            },
          },
          onColumnSortChange: (col: string, dir: string) => {
            setSortColumn(col);
            setSortDirection(dir);
          },
        }}
      />
    </Fragment>
  );
};

export default SitesTable;
