import {
  Button,
  DialogActions,
  DialogContent,
  CircularProgress,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core';
import { useFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import ResponsiveDialog from '../../ResponsiveDialog';

const EditMealTypesForm = (props: any) => {
  const { addFunc, archiveFunc, editFunc, initialValues, onRequestClose } =
    props;

  const formik = useFormik({
    initialValues: {
      name: initialValues?.name || '',
      itemsPerPackage: initialValues?.itemsPerPackage || 10,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
      itemsPerPackage: Yup.number().required('Required').default(10),
    }),
    onSubmit: async (values) => {
      const res = await (initialValues ? editFunc : addFunc)(values);

      if (res) {
        onRequestClose();
      }
    },
  });

  return (
    <ResponsiveDialog open onClose={onRequestClose}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle id="form-dialog-title">Add Meal Type</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the required information, submit to save.
          </DialogContentText>
          <Grid container spacing={2}>
            {/* name */}
            <Grid item xs={12}>
              <TextField
                id="name"
                size="small"
                variant="standard"
                label="Meal Type Name"
                fullWidth
                value={formik.values.name}
                error={formik.touched && Boolean(formik.errors.name)}
                helperText={
                  formik.touched.name &&
                  Boolean(formik.errors.name) &&
                  formik.errors.name
                }
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
            </Grid>

            {/* itemsPerPackage */}
            <Grid item xs={12}>
              <TextField
                id="itemsPerPackage"
                size="small"
                variant="standard"
                label="Items per Package"
                fullWidth
                value={formik.values.itemsPerPackage}
                error={formik.touched && Boolean(formik.errors.itemsPerPackage)}
                helperText={
                  formik.touched.itemsPerPackage &&
                  Boolean(formik.errors.itemsPerPackage) &&
                  formik.errors.itemsPerPackage
                }
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
            </Grid>
          </Grid>

          <DialogActions
            style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 32 }}
          >
            {initialValues && (
              <Button
                onClick={async () => {
                  const res = await archiveFunc();

                  if (res) {
                    onRequestClose();
                  }
                }}
                color="secondary"
                variant="outlined"
                disabled={formik.isSubmitting}
                style={{ marginRight: 'auto' }}
              >
                Archive
              </Button>
            )}
            <Button
              onClick={onRequestClose}
              color="primary"
              disabled={formik.isSubmitting}
            >
              Cancel
            </Button>
            <Button type="submit" color="primary" variant="outlined">
              {formik.isSubmitting ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <CircularProgress size={20} />
                  &nbsp; Submit
                </div>
              ) : (
                'Submit'
              )}
            </Button>
          </DialogActions>
        </DialogContent>
      </form>
    </ResponsiveDialog>
  );
};

export default EditMealTypesForm;
