import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  CardActions,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LocalShipping, AddCircleOutline } from '@material-ui/icons';
import React, { useContext, useEffect, useState, useMemo } from 'react';

import EditRoutes from '../../components/Forms/EditRoutes';
import { Layout } from '../../components/Layout';
import { ModalContext } from '../../components/Modal/ModalContext';
import {
  useListRoutesSubscription,
  useCountRoutesSubscription,
  useListSitesQuery,
} from '../../hasura/generated';
import usePagination from '../../helpers/usePagination';

import Sites from './Sites';
import RoutesTable from './routes-table';

const Routes = () => {
  const modal = useContext(ModalContext);
  const sites = useListSitesQuery().data?.sites || [];
  const countRes = useCountRoutesSubscription();
  const routeCount = countRes.data?.count?.aggregate?.count || 0;

  const [pageContent, setPageContent] = useState<string>('routes');
  const [rowsPerPage, setRowsPerPage] = useState<number>(100);
  const [sortColumn, setSortColumn] = useState<string>('loadingOrder');
  const [sortDirection, setSortDirection] = useState<string>('asc');

  const pagination = usePagination(routeCount, rowsPerPage);
  const { data, loading } = useListRoutesSubscription({
    variables: {
      offset: pagination.offset,
      limit: rowsPerPage,
      order_by: {
        [sortColumn]: sortDirection,
      },
    },
  });

  const [sortedData, setSortedData] = useState<any>([]);
  const [selectedRoute, setSelectedRoute] = useState<any>();

  useEffect(() => {
    if (data) setSortedData(data.routes);
  }, [data]);

  const newData = useMemo(
    () => [...sortedData].sort((a, b) => a.loadingOrder - b.loadingOrder),
    [sortedData],
  );

  const useStyles = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
    cardRoot: {
      height: '100%',
      minHeight: 100,
    },
    cardTitle: {
      fontWeight: 700,
    },
    cardAvatar: {
      backgroundColor: theme.palette.primary.main,
      height: 56,
      width: 56,
    },
    cardIcon: {
      height: 32,
      width: 32,
    },
  }));
  const classes = useStyles();

  return (
    <Layout>
      <div className={classes.root}>
        <Grid container spacing={2}>
          {pageContent === 'routes' ? (
            <>
              <Grid item xs={12} md={3}>
                <Card className="">
                  <CardContent>
                    <Grid container justifyContent="space-between">
                      <Grid item>
                        <Typography
                          className={classes.cardTitle}
                          color="textSecondary"
                          gutterBottom
                          variant="body2"
                        >
                          ROUTES
                        </Typography>
                        <Typography variant="h3">
                          {countRes.loading ? '-' : routeCount}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Avatar className={classes.cardAvatar}>
                          <LocalShipping className={classes.cardIcon} />
                        </Avatar>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions>
                    <Button
                      color="primary"
                      startIcon={<AddCircleOutline />}
                      onClick={() =>
                        modal.showModal(EditRoutes, { count: routeCount })
                      }
                    >
                      Add Route
                    </Button>
                  </CardActions>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <RoutesTable
                  count={routeCount}
                  loading={loading}
                  rowsPerPage={rowsPerPage}
                  page={pagination.page}
                  sortColumn={sortColumn}
                  sortDirection={sortDirection}
                  sortedData={newData}
                  setPage={pagination.setPage}
                  setPageContent={setPageContent}
                  setSortedData={setSortedData}
                  setRowsPerPage={setRowsPerPage}
                  setSortColumn={setSortColumn}
                  setSortDirection={setSortDirection}
                  setSelectedRoute={setSelectedRoute}
                />
              </Grid>
            </>
          ) : (
            <Sites
              setPageContent={setPageContent}
              route={selectedRoute}
              sites={sites}
            />
          )}
        </Grid>
      </div>
    </Layout>
  );
};

export default Routes;
