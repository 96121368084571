import { Grid } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import React, { useState } from 'react';

import {
  useListMealTypesSubscription,
  useListSitesQuery,
} from '../../../hasura/generated';

import DashboardMealTypesTable from './meal-types-table';
import DashboardMenuServicesTable from './menu-services-table';
import DashboardNotesTable from './notes-table';
import DashboardSitesTable from './sites-table';
import DashTabs from './table-tabs';

const Table = () => {
  const [value, setValue] = useState<any>('sites');
  const sites = useListSitesQuery().data?.sites || [];
  const mealTypes = useListMealTypesSubscription().data?.mealTypes || [];

  const options = {
    search: false,
    elevation: 1,
    pagination: true,
    rowsPerPage: 100,
    rowsPerPageOptions: [10, 25, 50, 100],
    selectableRows: 'none',
    serverSide: true,
  } as any;

  const selectedTable = () => {
    switch (value) {
      case 'sites':
        return <DashboardSitesTable data={sites} />;
      case 'menuServices':
        return <DashboardMenuServicesTable options={options} />;
      case 'notes':
        return <DashboardNotesTable options={options} />;
      case 'mealTypes':
        return <DashboardMealTypesTable data={mealTypes} />;
      default:
        return null;
    }
  };

  const theme = createTheme();
  const tablesTheme = createTheme(theme, {
    overrides: {
      MuiToolbar: {
        root: {
          'padding-top': '46px',
          [theme.breakpoints.up('sm')]: {
            'padding-top': '0px',
          },
        },
      },
    },
  });

  return (
    <Grid
      container
      spacing={2}
      justifyContent="space-between"
      style={{ position: 'relative' }}
    >
      <Grid item xs={12} style={{ position: 'absolute', top: 0, zIndex: 1 }}>
        <DashTabs value={value} setValue={setValue} />
      </Grid>
      <Grid item xs={12} style={{ zIndex: 0 }}>
        <ThemeProvider theme={tablesTheme}>{selectedTable()}</ThemeProvider>
      </Grid>
    </Grid>
  );
};

export default Table;
