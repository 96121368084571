import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Group } from '@material-ui/icons';
import React from 'react';

import { Layout } from '../../components/Layout';
import { useCountUsersSubscription } from '../../hasura/generated';

import UserTable from './user-table';

const UserManagement = () => {
  const countRes = useCountUsersSubscription();

  const userCount = countRes.data?.count?.aggregate?.count || 0;

  const useStyles = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
    cardRoot: {
      height: '100%',
      minHeight: 100,
    },
    cardTitle: {
      fontWeight: 700,
    },
    cardAvatar: {
      backgroundColor: theme.palette.primary.main,
      height: 56,
      width: 56,
    },
    cardIcon: {
      height: 32,
      width: 32,
    },
  }));

  const classes = useStyles();
  return (
    <Layout>
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Card className="">
              <CardContent>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography
                      className={classes.cardTitle}
                      color="textSecondary"
                      gutterBottom
                      variant="body2"
                    >
                      USERS
                    </Typography>
                    <Typography variant="h3">
                      {countRes.loading ? '-' : userCount}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Avatar className={classes.cardAvatar}>
                      <Group className={classes.cardIcon} />
                    </Avatar>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <UserTable count={userCount} />
          </Grid>
        </Grid>
      </div>
    </Layout>
  );
};

export default UserManagement;
