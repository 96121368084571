import { Grid } from '@material-ui/core';
import { ArrowUpward, ArrowDownward } from '@material-ui/icons';
import React from 'react';

import {
  useIncrementRouteLoadingOrderMutation,
  useDecrementRouteLoadingOrderMutation,
} from '../../hasura/generated';
import handleToastError from '../../helpers/handleToastError';

const Arrows = ({
  count,
  value,
  tableMeta,
  sortedData,
  setSortedData,
}: any) => {
  const rowIndex = tableMeta.rowIndex;
  const [incrementRouteLoadingOrder] = useIncrementRouteLoadingOrderMutation({
    onError(error) {
      handleToastError(error);
    },
  });
  const [decrementRouteLoadingOrder] = useDecrementRouteLoadingOrderMutation({
    onError(error) {
      handleToastError(error);
    },
  });

  return (
    <>
      <Grid container>
        <Grid item xs={3}>
          {rowIndex === 0 ? null : (
            <div>
              <ArrowUpward
                className="arrows"
                onClick={() => {
                  const array = [...sortedData];
                  array.splice(rowIndex - 1, 0, array[rowIndex]);
                  array.splice(rowIndex + 1, 1);
                  const newData = array.map((row: any, i: any) => {
                    if (row.loadingOrder) {
                      row.loadingOrder = i + 1;
                    } else {
                      row.siteDeliveryOrder = i + 1;
                    }
                    return row;
                  });
                  setSortedData(newData);
                  if (value > 0) {
                    incrementRouteLoadingOrder({
                      variables: {
                        curr: value,
                        prev: value - 1,
                      },
                    });
                  }
                }}
              />
            </div>
          )}

          {rowIndex + 1 === tableMeta.currentTableData.length ? null : (
            <div>
              <ArrowDownward
                className="arrows"
                onClick={() => {
                  if (value <= count) {
                    decrementRouteLoadingOrder({
                      variables: {
                        curr: value,
                        next: value + 1,
                      },
                    });
                  }
                  const array = [...sortedData];
                  array.splice(rowIndex + 2, 0, array[rowIndex]);
                  array.splice(rowIndex, 1);
                  const newData = array.map((row: any, i: any) => {
                    if (row.loadingOrder) {
                      row.loadingOrder = i + 1;
                    } else {
                      row.siteDeliveryOrder = i + 1;
                    }
                    return row;
                  });
                  setSortedData(newData);
                }}
              />
            </div>
          )}
        </Grid>
        <Grid item xs={9}>
          <span>{value}</span>
        </Grid>
      </Grid>
    </>
  );
};

export default Arrows;
