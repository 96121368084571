import {
  Button,
  DialogActions,
  DialogContent,
  CircularProgress,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core';
import { useFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

// import { required } from '../../../helpers/validators';
import ResponsiveDialog from '../../ResponsiveDialog';

const EditRoutesForm = (props: any) => {
  const { addFunc, archiveFunc, editFunc, initialValues, onRequestClose } =
    props;

  const formik = useFormik({
    initialValues: {
      name: initialValues?.name || '',
      truckNumber: initialValues?.defaultTruckNumber || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
      truckNumber: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      submitAndReset(values);
    },
  });

  const submitAndReset = (data: any) => {
    initialValues
      ? editFunc(data).then((resData: any) => {
          if (resData) {
            onRequestClose();
          }
        })
      : addFunc(data).then((resData: any) => {
          if (resData) {
            onRequestClose();
          }
        });
  };

  const archiveAndReset = () => {
    return archiveFunc().then((resData: any) => {
      if (resData) {
        onRequestClose();
      }
    });
  };

  return (
    <ResponsiveDialog open onClose={onRequestClose}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle id="form-dialog-title">Route Details</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the required information, submit to save.
          </DialogContentText>
          {!initialValues && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  id="name"
                  size="small"
                  variant="outlined"
                  label="Route Number"
                  required
                  fullWidth
                  value={formik.values.name}
                  error={formik.touched && Boolean(formik.errors.name)}
                  helperText={
                    formik.touched &&
                    Boolean(formik.errors.name) &&
                    formik.errors.name
                  }
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>
            </Grid>
          )}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="truckNumber"
                size="small"
                variant="outlined"
                label="Truck Number"
                required
                fullWidth
                value={formik.values.truckNumber}
                error={formik.touched && Boolean(formik.errors.truckNumber)}
                helperText={
                  formik.touched &&
                  Boolean(formik.errors.truckNumber) &&
                  formik.errors.truckNumber
                }
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
            </Grid>
          </Grid>
          <DialogActions
            style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 32 }}
          >
            {initialValues && (
              <Button
                onClick={archiveAndReset}
                color="secondary"
                variant="outlined"
                disabled={formik.isSubmitting}
                style={{ marginRight: 'auto' }}
              >
                Archive
              </Button>
            )}
            <Button
              onClick={onRequestClose}
              color="primary"
              disabled={formik.isSubmitting}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="outlined"
              disabled={formik.isSubmitting}
            >
              {formik.isSubmitting ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <CircularProgress size={20} />
                  &nbsp; Submit
                </div>
              ) : (
                'Submit'
              )}
            </Button>
          </DialogActions>
        </DialogContent>
      </form>
    </ResponsiveDialog>
  );
};

export default EditRoutesForm;
