import { useFormik } from 'formik';
import React, { FC } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import {
  useCreateOrderMutation,
  OrderFullFragment,
  OrderStatusEnum_enum,
} from '../../../hasura/generated';
import handleToastError from '../../../helpers/handleToastError';

import { OrderForm } from './common/order-form';

export type IOrderCreate = Pick<
  OrderFullFragment,
  | 'status'
  | 'mealTypeId'
  | 'siteId'
  | 'routeId'
  | 'deliveryDay'
  | 'quantityOrdered'
  | 'truckNumber'
>;

// Create Entire Order
const validationSchema = Yup.object({
  status: Yup.string().required('Required'),
  mealTypeId: Yup.string().required('Required'),
  siteId: Yup.string().required('Required'),
  routeId: Yup.string().nullable(),
  deliveryDay: Yup.date().required('Required'),
  truckNumber: Yup.number().nullable(),
  quantityOrdered: Yup.number().required('Required'),
});
const initialValues: Partial<IOrderCreate> = {
  status: OrderStatusEnum_enum.draft,
  deliveryDay: new Date(),
  routeId: null,
};

export const CreateOrderForm: FC<{
  open: boolean;
  onClose: () => void;
}> = (props) => {
  const [mutation] = useCreateOrderMutation({
    onCompleted() {
      toast('Order created');
    },
    onError(error) {
      handleToastError(error);
    },
  });

  const formik = useFormik<Partial<IOrderCreate>>({
    initialValues,
    validationSchema,
    onSubmit: async (data) => {
      await mutation({
        variables: {
          data,
        },
      });

      await props.onClose();
    },
  });

  return <OrderForm formik={formik} title="Create new Order" {...props} />;
};
