import {
  Button,
  DialogActions,
  DialogContent,
  CircularProgress,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import { useFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import ResponsiveDialog from '../../ResponsiveDialog';
import AutocompleteField from '../form-fields/AutocompleteField';

const EditSitesForm = ({ addFunc, onRequestClose, sites }: any) => {
  // TODO: Remove sites that have already been assigned from list

  const siteOptions =
    sites.map((site: any) => {
      return { name: site.name, id: site.siteId };
    }) || [];

  const formik = useFormik({
    initialValues: {
      site: { name: '', id: null },
    },
    validationSchema: Yup.object({
      site: Yup.object().nullable().required('Required'),
    }),
    onSubmit: async (values) => {
      submitAndReset(values);
    },
  });

  const submitAndReset = (data: any) =>
    addFunc(data).then((resData: any) => {
      if (resData) {
        onRequestClose();
      }
    });

  return (
    <ResponsiveDialog open onClose={onRequestClose}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle id="form-dialog-title">Assign Site</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select the site, submit to assign site to route.
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AutocompleteField
                label="Site"
                name="site"
                value={formik.values.site}
                options={siteOptions}
                error={formik.errors.site}
                touched={formik.touched.site}
                onBlur={formik.handleBlur}
                handleChange={formik.setFieldValue}
                fullWidth
              />
            </Grid>
          </Grid>
          <DialogActions
            style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 32 }}
          >
            <Button
              onClick={onRequestClose}
              color="primary"
              disabled={formik.isSubmitting}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="outlined"
              disabled={formik.isSubmitting}
            >
              {formik.isSubmitting ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <CircularProgress size={20} />
                  &nbsp; Submit
                </div>
              ) : (
                'Submit'
              )}
            </Button>
          </DialogActions>
        </DialogContent>
      </form>
    </ResponsiveDialog>
  );
};

export default EditSitesForm;
