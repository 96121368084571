import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  CardActions,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Fastfood, AddCircleOutline } from '@material-ui/icons';
import React, { useContext } from 'react';

import EditMealTypes from '../../components/Forms/EditMealTypes';
import { Layout } from '../../components/Layout';
import { ModalContext } from '../../components/Modal/ModalContext';
import { useCountMealTypesSubscription } from '../../hasura/generated';

import MealTypesTable from './meal-types-table';

const MealTypes = () => {
  const countRes = useCountMealTypesSubscription();
  const mealTypeCount = countRes.data?.count?.aggregate?.count || 0;
  const modal = useContext(ModalContext);
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
    cardRoot: {
      height: '100%',
      minHeight: 100,
    },
    cardTitle: {
      fontWeight: 700,
    },
    cardAvatar: {
      backgroundColor: theme.palette.primary.main,
      height: 56,
      width: 56,
    },
    cardIcon: {
      height: 32,
      width: 32,
    },
  }));

  const classes = useStyles();
  return (
    <Layout>
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Card className="">
              <CardContent>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography
                      className={classes.cardTitle}
                      color="textSecondary"
                      gutterBottom
                      variant="body2"
                    >
                      MEAL TYPES
                    </Typography>
                    <Typography variant="h3">
                      {countRes.loading ? '-' : mealTypeCount}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Avatar className={classes.cardAvatar}>
                      <Fastfood className={classes.cardIcon} />
                    </Avatar>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <Button
                  color="primary"
                  startIcon={<AddCircleOutline />}
                  onClick={() => modal.showModal(EditMealTypes)}
                >
                  Add Meal Type
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <MealTypesTable count={mealTypeCount} />
          </Grid>
        </Grid>
      </div>
    </Layout>
  );
};

export default MealTypes;
