// import { fromPromise, toPromise } from 'apollo-link';
import { fromPromise, NextLink, Operation, toPromise } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { toast } from 'react-toastify';

import { authentication } from '../../stores';

// import { store } from '../../App';
// import { endSession } from '../../helpers/session';
// import setUserEnv from '../../helpers/setUserEnv';
// import { userAgentApplication } from '../../pages/Login';

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (const error of graphQLErrors) {
        const { message } = error;

        if (message.includes('JWTExpired')) {
          return renewSessionOnExpiredJWT(operation, forward);
        }

        console.error(`[GraphQL error]: Message: ${message}`);
      }
    }

    if (networkError) {
      console.error(`[Network error]: ${JSON.stringify(networkError)}`);

      toast('Network error, please try again', {
        toastId: 'network-error',
        autoClose: 4000,
        type: 'warning',
      });
    }
  },
);

export default errorLink;

export function renewSessionOnExpiredJWT(
  operation: Operation,
  forward: NextLink,
) {
  return fromPromise(
    (async () => {
      await authentication.memoizedRenewSession();

      return toPromise(forward(operation));
    })(),
  );
}
