import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useState } from 'react';

import { useListUsersSubscription, order_by } from '../../hasura/generated';
import usePagination from '../../helpers/usePagination';

const UserTable = ({ count }: any) => {
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);

  const [sortColumn, setSortColumn] = useState<string>('id');
  const [sortDirection, setSortDirection] = useState<order_by>(order_by['asc']);

  const pagination = usePagination(count, rowsPerPage);
  const order =
    sortColumn === 'sessionsAggregate.aggregate.max.lastSeenAt'
      ? { Sessions_aggregate: { max: { lastSeenAt: order_by[sortDirection] } } }
      : { [sortColumn]: sortDirection };
  const { data, loading } = useListUsersSubscription({
    variables: {
      offset: pagination.offset,
      limit: rowsPerPage,
      order_by: order,
    },
  });

  const [users, setUsers] = useState<any>([]);
  useEffect(() => {
    if (data) {
      setUsers(data.users);
    }
  }, [data]);

  return (
    <>
      <MUIDataTable
        title="Users"
        data={users}
        columns={[
          {
            name: 'id',
            options: {
              display: false,
              sort: false,
              searchable: false,
            },
          },
          {
            name: 'firstName',
            label: 'First Name',
          },
          {
            name: 'lastName',
            label: 'Last Name',
          },
          {
            name: 'email',
            label: 'E-mail',
          },
          {
            name: 'sessionsAggregate.aggregate.max.lastSeenAt',
            label: 'Last Login',
            options: {
              searchable: false,
              customBodyRender: (value: any) =>
                value ? moment(value).format('MM/DD/YYYY, hh:mma') : null,
            },
          },
          {
            name: 'sessionsAggregate.aggregate.max.role',
            label: 'Role',
            options: {
              sort: false,
            },
          },
        ]}
        options={{
          count,
          download: false,
          elevation: 1,
          enableNestedDataAccess: '.',
          filter: false,
          page: pagination.page,
          print: false,
          rowsPerPage,
          rowsPerPageOptions: [25, 50, 100],
          search: false,
          selectableRows: 'none',
          serverSide: true,
          textLabels: {
            body: {
              noMatch: loading
                ? 'Loading data, please wait'
                : 'Sorry, no matching records found',
            },
          },
          viewColumns: false,
          onChangePage: (p: number) => pagination.setPage(p),
          onChangeRowsPerPage: (num: number) => {
            setRowsPerPage(num);
            pagination.setPage(0);
          },
          onColumnSortChange: (col, dir) => {
            setSortColumn(col);
            setSortDirection(order_by[dir]);
          },
        }}
      />
    </>
  );
};

export default UserTable;
