import { Delete } from '@material-ui/icons';
import MUIDataTable from 'mui-datatables';
import React, { useContext, useEffect, useState } from 'react';

import ArchiveMealTypes from '../../components/Forms/EditMealTypes/archive-meal-type-form';
import { ModalContext } from '../../components/Modal/ModalContext';
import { useListMealTypesSubscription, order_by } from '../../hasura/generated';
import usePagination from '../../helpers/usePagination';

const MealTypesTable = ({ count }: any) => {
  const modal = useContext(ModalContext);
  const [rowsPerPage, setRowsPerPage] = useState<number>(50);
  const [sortColumn, setSortColumn] = useState<string>('name');
  const [sortDirection, setSortDirection] = useState<order_by>(order_by['asc']);

  const pagination = usePagination(count, rowsPerPage);
  const order =
    sortColumn === 'orderCount.aggregate.count'
      ? { Orders_aggregate: { count: order_by[sortDirection] } }
      : { [sortColumn]: sortDirection };
  const { data, loading } = useListMealTypesSubscription({
    variables: {
      offset: pagination.offset,
      limit: rowsPerPage,
      order_by: order,
    },
  });

  const [mealTypes, setMealTypes] = useState<any>([]);
  useEffect(() => {
    if (data) {
      setMealTypes(data.mealTypes);
    }
  }, [data]);

  return (
    <>
      <MUIDataTable
        title="Meal Types"
        data={mealTypes}
        columns={[
          {
            name: 'id',
            options: {
              display: false,
              sort: false,
              searchable: false,
            },
          },
          {
            name: 'name',
            label: 'Name',
          },
          {
            name: 'itemsPerPackage',
            label: 'Items per package',
          },
          {
            name: 'orderCount.aggregate.count',
            label: 'Assigned Orders',
          },
          {
            name: 'archivable',
            label: ' ',
            options: {
              sort: false,
              customBodyRender: (value: any, tableMeta: any) => {
                if (value === true) {
                  return (
                    <Delete
                      color="primary"
                      className="edit"
                      onClick={() => {
                        modal.showModal(ArchiveMealTypes, {
                          initialValues: {
                            ...mealTypes.find(
                              ({ id }: any) => id === tableMeta.rowData[0],
                            ),
                          },
                        });
                      }}
                    />
                  );
                }
                return null;
              },
            },
          },
        ]}
        options={{
          count,
          download: false,
          elevation: 1,
          enableNestedDataAccess: '.',
          filter: false,
          search: false,
          page: pagination.page,
          print: false,
          rowsPerPage: Number(rowsPerPage),
          rowsPerPageOptions: [25, 50, 100],
          selectableRows: 'none',
          serverSide: true,
          textLabels: {
            body: {
              noMatch: loading
                ? 'Loading data, please wait'
                : 'Sorry, no matching records found',
            },
          },
          viewColumns: false,
          onChangePage: (p) => pagination.setPage(p),
          onChangeRowsPerPage: (num) => {
            setRowsPerPage(num);
            pagination.setPage(0);
          },
          onColumnSortChange: (col, dir) => {
            setSortColumn(col);
            setSortDirection(order_by[dir]);
          },
        }}
      />
    </>
  );
};

export default MealTypesTable;
