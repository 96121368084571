/* eslint-disable */
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A special custom Scalar type for Dates that converts to a ISO formatted string  */
  Date: any;
  /** The `JSON` scalar type represents raw JSON as values. */
  SequelizeJSON: any;
  date: any;
  timestamptz: any;
  uuid: any;
};


export type ApplicationKidsCafe = {
  __typename?: 'ApplicationKidsCafe';
  agreeToDocumentation?: Maybe<Scalars['Boolean']>;
  agreeToReimbursement?: Maybe<Scalars['Boolean']>;
  applicationKidsCafeId: Scalars['String'];
  applicationYear: Scalars['String'];
  bogoLunchEndTime?: Maybe<Scalars['String']>;
  bogoLunchStartTime?: Maybe<Scalars['String']>;
  breakfastEndTime?: Maybe<Scalars['String']>;
  breakfastStartTime?: Maybe<Scalars['String']>;
  brunchEndTime?: Maybe<Scalars['String']>;
  brunchStartTime?: Maybe<Scalars['String']>;
  certififedVerification?: Maybe<Scalars['Boolean']>;
  childcareLicense: ApplicationKidsCafechildcareLicenseEnumType;
  closureDates: Scalars['String'];
  createdAt: Scalars['Date'];
  daysForService: Scalars['String'];
  deletedAt?: Maybe<Scalars['Date']>;
  educatorId?: Maybe<Scalars['String']>;
  endDate: Scalars['Date'];
  enrichmentPrograms: Scalars['String'];
  forProfit: Scalars['Boolean'];
  gateCode?: Maybe<Scalars['String']>;
  hasAfterSchool?: Maybe<Scalars['Boolean']>;
  hasInfantsEnrolled?: Maybe<Scalars['Boolean']>;
  hasMicrowave?: Maybe<Scalars['Boolean']>;
  hasRecordingDevice: Scalars['Boolean'];
  hasStorage: Scalars['Boolean'];
  hasWifi: Scalars['Boolean'];
  interestBogoLunch: Scalars['Boolean'];
  interestBreakfast: Scalars['Boolean'];
  interestBrunch: Scalars['Boolean'];
  interestLunch: Scalars['Boolean'];
  interestSnack: Scalars['Boolean'];
  interestSupper: Scalars['Boolean'];
  isChildcareCenter: Scalars['Boolean'];
  isFederalParticipant?: Maybe<Scalars['Boolean']>;
  isOpenEnrolledYear?: Maybe<Scalars['Boolean']>;
  isTexasRisingStar?: Maybe<ApplicationKidsCafeisTexasRisingStarEnumType>;
  lunchEndTime?: Maybe<Scalars['String']>;
  lunchStartTime?: Maybe<Scalars['String']>;
  new: Scalars['Boolean'];
  numExpectedParticipants: Scalars['Int'];
  numExpectedParticipantsBogoLunch?: Maybe<Scalars['Int']>;
  numExpectedParticipantsBreakfast?: Maybe<Scalars['Int']>;
  numExpectedParticipantsBrunch?: Maybe<Scalars['Int']>;
  numExpectedParticipantsLunch?: Maybe<Scalars['Int']>;
  numExpectedParticipantsSnack?: Maybe<Scalars['Int']>;
  numExpectedParticipantsSupper?: Maybe<Scalars['Int']>;
  operatingHoursEndTime: Scalars['String'];
  operatingHoursStartTime: Scalars['String'];
  participantAgeRanges: Scalars['String'];
  receivesDeliveries: Scalars['Boolean'];
  referredBy?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  snackEndTime?: Maybe<Scalars['String']>;
  snackStartTime?: Maybe<Scalars['String']>;
  startDate: Scalars['Date'];
  status: ApplicationKidsCafestatusEnumType;
  submittedAt?: Maybe<Scalars['Date']>;
  supperEndTime?: Maybe<Scalars['String']>;
  supperStartTime?: Maybe<Scalars['String']>;
  type: ApplicationKidsCafetypeEnumType;
  updatedAt?: Maybe<Scalars['Date']>;
};

export enum ApplicationKidsCafechildcareLicenseEnumType {
  EXEMPT = 'EXEMPT',
  NO = 'NO',
  YES = 'YES'
}

export enum ApplicationKidsCafeisTexasRisingStarEnumType {
  EXEMPT = 'EXEMPT',
  NO = 'NO',
  YES = 'YES'
}

export enum ApplicationKidsCafestatusEnumType {
  APPROVED = 'APPROVED',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING'
}

export enum ApplicationKidsCafetypeEnumType {
  SCHOOL = 'SCHOOL',
  SUMMER = 'SUMMER'
}

export enum AuthStrategy {
  AZURE_AD = 'AZURE_AD',
  LOCAL = 'LOCAL'
}

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_comparison_exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};


/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_comparison_exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

/** columns and relationships of "MealType" */
export type MealType = {
  __typename?: 'MealType';
  /** An array relationship */
  Orders: Array<Order>;
  /** An aggregate relationship */
  Orders_aggregate: Order_aggregate;
  archivable: Scalars['Boolean'];
  archived?: Maybe<Scalars['timestamptz']>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  itemsPerPackage: Scalars['Int'];
  name: Scalars['String'];
};


/** columns and relationships of "MealType" */
export type MealTypeOrdersArgs = {
  distinct_on?: Maybe<Array<Order_select_column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_order_by>>;
  where?: Maybe<Order_bool_exp>;
};


/** columns and relationships of "MealType" */
export type MealTypeOrders_aggregateArgs = {
  distinct_on?: Maybe<Array<Order_select_column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_order_by>>;
  where?: Maybe<Order_bool_exp>;
};

/** aggregated selection of "MealType" */
export type MealType_aggregate = {
  __typename?: 'MealType_aggregate';
  aggregate?: Maybe<MealType_aggregate_fields>;
  nodes: Array<MealType>;
};

/** aggregate fields of "MealType" */
export type MealType_aggregate_fields = {
  __typename?: 'MealType_aggregate_fields';
  avg?: Maybe<MealType_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<MealType_max_fields>;
  min?: Maybe<MealType_min_fields>;
  stddev?: Maybe<MealType_stddev_fields>;
  stddev_pop?: Maybe<MealType_stddev_pop_fields>;
  stddev_samp?: Maybe<MealType_stddev_samp_fields>;
  sum?: Maybe<MealType_sum_fields>;
  var_pop?: Maybe<MealType_var_pop_fields>;
  var_samp?: Maybe<MealType_var_samp_fields>;
  variance?: Maybe<MealType_variance_fields>;
};


/** aggregate fields of "MealType" */
export type MealType_aggregate_fieldscountArgs = {
  columns?: Maybe<Array<MealType_select_column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type MealType_avg_fields = {
  __typename?: 'MealType_avg_fields';
  itemsPerPackage?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "MealType". All fields are combined with a logical 'AND'. */
export type MealType_bool_exp = {
  Orders?: Maybe<Order_bool_exp>;
  _and?: Maybe<Array<MealType_bool_exp>>;
  _not?: Maybe<MealType_bool_exp>;
  _or?: Maybe<Array<MealType_bool_exp>>;
  archivable?: Maybe<Boolean_comparison_exp>;
  archived?: Maybe<timestamptz_comparison_exp>;
  createdAt?: Maybe<timestamptz_comparison_exp>;
  id?: Maybe<uuid_comparison_exp>;
  itemsPerPackage?: Maybe<Int_comparison_exp>;
  name?: Maybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "MealType" */
export enum MealType_constraint {
  /** unique or primary key constraint */
  MealType_pkey = 'MealType_pkey'
}

/** input type for incrementing numeric columns in table "MealType" */
export type MealType_inc_input = {
  itemsPerPackage?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "MealType" */
export type MealType_insert_input = {
  Orders?: Maybe<Order_arr_rel_insert_input>;
  archivable?: Maybe<Scalars['Boolean']>;
  archived?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  itemsPerPackage?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type MealType_max_fields = {
  __typename?: 'MealType_max_fields';
  archived?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  itemsPerPackage?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type MealType_min_fields = {
  __typename?: 'MealType_min_fields';
  archived?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  itemsPerPackage?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "MealType" */
export type MealType_mutation_response = {
  __typename?: 'MealType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MealType>;
};

/** input type for inserting object relation for remote table "MealType" */
export type MealType_obj_rel_insert_input = {
  data: MealType_insert_input;
  /** on conflict condition */
  on_conflict?: Maybe<MealType_on_conflict>;
};

/** on conflict condition type for table "MealType" */
export type MealType_on_conflict = {
  constraint: MealType_constraint;
  update_columns?: Array<MealType_update_column>;
  where?: Maybe<MealType_bool_exp>;
};

/** Ordering options when selecting data from "MealType". */
export type MealType_order_by = {
  Orders_aggregate?: Maybe<Order_aggregate_order_by>;
  archivable?: Maybe<order_by>;
  archived?: Maybe<order_by>;
  createdAt?: Maybe<order_by>;
  id?: Maybe<order_by>;
  itemsPerPackage?: Maybe<order_by>;
  name?: Maybe<order_by>;
};

/** primary key columns input for table: MealType */
export type MealType_pk_columns_input = {
  id: Scalars['uuid'];
};

/** select columns of table "MealType" */
export enum MealType_select_column {
  /** column name */
  archivable = 'archivable',
  /** column name */
  archived = 'archived',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  id = 'id',
  /** column name */
  itemsPerPackage = 'itemsPerPackage',
  /** column name */
  name = 'name'
}

/** input type for updating data in table "MealType" */
export type MealType_set_input = {
  archivable?: Maybe<Scalars['Boolean']>;
  archived?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  itemsPerPackage?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type MealType_stddev_fields = {
  __typename?: 'MealType_stddev_fields';
  itemsPerPackage?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type MealType_stddev_pop_fields = {
  __typename?: 'MealType_stddev_pop_fields';
  itemsPerPackage?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type MealType_stddev_samp_fields = {
  __typename?: 'MealType_stddev_samp_fields';
  itemsPerPackage?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type MealType_sum_fields = {
  __typename?: 'MealType_sum_fields';
  itemsPerPackage?: Maybe<Scalars['Int']>;
};

/** update columns of table "MealType" */
export enum MealType_update_column {
  /** column name */
  archivable = 'archivable',
  /** column name */
  archived = 'archived',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  id = 'id',
  /** column name */
  itemsPerPackage = 'itemsPerPackage',
  /** column name */
  name = 'name'
}

/** aggregate var_pop on columns */
export type MealType_var_pop_fields = {
  __typename?: 'MealType_var_pop_fields';
  itemsPerPackage?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type MealType_var_samp_fields = {
  __typename?: 'MealType_var_samp_fields';
  itemsPerPackage?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type MealType_variance_fields = {
  __typename?: 'MealType_variance_fields';
  itemsPerPackage?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Order" */
export type Order = {
  __typename?: 'Order';
  /** An object relationship */
  MealType: MealType;
  /** An object relationship */
  OrderStatusEnum: OrderStatusEnum;
  /** An object relationship */
  Route?: Maybe<Route>;
  Site?: Maybe<Array<Maybe<Site>>>;
  /** An object relationship */
  User?: Maybe<User>;
  applicationId?: Maybe<Scalars['uuid']>;
  archived?: Maybe<Scalars['timestamptz']>;
  createdAt: Scalars['timestamptz'];
  deliveryDay: Scalars['date'];
  id: Scalars['uuid'];
  lastEditedByUserId?: Maybe<Scalars['uuid']>;
  mealTypeId: Scalars['uuid'];
  quantityLoaded: Scalars['Int'];
  quantityOrdered: Scalars['Int'];
  routeId?: Maybe<Scalars['uuid']>;
  siteId: Scalars['String'];
  status: OrderStatusEnum_enum;
  truckNumber?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "Order" */
export type OrderSiteArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  where?: Maybe<Scalars['SequelizeJSON']>;
};

/** columns and relationships of "OrderStatusEnum" */
export type OrderStatusEnum = {
  __typename?: 'OrderStatusEnum';
  /** An array relationship */
  Orders: Array<Order>;
  /** An aggregate relationship */
  Orders_aggregate: Order_aggregate;
  label: Scalars['String'];
  value: Scalars['String'];
};


/** columns and relationships of "OrderStatusEnum" */
export type OrderStatusEnumOrdersArgs = {
  distinct_on?: Maybe<Array<Order_select_column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_order_by>>;
  where?: Maybe<Order_bool_exp>;
};


/** columns and relationships of "OrderStatusEnum" */
export type OrderStatusEnumOrders_aggregateArgs = {
  distinct_on?: Maybe<Array<Order_select_column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_order_by>>;
  where?: Maybe<Order_bool_exp>;
};

/** aggregated selection of "OrderStatusEnum" */
export type OrderStatusEnum_aggregate = {
  __typename?: 'OrderStatusEnum_aggregate';
  aggregate?: Maybe<OrderStatusEnum_aggregate_fields>;
  nodes: Array<OrderStatusEnum>;
};

/** aggregate fields of "OrderStatusEnum" */
export type OrderStatusEnum_aggregate_fields = {
  __typename?: 'OrderStatusEnum_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<OrderStatusEnum_max_fields>;
  min?: Maybe<OrderStatusEnum_min_fields>;
};


/** aggregate fields of "OrderStatusEnum" */
export type OrderStatusEnum_aggregate_fieldscountArgs = {
  columns?: Maybe<Array<OrderStatusEnum_select_column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "OrderStatusEnum". All fields are combined with a logical 'AND'. */
export type OrderStatusEnum_bool_exp = {
  Orders?: Maybe<Order_bool_exp>;
  _and?: Maybe<Array<OrderStatusEnum_bool_exp>>;
  _not?: Maybe<OrderStatusEnum_bool_exp>;
  _or?: Maybe<Array<OrderStatusEnum_bool_exp>>;
  label?: Maybe<String_comparison_exp>;
  value?: Maybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "OrderStatusEnum" */
export enum OrderStatusEnum_constraint {
  /** unique or primary key constraint */
  OrderStatusEnum_pkey = 'OrderStatusEnum_pkey'
}

export enum OrderStatusEnum_enum {
  /** Delivered */
  delivered = 'delivered',
  /** Draft */
  draft = 'draft',
  /** Loaded on Truck */
  loaded_on_truck = 'loaded_on_truck',
  /** Pending Kitchen Staff Approval */
  pending_kitchen_staff_approval = 'pending_kitchen_staff_approval',
  /** Ready for Truck Loading */
  ready_for_truck_loading = 'ready_for_truck_loading',
  /** Ready to Prep */
  ready_to_prep = 'ready_to_prep'
}

/** Boolean expression to compare columns of type "OrderStatusEnum_enum". All fields are combined with logical 'AND'. */
export type OrderStatusEnum_enum_comparison_exp = {
  _eq?: Maybe<OrderStatusEnum_enum>;
  _in?: Maybe<Array<OrderStatusEnum_enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<OrderStatusEnum_enum>;
  _nin?: Maybe<Array<OrderStatusEnum_enum>>;
};

/** input type for inserting data into table "OrderStatusEnum" */
export type OrderStatusEnum_insert_input = {
  Orders?: Maybe<Order_arr_rel_insert_input>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type OrderStatusEnum_max_fields = {
  __typename?: 'OrderStatusEnum_max_fields';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type OrderStatusEnum_min_fields = {
  __typename?: 'OrderStatusEnum_min_fields';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "OrderStatusEnum" */
export type OrderStatusEnum_mutation_response = {
  __typename?: 'OrderStatusEnum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<OrderStatusEnum>;
};

/** input type for inserting object relation for remote table "OrderStatusEnum" */
export type OrderStatusEnum_obj_rel_insert_input = {
  data: OrderStatusEnum_insert_input;
  /** on conflict condition */
  on_conflict?: Maybe<OrderStatusEnum_on_conflict>;
};

/** on conflict condition type for table "OrderStatusEnum" */
export type OrderStatusEnum_on_conflict = {
  constraint: OrderStatusEnum_constraint;
  update_columns?: Array<OrderStatusEnum_update_column>;
  where?: Maybe<OrderStatusEnum_bool_exp>;
};

/** Ordering options when selecting data from "OrderStatusEnum". */
export type OrderStatusEnum_order_by = {
  Orders_aggregate?: Maybe<Order_aggregate_order_by>;
  label?: Maybe<order_by>;
  value?: Maybe<order_by>;
};

/** primary key columns input for table: OrderStatusEnum */
export type OrderStatusEnum_pk_columns_input = {
  value: Scalars['String'];
};

/** select columns of table "OrderStatusEnum" */
export enum OrderStatusEnum_select_column {
  /** column name */
  label = 'label',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "OrderStatusEnum" */
export type OrderStatusEnum_set_input = {
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "OrderStatusEnum" */
export enum OrderStatusEnum_update_column {
  /** column name */
  label = 'label',
  /** column name */
  value = 'value'
}

/** aggregated selection of "Order" */
export type Order_aggregate = {
  __typename?: 'Order_aggregate';
  aggregate?: Maybe<Order_aggregate_fields>;
  nodes: Array<Order>;
};

/** aggregate fields of "Order" */
export type Order_aggregate_fields = {
  __typename?: 'Order_aggregate_fields';
  avg?: Maybe<Order_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<Order_max_fields>;
  min?: Maybe<Order_min_fields>;
  stddev?: Maybe<Order_stddev_fields>;
  stddev_pop?: Maybe<Order_stddev_pop_fields>;
  stddev_samp?: Maybe<Order_stddev_samp_fields>;
  sum?: Maybe<Order_sum_fields>;
  var_pop?: Maybe<Order_var_pop_fields>;
  var_samp?: Maybe<Order_var_samp_fields>;
  variance?: Maybe<Order_variance_fields>;
};


/** aggregate fields of "Order" */
export type Order_aggregate_fieldscountArgs = {
  columns?: Maybe<Array<Order_select_column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Order" */
export type Order_aggregate_order_by = {
  avg?: Maybe<Order_avg_order_by>;
  count?: Maybe<order_by>;
  max?: Maybe<Order_max_order_by>;
  min?: Maybe<Order_min_order_by>;
  stddev?: Maybe<Order_stddev_order_by>;
  stddev_pop?: Maybe<Order_stddev_pop_order_by>;
  stddev_samp?: Maybe<Order_stddev_samp_order_by>;
  sum?: Maybe<Order_sum_order_by>;
  var_pop?: Maybe<Order_var_pop_order_by>;
  var_samp?: Maybe<Order_var_samp_order_by>;
  variance?: Maybe<Order_variance_order_by>;
};

/** input type for inserting array relation for remote table "Order" */
export type Order_arr_rel_insert_input = {
  data: Array<Order_insert_input>;
  /** on conflict condition */
  on_conflict?: Maybe<Order_on_conflict>;
};

/** aggregate avg on columns */
export type Order_avg_fields = {
  __typename?: 'Order_avg_fields';
  quantityLoaded?: Maybe<Scalars['Float']>;
  quantityOrdered?: Maybe<Scalars['Float']>;
  truckNumber?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Order" */
export type Order_avg_order_by = {
  quantityLoaded?: Maybe<order_by>;
  quantityOrdered?: Maybe<order_by>;
  truckNumber?: Maybe<order_by>;
};

/** Boolean expression to filter rows from the table "Order". All fields are combined with a logical 'AND'. */
export type Order_bool_exp = {
  MealType?: Maybe<MealType_bool_exp>;
  OrderStatusEnum?: Maybe<OrderStatusEnum_bool_exp>;
  Route?: Maybe<Route_bool_exp>;
  User?: Maybe<User_bool_exp>;
  _and?: Maybe<Array<Order_bool_exp>>;
  _not?: Maybe<Order_bool_exp>;
  _or?: Maybe<Array<Order_bool_exp>>;
  applicationId?: Maybe<uuid_comparison_exp>;
  archived?: Maybe<timestamptz_comparison_exp>;
  createdAt?: Maybe<timestamptz_comparison_exp>;
  deliveryDay?: Maybe<date_comparison_exp>;
  id?: Maybe<uuid_comparison_exp>;
  lastEditedByUserId?: Maybe<uuid_comparison_exp>;
  mealTypeId?: Maybe<uuid_comparison_exp>;
  quantityLoaded?: Maybe<Int_comparison_exp>;
  quantityOrdered?: Maybe<Int_comparison_exp>;
  routeId?: Maybe<uuid_comparison_exp>;
  siteId?: Maybe<String_comparison_exp>;
  status?: Maybe<OrderStatusEnum_enum_comparison_exp>;
  truckNumber?: Maybe<Int_comparison_exp>;
  updatedAt?: Maybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "Order" */
export enum Order_constraint {
  /** unique or primary key constraint */
  Order_pkey = 'Order_pkey',
  /** unique or primary key constraint */
  Order_site_id_delivery_day_meal_type_id_key = 'Order_site_id_delivery_day_meal_type_id_key'
}

/** input type for incrementing numeric columns in table "Order" */
export type Order_inc_input = {
  quantityLoaded?: Maybe<Scalars['Int']>;
  quantityOrdered?: Maybe<Scalars['Int']>;
  truckNumber?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Order" */
export type Order_insert_input = {
  MealType?: Maybe<MealType_obj_rel_insert_input>;
  OrderStatusEnum?: Maybe<OrderStatusEnum_obj_rel_insert_input>;
  Route?: Maybe<Route_obj_rel_insert_input>;
  User?: Maybe<User_obj_rel_insert_input>;
  applicationId?: Maybe<Scalars['uuid']>;
  archived?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deliveryDay?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  lastEditedByUserId?: Maybe<Scalars['uuid']>;
  mealTypeId?: Maybe<Scalars['uuid']>;
  quantityLoaded?: Maybe<Scalars['Int']>;
  quantityOrdered?: Maybe<Scalars['Int']>;
  routeId?: Maybe<Scalars['uuid']>;
  siteId?: Maybe<Scalars['String']>;
  status?: Maybe<OrderStatusEnum_enum>;
  truckNumber?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Order_max_fields = {
  __typename?: 'Order_max_fields';
  applicationId?: Maybe<Scalars['uuid']>;
  archived?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deliveryDay?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  lastEditedByUserId?: Maybe<Scalars['uuid']>;
  mealTypeId?: Maybe<Scalars['uuid']>;
  quantityLoaded?: Maybe<Scalars['Int']>;
  quantityOrdered?: Maybe<Scalars['Int']>;
  routeId?: Maybe<Scalars['uuid']>;
  siteId?: Maybe<Scalars['String']>;
  truckNumber?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Order" */
export type Order_max_order_by = {
  applicationId?: Maybe<order_by>;
  archived?: Maybe<order_by>;
  createdAt?: Maybe<order_by>;
  deliveryDay?: Maybe<order_by>;
  id?: Maybe<order_by>;
  lastEditedByUserId?: Maybe<order_by>;
  mealTypeId?: Maybe<order_by>;
  quantityLoaded?: Maybe<order_by>;
  quantityOrdered?: Maybe<order_by>;
  routeId?: Maybe<order_by>;
  siteId?: Maybe<order_by>;
  truckNumber?: Maybe<order_by>;
  updatedAt?: Maybe<order_by>;
};

/** aggregate min on columns */
export type Order_min_fields = {
  __typename?: 'Order_min_fields';
  applicationId?: Maybe<Scalars['uuid']>;
  archived?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deliveryDay?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  lastEditedByUserId?: Maybe<Scalars['uuid']>;
  mealTypeId?: Maybe<Scalars['uuid']>;
  quantityLoaded?: Maybe<Scalars['Int']>;
  quantityOrdered?: Maybe<Scalars['Int']>;
  routeId?: Maybe<Scalars['uuid']>;
  siteId?: Maybe<Scalars['String']>;
  truckNumber?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Order" */
export type Order_min_order_by = {
  applicationId?: Maybe<order_by>;
  archived?: Maybe<order_by>;
  createdAt?: Maybe<order_by>;
  deliveryDay?: Maybe<order_by>;
  id?: Maybe<order_by>;
  lastEditedByUserId?: Maybe<order_by>;
  mealTypeId?: Maybe<order_by>;
  quantityLoaded?: Maybe<order_by>;
  quantityOrdered?: Maybe<order_by>;
  routeId?: Maybe<order_by>;
  siteId?: Maybe<order_by>;
  truckNumber?: Maybe<order_by>;
  updatedAt?: Maybe<order_by>;
};

/** response of any mutation on the table "Order" */
export type Order_mutation_response = {
  __typename?: 'Order_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Order>;
};

/** on conflict condition type for table "Order" */
export type Order_on_conflict = {
  constraint: Order_constraint;
  update_columns?: Array<Order_update_column>;
  where?: Maybe<Order_bool_exp>;
};

/** Ordering options when selecting data from "Order". */
export type Order_order_by = {
  MealType?: Maybe<MealType_order_by>;
  OrderStatusEnum?: Maybe<OrderStatusEnum_order_by>;
  Route?: Maybe<Route_order_by>;
  User?: Maybe<User_order_by>;
  applicationId?: Maybe<order_by>;
  archived?: Maybe<order_by>;
  createdAt?: Maybe<order_by>;
  deliveryDay?: Maybe<order_by>;
  id?: Maybe<order_by>;
  lastEditedByUserId?: Maybe<order_by>;
  mealTypeId?: Maybe<order_by>;
  quantityLoaded?: Maybe<order_by>;
  quantityOrdered?: Maybe<order_by>;
  routeId?: Maybe<order_by>;
  siteId?: Maybe<order_by>;
  status?: Maybe<order_by>;
  truckNumber?: Maybe<order_by>;
  updatedAt?: Maybe<order_by>;
};

/** primary key columns input for table: Order */
export type Order_pk_columns_input = {
  id: Scalars['uuid'];
};

/** select columns of table "Order" */
export enum Order_select_column {
  /** column name */
  applicationId = 'applicationId',
  /** column name */
  archived = 'archived',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  deliveryDay = 'deliveryDay',
  /** column name */
  id = 'id',
  /** column name */
  lastEditedByUserId = 'lastEditedByUserId',
  /** column name */
  mealTypeId = 'mealTypeId',
  /** column name */
  quantityLoaded = 'quantityLoaded',
  /** column name */
  quantityOrdered = 'quantityOrdered',
  /** column name */
  routeId = 'routeId',
  /** column name */
  siteId = 'siteId',
  /** column name */
  status = 'status',
  /** column name */
  truckNumber = 'truckNumber',
  /** column name */
  updatedAt = 'updatedAt'
}

/** input type for updating data in table "Order" */
export type Order_set_input = {
  applicationId?: Maybe<Scalars['uuid']>;
  archived?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deliveryDay?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  lastEditedByUserId?: Maybe<Scalars['uuid']>;
  mealTypeId?: Maybe<Scalars['uuid']>;
  quantityLoaded?: Maybe<Scalars['Int']>;
  quantityOrdered?: Maybe<Scalars['Int']>;
  routeId?: Maybe<Scalars['uuid']>;
  siteId?: Maybe<Scalars['String']>;
  status?: Maybe<OrderStatusEnum_enum>;
  truckNumber?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Order_stddev_fields = {
  __typename?: 'Order_stddev_fields';
  quantityLoaded?: Maybe<Scalars['Float']>;
  quantityOrdered?: Maybe<Scalars['Float']>;
  truckNumber?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Order" */
export type Order_stddev_order_by = {
  quantityLoaded?: Maybe<order_by>;
  quantityOrdered?: Maybe<order_by>;
  truckNumber?: Maybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type Order_stddev_pop_fields = {
  __typename?: 'Order_stddev_pop_fields';
  quantityLoaded?: Maybe<Scalars['Float']>;
  quantityOrdered?: Maybe<Scalars['Float']>;
  truckNumber?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Order" */
export type Order_stddev_pop_order_by = {
  quantityLoaded?: Maybe<order_by>;
  quantityOrdered?: Maybe<order_by>;
  truckNumber?: Maybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type Order_stddev_samp_fields = {
  __typename?: 'Order_stddev_samp_fields';
  quantityLoaded?: Maybe<Scalars['Float']>;
  quantityOrdered?: Maybe<Scalars['Float']>;
  truckNumber?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Order" */
export type Order_stddev_samp_order_by = {
  quantityLoaded?: Maybe<order_by>;
  quantityOrdered?: Maybe<order_by>;
  truckNumber?: Maybe<order_by>;
};

/** aggregate sum on columns */
export type Order_sum_fields = {
  __typename?: 'Order_sum_fields';
  quantityLoaded?: Maybe<Scalars['Int']>;
  quantityOrdered?: Maybe<Scalars['Int']>;
  truckNumber?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Order" */
export type Order_sum_order_by = {
  quantityLoaded?: Maybe<order_by>;
  quantityOrdered?: Maybe<order_by>;
  truckNumber?: Maybe<order_by>;
};

/** update columns of table "Order" */
export enum Order_update_column {
  /** column name */
  applicationId = 'applicationId',
  /** column name */
  archived = 'archived',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  deliveryDay = 'deliveryDay',
  /** column name */
  id = 'id',
  /** column name */
  lastEditedByUserId = 'lastEditedByUserId',
  /** column name */
  mealTypeId = 'mealTypeId',
  /** column name */
  quantityLoaded = 'quantityLoaded',
  /** column name */
  quantityOrdered = 'quantityOrdered',
  /** column name */
  routeId = 'routeId',
  /** column name */
  siteId = 'siteId',
  /** column name */
  status = 'status',
  /** column name */
  truckNumber = 'truckNumber',
  /** column name */
  updatedAt = 'updatedAt'
}

/** aggregate var_pop on columns */
export type Order_var_pop_fields = {
  __typename?: 'Order_var_pop_fields';
  quantityLoaded?: Maybe<Scalars['Float']>;
  quantityOrdered?: Maybe<Scalars['Float']>;
  truckNumber?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Order" */
export type Order_var_pop_order_by = {
  quantityLoaded?: Maybe<order_by>;
  quantityOrdered?: Maybe<order_by>;
  truckNumber?: Maybe<order_by>;
};

/** aggregate var_samp on columns */
export type Order_var_samp_fields = {
  __typename?: 'Order_var_samp_fields';
  quantityLoaded?: Maybe<Scalars['Float']>;
  quantityOrdered?: Maybe<Scalars['Float']>;
  truckNumber?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Order" */
export type Order_var_samp_order_by = {
  quantityLoaded?: Maybe<order_by>;
  quantityOrdered?: Maybe<order_by>;
  truckNumber?: Maybe<order_by>;
};

/** aggregate variance on columns */
export type Order_variance_fields = {
  __typename?: 'Order_variance_fields';
  quantityLoaded?: Maybe<Scalars['Float']>;
  quantityOrdered?: Maybe<Scalars['Float']>;
  truckNumber?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Order" */
export type Order_variance_order_by = {
  quantityLoaded?: Maybe<order_by>;
  quantityOrdered?: Maybe<order_by>;
  truckNumber?: Maybe<order_by>;
};

/** columns and relationships of "Route" */
export type Route = {
  __typename?: 'Route';
  /** An array relationship */
  RouteDeliverySites: Array<RouteDeliverySite>;
  /** An aggregate relationship */
  RouteDeliverySites_aggregate: RouteDeliverySite_aggregate;
  archived?: Maybe<Scalars['timestamptz']>;
  createdAt: Scalars['timestamptz'];
  defaultTruckNumber?: Maybe<Scalars['Int']>;
  id: Scalars['uuid'];
  loadingOrder?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
};


/** columns and relationships of "Route" */
export type RouteRouteDeliverySitesArgs = {
  distinct_on?: Maybe<Array<RouteDeliverySite_select_column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RouteDeliverySite_order_by>>;
  where?: Maybe<RouteDeliverySite_bool_exp>;
};


/** columns and relationships of "Route" */
export type RouteRouteDeliverySites_aggregateArgs = {
  distinct_on?: Maybe<Array<RouteDeliverySite_select_column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RouteDeliverySite_order_by>>;
  where?: Maybe<RouteDeliverySite_bool_exp>;
};

/** columns and relationships of "RouteDeliverySite" */
export type RouteDeliverySite = {
  __typename?: 'RouteDeliverySite';
  /** An object relationship */
  Route: Route;
  Site?: Maybe<Array<Maybe<Site>>>;
  id: Scalars['uuid'];
  routeId: Scalars['uuid'];
  siteDeliveryOrder?: Maybe<Scalars['Int']>;
  siteId: Scalars['String'];
};


/** columns and relationships of "RouteDeliverySite" */
export type RouteDeliverySiteSiteArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  where?: Maybe<Scalars['SequelizeJSON']>;
};

/** aggregated selection of "RouteDeliverySite" */
export type RouteDeliverySite_aggregate = {
  __typename?: 'RouteDeliverySite_aggregate';
  aggregate?: Maybe<RouteDeliverySite_aggregate_fields>;
  nodes: Array<RouteDeliverySite>;
};

/** aggregate fields of "RouteDeliverySite" */
export type RouteDeliverySite_aggregate_fields = {
  __typename?: 'RouteDeliverySite_aggregate_fields';
  avg?: Maybe<RouteDeliverySite_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<RouteDeliverySite_max_fields>;
  min?: Maybe<RouteDeliverySite_min_fields>;
  stddev?: Maybe<RouteDeliverySite_stddev_fields>;
  stddev_pop?: Maybe<RouteDeliverySite_stddev_pop_fields>;
  stddev_samp?: Maybe<RouteDeliverySite_stddev_samp_fields>;
  sum?: Maybe<RouteDeliverySite_sum_fields>;
  var_pop?: Maybe<RouteDeliverySite_var_pop_fields>;
  var_samp?: Maybe<RouteDeliverySite_var_samp_fields>;
  variance?: Maybe<RouteDeliverySite_variance_fields>;
};


/** aggregate fields of "RouteDeliverySite" */
export type RouteDeliverySite_aggregate_fieldscountArgs = {
  columns?: Maybe<Array<RouteDeliverySite_select_column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "RouteDeliverySite" */
export type RouteDeliverySite_aggregate_order_by = {
  avg?: Maybe<RouteDeliverySite_avg_order_by>;
  count?: Maybe<order_by>;
  max?: Maybe<RouteDeliverySite_max_order_by>;
  min?: Maybe<RouteDeliverySite_min_order_by>;
  stddev?: Maybe<RouteDeliverySite_stddev_order_by>;
  stddev_pop?: Maybe<RouteDeliverySite_stddev_pop_order_by>;
  stddev_samp?: Maybe<RouteDeliverySite_stddev_samp_order_by>;
  sum?: Maybe<RouteDeliverySite_sum_order_by>;
  var_pop?: Maybe<RouteDeliverySite_var_pop_order_by>;
  var_samp?: Maybe<RouteDeliverySite_var_samp_order_by>;
  variance?: Maybe<RouteDeliverySite_variance_order_by>;
};

/** input type for inserting array relation for remote table "RouteDeliverySite" */
export type RouteDeliverySite_arr_rel_insert_input = {
  data: Array<RouteDeliverySite_insert_input>;
  /** on conflict condition */
  on_conflict?: Maybe<RouteDeliverySite_on_conflict>;
};

/** aggregate avg on columns */
export type RouteDeliverySite_avg_fields = {
  __typename?: 'RouteDeliverySite_avg_fields';
  siteDeliveryOrder?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "RouteDeliverySite" */
export type RouteDeliverySite_avg_order_by = {
  siteDeliveryOrder?: Maybe<order_by>;
};

/** Boolean expression to filter rows from the table "RouteDeliverySite". All fields are combined with a logical 'AND'. */
export type RouteDeliverySite_bool_exp = {
  Route?: Maybe<Route_bool_exp>;
  _and?: Maybe<Array<RouteDeliverySite_bool_exp>>;
  _not?: Maybe<RouteDeliverySite_bool_exp>;
  _or?: Maybe<Array<RouteDeliverySite_bool_exp>>;
  id?: Maybe<uuid_comparison_exp>;
  routeId?: Maybe<uuid_comparison_exp>;
  siteDeliveryOrder?: Maybe<Int_comparison_exp>;
  siteId?: Maybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "RouteDeliverySite" */
export enum RouteDeliverySite_constraint {
  /** unique or primary key constraint */
  RouteDeliverySite_pkey = 'RouteDeliverySite_pkey',
  /** unique or primary key constraint */
  RouteDeliverySite_route_id_site_delivery_order_key = 'RouteDeliverySite_route_id_site_delivery_order_key',
  /** unique or primary key constraint */
  RouteDeliverySite_route_id_site_id_key = 'RouteDeliverySite_route_id_site_id_key'
}

/** input type for incrementing numeric columns in table "RouteDeliverySite" */
export type RouteDeliverySite_inc_input = {
  siteDeliveryOrder?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "RouteDeliverySite" */
export type RouteDeliverySite_insert_input = {
  Route?: Maybe<Route_obj_rel_insert_input>;
  id?: Maybe<Scalars['uuid']>;
  routeId?: Maybe<Scalars['uuid']>;
  siteDeliveryOrder?: Maybe<Scalars['Int']>;
  siteId?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type RouteDeliverySite_max_fields = {
  __typename?: 'RouteDeliverySite_max_fields';
  id?: Maybe<Scalars['uuid']>;
  routeId?: Maybe<Scalars['uuid']>;
  siteDeliveryOrder?: Maybe<Scalars['Int']>;
  siteId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "RouteDeliverySite" */
export type RouteDeliverySite_max_order_by = {
  id?: Maybe<order_by>;
  routeId?: Maybe<order_by>;
  siteDeliveryOrder?: Maybe<order_by>;
  siteId?: Maybe<order_by>;
};

/** aggregate min on columns */
export type RouteDeliverySite_min_fields = {
  __typename?: 'RouteDeliverySite_min_fields';
  id?: Maybe<Scalars['uuid']>;
  routeId?: Maybe<Scalars['uuid']>;
  siteDeliveryOrder?: Maybe<Scalars['Int']>;
  siteId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "RouteDeliverySite" */
export type RouteDeliverySite_min_order_by = {
  id?: Maybe<order_by>;
  routeId?: Maybe<order_by>;
  siteDeliveryOrder?: Maybe<order_by>;
  siteId?: Maybe<order_by>;
};

/** response of any mutation on the table "RouteDeliverySite" */
export type RouteDeliverySite_mutation_response = {
  __typename?: 'RouteDeliverySite_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<RouteDeliverySite>;
};

/** on conflict condition type for table "RouteDeliverySite" */
export type RouteDeliverySite_on_conflict = {
  constraint: RouteDeliverySite_constraint;
  update_columns?: Array<RouteDeliverySite_update_column>;
  where?: Maybe<RouteDeliverySite_bool_exp>;
};

/** Ordering options when selecting data from "RouteDeliverySite". */
export type RouteDeliverySite_order_by = {
  Route?: Maybe<Route_order_by>;
  id?: Maybe<order_by>;
  routeId?: Maybe<order_by>;
  siteDeliveryOrder?: Maybe<order_by>;
  siteId?: Maybe<order_by>;
};

/** primary key columns input for table: RouteDeliverySite */
export type RouteDeliverySite_pk_columns_input = {
  id: Scalars['uuid'];
};

/** select columns of table "RouteDeliverySite" */
export enum RouteDeliverySite_select_column {
  /** column name */
  id = 'id',
  /** column name */
  routeId = 'routeId',
  /** column name */
  siteDeliveryOrder = 'siteDeliveryOrder',
  /** column name */
  siteId = 'siteId'
}

/** input type for updating data in table "RouteDeliverySite" */
export type RouteDeliverySite_set_input = {
  id?: Maybe<Scalars['uuid']>;
  routeId?: Maybe<Scalars['uuid']>;
  siteDeliveryOrder?: Maybe<Scalars['Int']>;
  siteId?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type RouteDeliverySite_stddev_fields = {
  __typename?: 'RouteDeliverySite_stddev_fields';
  siteDeliveryOrder?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "RouteDeliverySite" */
export type RouteDeliverySite_stddev_order_by = {
  siteDeliveryOrder?: Maybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type RouteDeliverySite_stddev_pop_fields = {
  __typename?: 'RouteDeliverySite_stddev_pop_fields';
  siteDeliveryOrder?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "RouteDeliverySite" */
export type RouteDeliverySite_stddev_pop_order_by = {
  siteDeliveryOrder?: Maybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type RouteDeliverySite_stddev_samp_fields = {
  __typename?: 'RouteDeliverySite_stddev_samp_fields';
  siteDeliveryOrder?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "RouteDeliverySite" */
export type RouteDeliverySite_stddev_samp_order_by = {
  siteDeliveryOrder?: Maybe<order_by>;
};

/** aggregate sum on columns */
export type RouteDeliverySite_sum_fields = {
  __typename?: 'RouteDeliverySite_sum_fields';
  siteDeliveryOrder?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "RouteDeliverySite" */
export type RouteDeliverySite_sum_order_by = {
  siteDeliveryOrder?: Maybe<order_by>;
};

/** update columns of table "RouteDeliverySite" */
export enum RouteDeliverySite_update_column {
  /** column name */
  id = 'id',
  /** column name */
  routeId = 'routeId',
  /** column name */
  siteDeliveryOrder = 'siteDeliveryOrder',
  /** column name */
  siteId = 'siteId'
}

/** aggregate var_pop on columns */
export type RouteDeliverySite_var_pop_fields = {
  __typename?: 'RouteDeliverySite_var_pop_fields';
  siteDeliveryOrder?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "RouteDeliverySite" */
export type RouteDeliverySite_var_pop_order_by = {
  siteDeliveryOrder?: Maybe<order_by>;
};

/** aggregate var_samp on columns */
export type RouteDeliverySite_var_samp_fields = {
  __typename?: 'RouteDeliverySite_var_samp_fields';
  siteDeliveryOrder?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "RouteDeliverySite" */
export type RouteDeliverySite_var_samp_order_by = {
  siteDeliveryOrder?: Maybe<order_by>;
};

/** aggregate variance on columns */
export type RouteDeliverySite_variance_fields = {
  __typename?: 'RouteDeliverySite_variance_fields';
  siteDeliveryOrder?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "RouteDeliverySite" */
export type RouteDeliverySite_variance_order_by = {
  siteDeliveryOrder?: Maybe<order_by>;
};

/** aggregated selection of "Route" */
export type Route_aggregate = {
  __typename?: 'Route_aggregate';
  aggregate?: Maybe<Route_aggregate_fields>;
  nodes: Array<Route>;
};

/** aggregate fields of "Route" */
export type Route_aggregate_fields = {
  __typename?: 'Route_aggregate_fields';
  avg?: Maybe<Route_avg_fields>;
  count: Scalars['Int'];
  max?: Maybe<Route_max_fields>;
  min?: Maybe<Route_min_fields>;
  stddev?: Maybe<Route_stddev_fields>;
  stddev_pop?: Maybe<Route_stddev_pop_fields>;
  stddev_samp?: Maybe<Route_stddev_samp_fields>;
  sum?: Maybe<Route_sum_fields>;
  var_pop?: Maybe<Route_var_pop_fields>;
  var_samp?: Maybe<Route_var_samp_fields>;
  variance?: Maybe<Route_variance_fields>;
};


/** aggregate fields of "Route" */
export type Route_aggregate_fieldscountArgs = {
  columns?: Maybe<Array<Route_select_column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Route_avg_fields = {
  __typename?: 'Route_avg_fields';
  defaultTruckNumber?: Maybe<Scalars['Float']>;
  loadingOrder?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Route". All fields are combined with a logical 'AND'. */
export type Route_bool_exp = {
  RouteDeliverySites?: Maybe<RouteDeliverySite_bool_exp>;
  _and?: Maybe<Array<Route_bool_exp>>;
  _not?: Maybe<Route_bool_exp>;
  _or?: Maybe<Array<Route_bool_exp>>;
  archived?: Maybe<timestamptz_comparison_exp>;
  createdAt?: Maybe<timestamptz_comparison_exp>;
  defaultTruckNumber?: Maybe<Int_comparison_exp>;
  id?: Maybe<uuid_comparison_exp>;
  loadingOrder?: Maybe<Int_comparison_exp>;
  name?: Maybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "Route" */
export enum Route_constraint {
  /** unique or primary key constraint */
  Route_default_truck_number_key = 'Route_default_truck_number_key',
  /** unique or primary key constraint */
  Route_loading_order_key = 'Route_loading_order_key',
  /** unique or primary key constraint */
  Route_pkey = 'Route_pkey'
}

/** input type for incrementing numeric columns in table "Route" */
export type Route_inc_input = {
  defaultTruckNumber?: Maybe<Scalars['Int']>;
  loadingOrder?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Route" */
export type Route_insert_input = {
  RouteDeliverySites?: Maybe<RouteDeliverySite_arr_rel_insert_input>;
  archived?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  defaultTruckNumber?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  loadingOrder?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Route_max_fields = {
  __typename?: 'Route_max_fields';
  archived?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  defaultTruckNumber?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  loadingOrder?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Route_min_fields = {
  __typename?: 'Route_min_fields';
  archived?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  defaultTruckNumber?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  loadingOrder?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Route" */
export type Route_mutation_response = {
  __typename?: 'Route_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Route>;
};

/** input type for inserting object relation for remote table "Route" */
export type Route_obj_rel_insert_input = {
  data: Route_insert_input;
  /** on conflict condition */
  on_conflict?: Maybe<Route_on_conflict>;
};

/** on conflict condition type for table "Route" */
export type Route_on_conflict = {
  constraint: Route_constraint;
  update_columns?: Array<Route_update_column>;
  where?: Maybe<Route_bool_exp>;
};

/** Ordering options when selecting data from "Route". */
export type Route_order_by = {
  RouteDeliverySites_aggregate?: Maybe<RouteDeliverySite_aggregate_order_by>;
  archived?: Maybe<order_by>;
  createdAt?: Maybe<order_by>;
  defaultTruckNumber?: Maybe<order_by>;
  id?: Maybe<order_by>;
  loadingOrder?: Maybe<order_by>;
  name?: Maybe<order_by>;
};

/** primary key columns input for table: Route */
export type Route_pk_columns_input = {
  id: Scalars['uuid'];
};

/** select columns of table "Route" */
export enum Route_select_column {
  /** column name */
  archived = 'archived',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  defaultTruckNumber = 'defaultTruckNumber',
  /** column name */
  id = 'id',
  /** column name */
  loadingOrder = 'loadingOrder',
  /** column name */
  name = 'name'
}

/** input type for updating data in table "Route" */
export type Route_set_input = {
  archived?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  defaultTruckNumber?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  loadingOrder?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Route_stddev_fields = {
  __typename?: 'Route_stddev_fields';
  defaultTruckNumber?: Maybe<Scalars['Float']>;
  loadingOrder?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Route_stddev_pop_fields = {
  __typename?: 'Route_stddev_pop_fields';
  defaultTruckNumber?: Maybe<Scalars['Float']>;
  loadingOrder?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Route_stddev_samp_fields = {
  __typename?: 'Route_stddev_samp_fields';
  defaultTruckNumber?: Maybe<Scalars['Float']>;
  loadingOrder?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Route_sum_fields = {
  __typename?: 'Route_sum_fields';
  defaultTruckNumber?: Maybe<Scalars['Int']>;
  loadingOrder?: Maybe<Scalars['Int']>;
};

/** update columns of table "Route" */
export enum Route_update_column {
  /** column name */
  archived = 'archived',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  defaultTruckNumber = 'defaultTruckNumber',
  /** column name */
  id = 'id',
  /** column name */
  loadingOrder = 'loadingOrder',
  /** column name */
  name = 'name'
}

/** aggregate var_pop on columns */
export type Route_var_pop_fields = {
  __typename?: 'Route_var_pop_fields';
  defaultTruckNumber?: Maybe<Scalars['Float']>;
  loadingOrder?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Route_var_samp_fields = {
  __typename?: 'Route_var_samp_fields';
  defaultTruckNumber?: Maybe<Scalars['Float']>;
  loadingOrder?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Route_variance_fields = {
  __typename?: 'Route_variance_fields';
  defaultTruckNumber?: Maybe<Scalars['Float']>;
  loadingOrder?: Maybe<Scalars['Float']>;
};


/** columns and relationships of "Session" */
export type Session = {
  __typename?: 'Session';
  /** An object relationship */
  User: User;
  createdAt: Scalars['timestamptz'];
  expiresAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  lastSeenAt: Scalars['timestamptz'];
  refreshToken: Scalars['uuid'];
  revokedAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  strategy: Scalars['String'];
  userId: Scalars['uuid'];
};

export type SessionTokens = {
  __typename?: 'SessionTokens';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

/** aggregated selection of "Session" */
export type Session_aggregate = {
  __typename?: 'Session_aggregate';
  aggregate?: Maybe<Session_aggregate_fields>;
  nodes: Array<Session>;
};

/** aggregate fields of "Session" */
export type Session_aggregate_fields = {
  __typename?: 'Session_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Session_max_fields>;
  min?: Maybe<Session_min_fields>;
};


/** aggregate fields of "Session" */
export type Session_aggregate_fieldscountArgs = {
  columns?: Maybe<Array<Session_select_column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Session" */
export type Session_aggregate_order_by = {
  count?: Maybe<order_by>;
  max?: Maybe<Session_max_order_by>;
  min?: Maybe<Session_min_order_by>;
};

/** input type for inserting array relation for remote table "Session" */
export type Session_arr_rel_insert_input = {
  data: Array<Session_insert_input>;
  /** on conflict condition */
  on_conflict?: Maybe<Session_on_conflict>;
};

/** Boolean expression to filter rows from the table "Session". All fields are combined with a logical 'AND'. */
export type Session_bool_exp = {
  User?: Maybe<User_bool_exp>;
  _and?: Maybe<Array<Session_bool_exp>>;
  _not?: Maybe<Session_bool_exp>;
  _or?: Maybe<Array<Session_bool_exp>>;
  createdAt?: Maybe<timestamptz_comparison_exp>;
  expiresAt?: Maybe<timestamptz_comparison_exp>;
  id?: Maybe<uuid_comparison_exp>;
  lastSeenAt?: Maybe<timestamptz_comparison_exp>;
  refreshToken?: Maybe<uuid_comparison_exp>;
  revokedAt?: Maybe<timestamptz_comparison_exp>;
  role?: Maybe<String_comparison_exp>;
  strategy?: Maybe<String_comparison_exp>;
  userId?: Maybe<uuid_comparison_exp>;
};

/** unique or primary key constraints on table "Session" */
export enum Session_constraint {
  /** unique or primary key constraint */
  Session_pkey = 'Session_pkey',
  /** unique or primary key constraint */
  Session_refresh_token_key = 'Session_refresh_token_key'
}

/** input type for inserting data into table "Session" */
export type Session_insert_input = {
  User?: Maybe<User_obj_rel_insert_input>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  expiresAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lastSeenAt?: Maybe<Scalars['timestamptz']>;
  refreshToken?: Maybe<Scalars['uuid']>;
  revokedAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  strategy?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Session_max_fields = {
  __typename?: 'Session_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  expiresAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lastSeenAt?: Maybe<Scalars['timestamptz']>;
  refreshToken?: Maybe<Scalars['uuid']>;
  revokedAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  strategy?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "Session" */
export type Session_max_order_by = {
  createdAt?: Maybe<order_by>;
  expiresAt?: Maybe<order_by>;
  id?: Maybe<order_by>;
  lastSeenAt?: Maybe<order_by>;
  refreshToken?: Maybe<order_by>;
  revokedAt?: Maybe<order_by>;
  role?: Maybe<order_by>;
  strategy?: Maybe<order_by>;
  userId?: Maybe<order_by>;
};

/** aggregate min on columns */
export type Session_min_fields = {
  __typename?: 'Session_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  expiresAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lastSeenAt?: Maybe<Scalars['timestamptz']>;
  refreshToken?: Maybe<Scalars['uuid']>;
  revokedAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  strategy?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "Session" */
export type Session_min_order_by = {
  createdAt?: Maybe<order_by>;
  expiresAt?: Maybe<order_by>;
  id?: Maybe<order_by>;
  lastSeenAt?: Maybe<order_by>;
  refreshToken?: Maybe<order_by>;
  revokedAt?: Maybe<order_by>;
  role?: Maybe<order_by>;
  strategy?: Maybe<order_by>;
  userId?: Maybe<order_by>;
};

/** response of any mutation on the table "Session" */
export type Session_mutation_response = {
  __typename?: 'Session_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Session>;
};

/** on conflict condition type for table "Session" */
export type Session_on_conflict = {
  constraint: Session_constraint;
  update_columns?: Array<Session_update_column>;
  where?: Maybe<Session_bool_exp>;
};

/** Ordering options when selecting data from "Session". */
export type Session_order_by = {
  User?: Maybe<User_order_by>;
  createdAt?: Maybe<order_by>;
  expiresAt?: Maybe<order_by>;
  id?: Maybe<order_by>;
  lastSeenAt?: Maybe<order_by>;
  refreshToken?: Maybe<order_by>;
  revokedAt?: Maybe<order_by>;
  role?: Maybe<order_by>;
  strategy?: Maybe<order_by>;
  userId?: Maybe<order_by>;
};

/** primary key columns input for table: Session */
export type Session_pk_columns_input = {
  id: Scalars['uuid'];
};

/** select columns of table "Session" */
export enum Session_select_column {
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  expiresAt = 'expiresAt',
  /** column name */
  id = 'id',
  /** column name */
  lastSeenAt = 'lastSeenAt',
  /** column name */
  refreshToken = 'refreshToken',
  /** column name */
  revokedAt = 'revokedAt',
  /** column name */
  role = 'role',
  /** column name */
  strategy = 'strategy',
  /** column name */
  userId = 'userId'
}

/** input type for updating data in table "Session" */
export type Session_set_input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  expiresAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lastSeenAt?: Maybe<Scalars['timestamptz']>;
  refreshToken?: Maybe<Scalars['uuid']>;
  revokedAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  strategy?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "Session" */
export enum Session_update_column {
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  expiresAt = 'expiresAt',
  /** column name */
  id = 'id',
  /** column name */
  lastSeenAt = 'lastSeenAt',
  /** column name */
  refreshToken = 'refreshToken',
  /** column name */
  revokedAt = 'revokedAt',
  /** column name */
  role = 'role',
  /** column name */
  strategy = 'strategy',
  /** column name */
  userId = 'userId'
}

export type Site = {
  __typename?: 'Site';
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  closestSchool?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  crossStreet?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Date']>;
  name: Scalars['String'];
  siteId: Scalars['String'];
  state: Scalars['String'];
  statusKidsCafe: SitestatusKidsCafeEnumType;
  statusKidsCafeUpdatedAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  zip: Scalars['String'];
};

export enum SitestatusKidsCafeEnumType {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_comparison_exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
};

/** columns and relationships of "User" */
export type User = {
  __typename?: 'User';
  /** An array relationship */
  Orders: Array<Order>;
  /** An aggregate relationship */
  Orders_aggregate: Order_aggregate;
  /** An array relationship */
  Sessions: Array<Session>;
  /** An aggregate relationship */
  Sessions_aggregate: Session_aggregate;
  createdAt: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['uuid'];
  lastName: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "User" */
export type UserOrdersArgs = {
  distinct_on?: Maybe<Array<Order_select_column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_order_by>>;
  where?: Maybe<Order_bool_exp>;
};


/** columns and relationships of "User" */
export type UserOrders_aggregateArgs = {
  distinct_on?: Maybe<Array<Order_select_column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_order_by>>;
  where?: Maybe<Order_bool_exp>;
};


/** columns and relationships of "User" */
export type UserSessionsArgs = {
  distinct_on?: Maybe<Array<Session_select_column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Session_order_by>>;
  where?: Maybe<Session_bool_exp>;
};


/** columns and relationships of "User" */
export type UserSessions_aggregateArgs = {
  distinct_on?: Maybe<Array<Session_select_column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Session_order_by>>;
  where?: Maybe<Session_bool_exp>;
};

/** aggregated selection of "User" */
export type User_aggregate = {
  __typename?: 'User_aggregate';
  aggregate?: Maybe<User_aggregate_fields>;
  nodes: Array<User>;
};

/** aggregate fields of "User" */
export type User_aggregate_fields = {
  __typename?: 'User_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_max_fields>;
  min?: Maybe<User_min_fields>;
};


/** aggregate fields of "User" */
export type User_aggregate_fieldscountArgs = {
  columns?: Maybe<Array<User_select_column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "User". All fields are combined with a logical 'AND'. */
export type User_bool_exp = {
  Orders?: Maybe<Order_bool_exp>;
  Sessions?: Maybe<Session_bool_exp>;
  _and?: Maybe<Array<User_bool_exp>>;
  _not?: Maybe<User_bool_exp>;
  _or?: Maybe<Array<User_bool_exp>>;
  createdAt?: Maybe<timestamptz_comparison_exp>;
  description?: Maybe<String_comparison_exp>;
  email?: Maybe<String_comparison_exp>;
  firstName?: Maybe<String_comparison_exp>;
  id?: Maybe<uuid_comparison_exp>;
  lastName?: Maybe<String_comparison_exp>;
  updatedAt?: Maybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "User" */
export enum User_constraint {
  /** unique or primary key constraint */
  User_pkey = 'User_pkey'
}

/** input type for inserting data into table "User" */
export type User_insert_input = {
  Orders?: Maybe<Order_arr_rel_insert_input>;
  Sessions?: Maybe<Session_arr_rel_insert_input>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type User_max_fields = {
  __typename?: 'User_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type User_min_fields = {
  __typename?: 'User_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "User" */
export type User_mutation_response = {
  __typename?: 'User_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User>;
};

/** input type for inserting object relation for remote table "User" */
export type User_obj_rel_insert_input = {
  data: User_insert_input;
  /** on conflict condition */
  on_conflict?: Maybe<User_on_conflict>;
};

/** on conflict condition type for table "User" */
export type User_on_conflict = {
  constraint: User_constraint;
  update_columns?: Array<User_update_column>;
  where?: Maybe<User_bool_exp>;
};

/** Ordering options when selecting data from "User". */
export type User_order_by = {
  Orders_aggregate?: Maybe<Order_aggregate_order_by>;
  Sessions_aggregate?: Maybe<Session_aggregate_order_by>;
  createdAt?: Maybe<order_by>;
  description?: Maybe<order_by>;
  email?: Maybe<order_by>;
  firstName?: Maybe<order_by>;
  id?: Maybe<order_by>;
  lastName?: Maybe<order_by>;
  updatedAt?: Maybe<order_by>;
};

/** primary key columns input for table: User */
export type User_pk_columns_input = {
  id: Scalars['uuid'];
};

/** select columns of table "User" */
export enum User_select_column {
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  description = 'description',
  /** column name */
  email = 'email',
  /** column name */
  firstName = 'firstName',
  /** column name */
  id = 'id',
  /** column name */
  lastName = 'lastName',
  /** column name */
  updatedAt = 'updatedAt'
}

/** input type for updating data in table "User" */
export type User_set_input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "User" */
export enum User_update_column {
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  description = 'description',
  /** column name */
  email = 'email',
  /** column name */
  firstName = 'firstName',
  /** column name */
  id = 'id',
  /** column name */
  lastName = 'lastName',
  /** column name */
  updatedAt = 'updatedAt'
}


/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type date_comparison_exp = {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
};

/** mutation root */
export type mutation_root = {
  __typename?: 'mutation_root';
  authAzureAdSignIn?: Maybe<SessionTokens>;
  authSessionRenew?: Maybe<SessionTokens>;
  authSessionRevoke?: Maybe<Scalars['Boolean']>;
  /** delete data from the table: "MealType" */
  delete_MealType?: Maybe<MealType_mutation_response>;
  /** delete single row from the table: "MealType" */
  delete_MealType_by_pk?: Maybe<MealType>;
  /** delete data from the table: "Order" */
  delete_Order?: Maybe<Order_mutation_response>;
  /** delete data from the table: "OrderStatusEnum" */
  delete_OrderStatusEnum?: Maybe<OrderStatusEnum_mutation_response>;
  /** delete single row from the table: "OrderStatusEnum" */
  delete_OrderStatusEnum_by_pk?: Maybe<OrderStatusEnum>;
  /** delete single row from the table: "Order" */
  delete_Order_by_pk?: Maybe<Order>;
  /** delete data from the table: "Route" */
  delete_Route?: Maybe<Route_mutation_response>;
  /** delete data from the table: "RouteDeliverySite" */
  delete_RouteDeliverySite?: Maybe<RouteDeliverySite_mutation_response>;
  /** delete single row from the table: "RouteDeliverySite" */
  delete_RouteDeliverySite_by_pk?: Maybe<RouteDeliverySite>;
  /** delete single row from the table: "Route" */
  delete_Route_by_pk?: Maybe<Route>;
  /** delete data from the table: "Session" */
  delete_Session?: Maybe<Session_mutation_response>;
  /** delete single row from the table: "Session" */
  delete_Session_by_pk?: Maybe<Session>;
  /** delete data from the table: "User" */
  delete_User?: Maybe<User_mutation_response>;
  /** delete single row from the table: "User" */
  delete_User_by_pk?: Maybe<User>;
  /** insert data into the table: "MealType" */
  insert_MealType?: Maybe<MealType_mutation_response>;
  /** insert a single row into the table: "MealType" */
  insert_MealType_one?: Maybe<MealType>;
  /** insert data into the table: "Order" */
  insert_Order?: Maybe<Order_mutation_response>;
  /** insert data into the table: "OrderStatusEnum" */
  insert_OrderStatusEnum?: Maybe<OrderStatusEnum_mutation_response>;
  /** insert a single row into the table: "OrderStatusEnum" */
  insert_OrderStatusEnum_one?: Maybe<OrderStatusEnum>;
  /** insert a single row into the table: "Order" */
  insert_Order_one?: Maybe<Order>;
  /** insert data into the table: "Route" */
  insert_Route?: Maybe<Route_mutation_response>;
  /** insert data into the table: "RouteDeliverySite" */
  insert_RouteDeliverySite?: Maybe<RouteDeliverySite_mutation_response>;
  /** insert a single row into the table: "RouteDeliverySite" */
  insert_RouteDeliverySite_one?: Maybe<RouteDeliverySite>;
  /** insert a single row into the table: "Route" */
  insert_Route_one?: Maybe<Route>;
  /** insert data into the table: "Session" */
  insert_Session?: Maybe<Session_mutation_response>;
  /** insert a single row into the table: "Session" */
  insert_Session_one?: Maybe<Session>;
  /** insert data into the table: "User" */
  insert_User?: Maybe<User_mutation_response>;
  /** insert a single row into the table: "User" */
  insert_User_one?: Maybe<User>;
  /** update data of the table: "MealType" */
  update_MealType?: Maybe<MealType_mutation_response>;
  /** update single row of the table: "MealType" */
  update_MealType_by_pk?: Maybe<MealType>;
  /** update data of the table: "Order" */
  update_Order?: Maybe<Order_mutation_response>;
  /** update data of the table: "OrderStatusEnum" */
  update_OrderStatusEnum?: Maybe<OrderStatusEnum_mutation_response>;
  /** update single row of the table: "OrderStatusEnum" */
  update_OrderStatusEnum_by_pk?: Maybe<OrderStatusEnum>;
  /** update single row of the table: "Order" */
  update_Order_by_pk?: Maybe<Order>;
  /** update data of the table: "Route" */
  update_Route?: Maybe<Route_mutation_response>;
  /** update data of the table: "RouteDeliverySite" */
  update_RouteDeliverySite?: Maybe<RouteDeliverySite_mutation_response>;
  /** update single row of the table: "RouteDeliverySite" */
  update_RouteDeliverySite_by_pk?: Maybe<RouteDeliverySite>;
  /** update single row of the table: "Route" */
  update_Route_by_pk?: Maybe<Route>;
  /** update data of the table: "Session" */
  update_Session?: Maybe<Session_mutation_response>;
  /** update single row of the table: "Session" */
  update_Session_by_pk?: Maybe<Session>;
  /** update data of the table: "User" */
  update_User?: Maybe<User_mutation_response>;
  /** update single row of the table: "User" */
  update_User_by_pk?: Maybe<User>;
};


/** mutation root */
export type mutation_rootauthAzureAdSignInArgs = {
  azureAdAccessToken: Scalars['String'];
  azureAdIdToken: Scalars['String'];
};


/** mutation root */
export type mutation_rootauthSessionRenewArgs = {
  refreshToken: Scalars['String'];
};


/** mutation root */
export type mutation_rootauthSessionRevokeArgs = {
  refreshToken: Scalars['String'];
};


/** mutation root */
export type mutation_rootdelete_MealTypeArgs = {
  where: MealType_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_MealType_by_pkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type mutation_rootdelete_OrderArgs = {
  where: Order_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_OrderStatusEnumArgs = {
  where: OrderStatusEnum_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_OrderStatusEnum_by_pkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type mutation_rootdelete_Order_by_pkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type mutation_rootdelete_RouteArgs = {
  where: Route_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_RouteDeliverySiteArgs = {
  where: RouteDeliverySite_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_RouteDeliverySite_by_pkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type mutation_rootdelete_Route_by_pkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type mutation_rootdelete_SessionArgs = {
  where: Session_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_Session_by_pkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type mutation_rootdelete_UserArgs = {
  where: User_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_User_by_pkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type mutation_rootinsert_MealTypeArgs = {
  objects: Array<MealType_insert_input>;
  on_conflict?: Maybe<MealType_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_MealType_oneArgs = {
  object: MealType_insert_input;
  on_conflict?: Maybe<MealType_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_OrderArgs = {
  objects: Array<Order_insert_input>;
  on_conflict?: Maybe<Order_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_OrderStatusEnumArgs = {
  objects: Array<OrderStatusEnum_insert_input>;
  on_conflict?: Maybe<OrderStatusEnum_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_OrderStatusEnum_oneArgs = {
  object: OrderStatusEnum_insert_input;
  on_conflict?: Maybe<OrderStatusEnum_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_Order_oneArgs = {
  object: Order_insert_input;
  on_conflict?: Maybe<Order_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_RouteArgs = {
  objects: Array<Route_insert_input>;
  on_conflict?: Maybe<Route_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_RouteDeliverySiteArgs = {
  objects: Array<RouteDeliverySite_insert_input>;
  on_conflict?: Maybe<RouteDeliverySite_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_RouteDeliverySite_oneArgs = {
  object: RouteDeliverySite_insert_input;
  on_conflict?: Maybe<RouteDeliverySite_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_Route_oneArgs = {
  object: Route_insert_input;
  on_conflict?: Maybe<Route_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_SessionArgs = {
  objects: Array<Session_insert_input>;
  on_conflict?: Maybe<Session_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_Session_oneArgs = {
  object: Session_insert_input;
  on_conflict?: Maybe<Session_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_UserArgs = {
  objects: Array<User_insert_input>;
  on_conflict?: Maybe<User_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_User_oneArgs = {
  object: User_insert_input;
  on_conflict?: Maybe<User_on_conflict>;
};


/** mutation root */
export type mutation_rootupdate_MealTypeArgs = {
  _inc?: Maybe<MealType_inc_input>;
  _set?: Maybe<MealType_set_input>;
  where: MealType_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_MealType_by_pkArgs = {
  _inc?: Maybe<MealType_inc_input>;
  _set?: Maybe<MealType_set_input>;
  pk_columns: MealType_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_OrderArgs = {
  _inc?: Maybe<Order_inc_input>;
  _set?: Maybe<Order_set_input>;
  where: Order_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_OrderStatusEnumArgs = {
  _set?: Maybe<OrderStatusEnum_set_input>;
  where: OrderStatusEnum_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_OrderStatusEnum_by_pkArgs = {
  _set?: Maybe<OrderStatusEnum_set_input>;
  pk_columns: OrderStatusEnum_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_Order_by_pkArgs = {
  _inc?: Maybe<Order_inc_input>;
  _set?: Maybe<Order_set_input>;
  pk_columns: Order_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_RouteArgs = {
  _inc?: Maybe<Route_inc_input>;
  _set?: Maybe<Route_set_input>;
  where: Route_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_RouteDeliverySiteArgs = {
  _inc?: Maybe<RouteDeliverySite_inc_input>;
  _set?: Maybe<RouteDeliverySite_set_input>;
  where: RouteDeliverySite_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_RouteDeliverySite_by_pkArgs = {
  _inc?: Maybe<RouteDeliverySite_inc_input>;
  _set?: Maybe<RouteDeliverySite_set_input>;
  pk_columns: RouteDeliverySite_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_Route_by_pkArgs = {
  _inc?: Maybe<Route_inc_input>;
  _set?: Maybe<Route_set_input>;
  pk_columns: Route_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_SessionArgs = {
  _set?: Maybe<Session_set_input>;
  where: Session_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_Session_by_pkArgs = {
  _set?: Maybe<Session_set_input>;
  pk_columns: Session_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_UserArgs = {
  _set?: Maybe<User_set_input>;
  where: User_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_User_by_pkArgs = {
  _set?: Maybe<User_set_input>;
  pk_columns: User_pk_columns_input;
};

/** column ordering options */
export enum order_by {
  /** in ascending order, nulls last */
  asc = 'asc',
  /** in ascending order, nulls first */
  asc_nulls_first = 'asc_nulls_first',
  /** in ascending order, nulls last */
  asc_nulls_last = 'asc_nulls_last',
  /** in descending order, nulls first */
  desc = 'desc',
  /** in descending order, nulls first */
  desc_nulls_first = 'desc_nulls_first',
  /** in descending order, nulls last */
  desc_nulls_last = 'desc_nulls_last'
}

export type query_root = {
  __typename?: 'query_root';
  ApplicationKidsCafe?: Maybe<Array<Maybe<ApplicationKidsCafe>>>;
  /** fetch data from the table: "MealType" */
  MealType: Array<MealType>;
  /** fetch aggregated fields from the table: "MealType" */
  MealType_aggregate: MealType_aggregate;
  /** fetch data from the table: "MealType" using primary key columns */
  MealType_by_pk?: Maybe<MealType>;
  /** fetch data from the table: "Order" */
  Order: Array<Order>;
  /** fetch data from the table: "OrderStatusEnum" */
  OrderStatusEnum: Array<OrderStatusEnum>;
  /** fetch aggregated fields from the table: "OrderStatusEnum" */
  OrderStatusEnum_aggregate: OrderStatusEnum_aggregate;
  /** fetch data from the table: "OrderStatusEnum" using primary key columns */
  OrderStatusEnum_by_pk?: Maybe<OrderStatusEnum>;
  /** fetch aggregated fields from the table: "Order" */
  Order_aggregate: Order_aggregate;
  /** fetch data from the table: "Order" using primary key columns */
  Order_by_pk?: Maybe<Order>;
  /** fetch data from the table: "Route" */
  Route: Array<Route>;
  /** fetch data from the table: "RouteDeliverySite" */
  RouteDeliverySite: Array<RouteDeliverySite>;
  /** fetch aggregated fields from the table: "RouteDeliverySite" */
  RouteDeliverySite_aggregate: RouteDeliverySite_aggregate;
  /** fetch data from the table: "RouteDeliverySite" using primary key columns */
  RouteDeliverySite_by_pk?: Maybe<RouteDeliverySite>;
  /** fetch aggregated fields from the table: "Route" */
  Route_aggregate: Route_aggregate;
  /** fetch data from the table: "Route" using primary key columns */
  Route_by_pk?: Maybe<Route>;
  /** fetch data from the table: "Session" */
  Session: Array<Session>;
  /** fetch aggregated fields from the table: "Session" */
  Session_aggregate: Session_aggregate;
  /** fetch data from the table: "Session" using primary key columns */
  Session_by_pk?: Maybe<Session>;
  Site?: Maybe<Array<Maybe<Site>>>;
  /** fetch data from the table: "User" */
  User: Array<User>;
  /** fetch aggregated fields from the table: "User" */
  User_aggregate: User_aggregate;
  /** fetch data from the table: "User" using primary key columns */
  User_by_pk?: Maybe<User>;
  authStrategiesEnabled: Array<Maybe<AuthStrategy>>;
};


export type query_rootApplicationKidsCafeArgs = {
  applicationKidsCafeId?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  where?: Maybe<Scalars['SequelizeJSON']>;
};


export type query_rootMealTypeArgs = {
  distinct_on?: Maybe<Array<MealType_select_column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MealType_order_by>>;
  where?: Maybe<MealType_bool_exp>;
};


export type query_rootMealType_aggregateArgs = {
  distinct_on?: Maybe<Array<MealType_select_column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MealType_order_by>>;
  where?: Maybe<MealType_bool_exp>;
};


export type query_rootMealType_by_pkArgs = {
  id: Scalars['uuid'];
};


export type query_rootOrderArgs = {
  distinct_on?: Maybe<Array<Order_select_column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_order_by>>;
  where?: Maybe<Order_bool_exp>;
};


export type query_rootOrderStatusEnumArgs = {
  distinct_on?: Maybe<Array<OrderStatusEnum_select_column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrderStatusEnum_order_by>>;
  where?: Maybe<OrderStatusEnum_bool_exp>;
};


export type query_rootOrderStatusEnum_aggregateArgs = {
  distinct_on?: Maybe<Array<OrderStatusEnum_select_column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrderStatusEnum_order_by>>;
  where?: Maybe<OrderStatusEnum_bool_exp>;
};


export type query_rootOrderStatusEnum_by_pkArgs = {
  value: Scalars['String'];
};


export type query_rootOrder_aggregateArgs = {
  distinct_on?: Maybe<Array<Order_select_column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_order_by>>;
  where?: Maybe<Order_bool_exp>;
};


export type query_rootOrder_by_pkArgs = {
  id: Scalars['uuid'];
};


export type query_rootRouteArgs = {
  distinct_on?: Maybe<Array<Route_select_column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Route_order_by>>;
  where?: Maybe<Route_bool_exp>;
};


export type query_rootRouteDeliverySiteArgs = {
  distinct_on?: Maybe<Array<RouteDeliverySite_select_column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RouteDeliverySite_order_by>>;
  where?: Maybe<RouteDeliverySite_bool_exp>;
};


export type query_rootRouteDeliverySite_aggregateArgs = {
  distinct_on?: Maybe<Array<RouteDeliverySite_select_column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RouteDeliverySite_order_by>>;
  where?: Maybe<RouteDeliverySite_bool_exp>;
};


export type query_rootRouteDeliverySite_by_pkArgs = {
  id: Scalars['uuid'];
};


export type query_rootRoute_aggregateArgs = {
  distinct_on?: Maybe<Array<Route_select_column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Route_order_by>>;
  where?: Maybe<Route_bool_exp>;
};


export type query_rootRoute_by_pkArgs = {
  id: Scalars['uuid'];
};


export type query_rootSessionArgs = {
  distinct_on?: Maybe<Array<Session_select_column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Session_order_by>>;
  where?: Maybe<Session_bool_exp>;
};


export type query_rootSession_aggregateArgs = {
  distinct_on?: Maybe<Array<Session_select_column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Session_order_by>>;
  where?: Maybe<Session_bool_exp>;
};


export type query_rootSession_by_pkArgs = {
  id: Scalars['uuid'];
};


export type query_rootSiteArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  where?: Maybe<Scalars['SequelizeJSON']>;
};


export type query_rootUserArgs = {
  distinct_on?: Maybe<Array<User_select_column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_order_by>>;
  where?: Maybe<User_bool_exp>;
};


export type query_rootUser_aggregateArgs = {
  distinct_on?: Maybe<Array<User_select_column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_order_by>>;
  where?: Maybe<User_bool_exp>;
};


export type query_rootUser_by_pkArgs = {
  id: Scalars['uuid'];
};

export type subscription_root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "MealType" */
  MealType: Array<MealType>;
  /** fetch aggregated fields from the table: "MealType" */
  MealType_aggregate: MealType_aggregate;
  /** fetch data from the table: "MealType" using primary key columns */
  MealType_by_pk?: Maybe<MealType>;
  /** fetch data from the table: "Order" */
  Order: Array<Order>;
  /** fetch data from the table: "OrderStatusEnum" */
  OrderStatusEnum: Array<OrderStatusEnum>;
  /** fetch aggregated fields from the table: "OrderStatusEnum" */
  OrderStatusEnum_aggregate: OrderStatusEnum_aggregate;
  /** fetch data from the table: "OrderStatusEnum" using primary key columns */
  OrderStatusEnum_by_pk?: Maybe<OrderStatusEnum>;
  /** fetch aggregated fields from the table: "Order" */
  Order_aggregate: Order_aggregate;
  /** fetch data from the table: "Order" using primary key columns */
  Order_by_pk?: Maybe<Order>;
  /** fetch data from the table: "Route" */
  Route: Array<Route>;
  /** fetch data from the table: "RouteDeliverySite" */
  RouteDeliverySite: Array<RouteDeliverySite>;
  /** fetch aggregated fields from the table: "RouteDeliverySite" */
  RouteDeliverySite_aggregate: RouteDeliverySite_aggregate;
  /** fetch data from the table: "RouteDeliverySite" using primary key columns */
  RouteDeliverySite_by_pk?: Maybe<RouteDeliverySite>;
  /** fetch aggregated fields from the table: "Route" */
  Route_aggregate: Route_aggregate;
  /** fetch data from the table: "Route" using primary key columns */
  Route_by_pk?: Maybe<Route>;
  /** fetch data from the table: "Session" */
  Session: Array<Session>;
  /** fetch aggregated fields from the table: "Session" */
  Session_aggregate: Session_aggregate;
  /** fetch data from the table: "Session" using primary key columns */
  Session_by_pk?: Maybe<Session>;
  /** fetch data from the table: "User" */
  User: Array<User>;
  /** fetch aggregated fields from the table: "User" */
  User_aggregate: User_aggregate;
  /** fetch data from the table: "User" using primary key columns */
  User_by_pk?: Maybe<User>;
};


export type subscription_rootMealTypeArgs = {
  distinct_on?: Maybe<Array<MealType_select_column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MealType_order_by>>;
  where?: Maybe<MealType_bool_exp>;
};


export type subscription_rootMealType_aggregateArgs = {
  distinct_on?: Maybe<Array<MealType_select_column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MealType_order_by>>;
  where?: Maybe<MealType_bool_exp>;
};


export type subscription_rootMealType_by_pkArgs = {
  id: Scalars['uuid'];
};


export type subscription_rootOrderArgs = {
  distinct_on?: Maybe<Array<Order_select_column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_order_by>>;
  where?: Maybe<Order_bool_exp>;
};


export type subscription_rootOrderStatusEnumArgs = {
  distinct_on?: Maybe<Array<OrderStatusEnum_select_column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrderStatusEnum_order_by>>;
  where?: Maybe<OrderStatusEnum_bool_exp>;
};


export type subscription_rootOrderStatusEnum_aggregateArgs = {
  distinct_on?: Maybe<Array<OrderStatusEnum_select_column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrderStatusEnum_order_by>>;
  where?: Maybe<OrderStatusEnum_bool_exp>;
};


export type subscription_rootOrderStatusEnum_by_pkArgs = {
  value: Scalars['String'];
};


export type subscription_rootOrder_aggregateArgs = {
  distinct_on?: Maybe<Array<Order_select_column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_order_by>>;
  where?: Maybe<Order_bool_exp>;
};


export type subscription_rootOrder_by_pkArgs = {
  id: Scalars['uuid'];
};


export type subscription_rootRouteArgs = {
  distinct_on?: Maybe<Array<Route_select_column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Route_order_by>>;
  where?: Maybe<Route_bool_exp>;
};


export type subscription_rootRouteDeliverySiteArgs = {
  distinct_on?: Maybe<Array<RouteDeliverySite_select_column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RouteDeliverySite_order_by>>;
  where?: Maybe<RouteDeliverySite_bool_exp>;
};


export type subscription_rootRouteDeliverySite_aggregateArgs = {
  distinct_on?: Maybe<Array<RouteDeliverySite_select_column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<RouteDeliverySite_order_by>>;
  where?: Maybe<RouteDeliverySite_bool_exp>;
};


export type subscription_rootRouteDeliverySite_by_pkArgs = {
  id: Scalars['uuid'];
};


export type subscription_rootRoute_aggregateArgs = {
  distinct_on?: Maybe<Array<Route_select_column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Route_order_by>>;
  where?: Maybe<Route_bool_exp>;
};


export type subscription_rootRoute_by_pkArgs = {
  id: Scalars['uuid'];
};


export type subscription_rootSessionArgs = {
  distinct_on?: Maybe<Array<Session_select_column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Session_order_by>>;
  where?: Maybe<Session_bool_exp>;
};


export type subscription_rootSession_aggregateArgs = {
  distinct_on?: Maybe<Array<Session_select_column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Session_order_by>>;
  where?: Maybe<Session_bool_exp>;
};


export type subscription_rootSession_by_pkArgs = {
  id: Scalars['uuid'];
};


export type subscription_rootUserArgs = {
  distinct_on?: Maybe<Array<User_select_column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_order_by>>;
  where?: Maybe<User_bool_exp>;
};


export type subscription_rootUser_aggregateArgs = {
  distinct_on?: Maybe<Array<User_select_column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_order_by>>;
  where?: Maybe<User_bool_exp>;
};


export type subscription_rootUser_by_pkArgs = {
  id: Scalars['uuid'];
};


/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type timestamptz_comparison_exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};


/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type uuid_comparison_exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

export type MealTypeFullFragment = (
  { __typename?: 'MealType' }
  & Pick<MealType, 'id' | 'name' | 'itemsPerPackage' | 'archivable' | 'archived' | 'createdAt'>
  & { orderCount: (
    { __typename?: 'Order_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'Order_aggregate_fields' }
      & Pick<Order_aggregate_fields, 'count'>
    )> }
  ) }
);

export type RouteFullFragment = (
  { __typename?: 'Route' }
  & Pick<Route, 'id' | 'name' | 'loadingOrder' | 'defaultTruckNumber' | 'archived' | 'createdAt'>
);

export type OrderFullFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'applicationId' | 'archived' | 'createdAt' | 'deliveryDay' | 'lastEditedByUserId' | 'mealTypeId' | 'quantityLoaded' | 'quantityOrdered' | 'truckNumber' | 'siteId' | 'status' | 'routeId' | 'updatedAt'>
);

export type UserFullFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'firstName' | 'lastName' | 'createdAt' | 'description' | 'email' | 'updatedAt'>
  & { Sessions: Array<(
    { __typename?: 'Session' }
    & Pick<Session, 'lastSeenAt' | 'role'>
  )> }
);

export type AddMealTypeMutationVariables = Exact<{
  data: Array<MealType_insert_input> | MealType_insert_input;
}>;


export type AddMealTypeMutation = (
  { __typename?: 'mutation_root' }
  & { insert_MealType?: Maybe<(
    { __typename?: 'MealType_mutation_response' }
    & { returning: Array<(
      { __typename?: 'MealType' }
      & MealTypeFullFragment
    )> }
  )> }
);

export type EditMealTypeMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  data?: Maybe<MealType_set_input>;
}>;


export type EditMealTypeMutation = (
  { __typename?: 'mutation_root' }
  & { update_MealType?: Maybe<(
    { __typename?: 'MealType_mutation_response' }
    & { returning: Array<(
      { __typename?: 'MealType' }
      & MealTypeFullFragment
    )> }
  )> }
);

export type ArchiveMealTypeMutationVariables = Exact<{
  archived: Scalars['timestamptz'];
  id: Scalars['uuid'];
}>;


export type ArchiveMealTypeMutation = (
  { __typename?: 'mutation_root' }
  & { update_MealType?: Maybe<(
    { __typename?: 'MealType_mutation_response' }
    & { returning: Array<(
      { __typename?: 'MealType' }
      & Pick<MealType, 'name' | 'id' | 'createdAt' | 'archived'>
    )> }
  )> }
);

export type CreateOrderMutationVariables = Exact<{
  data: Array<Order_insert_input> | Order_insert_input;
}>;


export type CreateOrderMutation = (
  { __typename?: 'mutation_root' }
  & { insert_Order?: Maybe<(
    { __typename?: 'Order_mutation_response' }
    & { returning: Array<(
      { __typename?: 'Order' }
      & Pick<Order, 'id'>
    )> }
  )> }
);

export type EditOrderMutationVariables = Exact<{
  id: Scalars['uuid'];
  data: Order_set_input;
}>;


export type EditOrderMutation = (
  { __typename?: 'mutation_root' }
  & { update_Order?: Maybe<(
    { __typename?: 'Order_mutation_response' }
    & { returning: Array<(
      { __typename?: 'Order' }
      & Pick<Order, 'id'>
    )> }
  )> }
);

export type ArchiveOrderMutationVariables = Exact<{
  archived: Scalars['timestamptz'];
  id: Scalars['uuid'];
}>;


export type ArchiveOrderMutation = (
  { __typename?: 'mutation_root' }
  & { update_Order?: Maybe<(
    { __typename?: 'Order_mutation_response' }
    & { returning: Array<(
      { __typename?: 'Order' }
      & Pick<Order, 'id' | 'archived'>
    )> }
  )> }
);

export type AddRouteMutationVariables = Exact<{
  name: Scalars['String'];
  loadingOrder: Scalars['Int'];
  truckNumber: Scalars['Int'];
}>;


export type AddRouteMutation = (
  { __typename?: 'mutation_root' }
  & { insert_Route?: Maybe<(
    { __typename?: 'Route_mutation_response' }
    & { returning: Array<(
      { __typename?: 'Route' }
      & Pick<Route, 'archived' | 'createdAt' | 'id' | 'name'>
    )> }
  )> }
);

export type ArchiveRouteMutationVariables = Exact<{
  id: Scalars['uuid'];
  loadingOrder: Scalars['Int'];
  archived: Scalars['timestamptz'];
}>;


export type ArchiveRouteMutation = (
  { __typename?: 'mutation_root' }
  & { archiveRoute?: Maybe<(
    { __typename?: 'Route_mutation_response' }
    & Pick<Route_mutation_response, 'affected_rows'>
  )>, updateLoadingOrder?: Maybe<(
    { __typename?: 'Route_mutation_response' }
    & Pick<Route_mutation_response, 'affected_rows'>
  )> }
);

export type EditRouteTruckNumberMutationVariables = Exact<{
  id: Scalars['uuid'];
  truckNumber: Scalars['Int'];
}>;


export type EditRouteTruckNumberMutation = (
  { __typename?: 'mutation_root' }
  & { update_Route?: Maybe<(
    { __typename?: 'Route_mutation_response' }
    & Pick<Route_mutation_response, 'affected_rows'>
  )> }
);

export type IncrementRouteLoadingOrderMutationVariables = Exact<{
  curr?: Maybe<Scalars['Int']>;
  prev?: Maybe<Scalars['Int']>;
}>;


export type IncrementRouteLoadingOrderMutation = (
  { __typename?: 'mutation_root' }
  & { setPrev?: Maybe<(
    { __typename?: 'Route_mutation_response' }
    & Pick<Route_mutation_response, 'affected_rows'>
  )>, updateCurr?: Maybe<(
    { __typename?: 'Route_mutation_response' }
    & Pick<Route_mutation_response, 'affected_rows'>
  )>, updatePrev?: Maybe<(
    { __typename?: 'Route_mutation_response' }
    & Pick<Route_mutation_response, 'affected_rows'>
  )> }
);

export type DecrementRouteLoadingOrderMutationVariables = Exact<{
  curr?: Maybe<Scalars['Int']>;
  next?: Maybe<Scalars['Int']>;
}>;


export type DecrementRouteLoadingOrderMutation = (
  { __typename?: 'mutation_root' }
  & { setNext?: Maybe<(
    { __typename?: 'Route_mutation_response' }
    & Pick<Route_mutation_response, 'affected_rows'>
  )>, updateCurr?: Maybe<(
    { __typename?: 'Route_mutation_response' }
    & Pick<Route_mutation_response, 'affected_rows'>
  )>, updateNext?: Maybe<(
    { __typename?: 'Route_mutation_response' }
    & Pick<Route_mutation_response, 'affected_rows'>
  )> }
);

export type IncrementSiteDeliveryOrderMutationVariables = Exact<{
  curr?: Maybe<Scalars['Int']>;
  prev?: Maybe<Scalars['Int']>;
  routeId: Scalars['uuid'];
}>;


export type IncrementSiteDeliveryOrderMutation = (
  { __typename?: 'mutation_root' }
  & { setPrev?: Maybe<(
    { __typename?: 'RouteDeliverySite_mutation_response' }
    & Pick<RouteDeliverySite_mutation_response, 'affected_rows'>
  )>, updateCurr?: Maybe<(
    { __typename?: 'RouteDeliverySite_mutation_response' }
    & Pick<RouteDeliverySite_mutation_response, 'affected_rows'>
  )>, updatePrev?: Maybe<(
    { __typename?: 'RouteDeliverySite_mutation_response' }
    & Pick<RouteDeliverySite_mutation_response, 'affected_rows'>
  )> }
);

export type DecrementSiteDeliveryOrderMutationVariables = Exact<{
  curr?: Maybe<Scalars['Int']>;
  next?: Maybe<Scalars['Int']>;
  routeId: Scalars['uuid'];
}>;


export type DecrementSiteDeliveryOrderMutation = (
  { __typename?: 'mutation_root' }
  & { setNext?: Maybe<(
    { __typename?: 'RouteDeliverySite_mutation_response' }
    & Pick<RouteDeliverySite_mutation_response, 'affected_rows'>
  )>, updateCurr?: Maybe<(
    { __typename?: 'RouteDeliverySite_mutation_response' }
    & Pick<RouteDeliverySite_mutation_response, 'affected_rows'>
  )>, updateNext?: Maybe<(
    { __typename?: 'RouteDeliverySite_mutation_response' }
    & Pick<RouteDeliverySite_mutation_response, 'affected_rows'>
  )> }
);

export type AssignSiteToRouteMutationVariables = Exact<{
  siteDeliveryOrder: Scalars['Int'];
  siteId: Scalars['String'];
  routeId: Scalars['uuid'];
}>;


export type AssignSiteToRouteMutation = (
  { __typename?: 'mutation_root' }
  & { insert_RouteDeliverySite?: Maybe<(
    { __typename?: 'RouteDeliverySite_mutation_response' }
    & Pick<RouteDeliverySite_mutation_response, 'affected_rows'>
  )> }
);

export type UnassignSiteToRouteMutationVariables = Exact<{
  id: Scalars['uuid'];
  siteDeliveryOrder: Scalars['Int'];
}>;


export type UnassignSiteToRouteMutation = (
  { __typename?: 'mutation_root' }
  & { delete_RouteDeliverySite_by_pk?: Maybe<(
    { __typename?: 'RouteDeliverySite' }
    & Pick<RouteDeliverySite, 'id'>
  )>, update_RouteDeliverySite?: Maybe<(
    { __typename?: 'RouteDeliverySite_mutation_response' }
    & Pick<RouteDeliverySite_mutation_response, 'affected_rows'>
  )> }
);

export type AuthAzureAdSignInMutationVariables = Exact<{
  azureAdAccessToken: Scalars['String'];
  azureAdIdToken: Scalars['String'];
}>;


export type AuthAzureAdSignInMutation = (
  { __typename?: 'mutation_root' }
  & { tokens?: Maybe<(
    { __typename?: 'SessionTokens' }
    & Pick<SessionTokens, 'accessToken' | 'refreshToken'>
  )> }
);

export type AuthSessionRenewMutationVariables = Exact<{
  refreshToken: Scalars['String'];
}>;


export type AuthSessionRenewMutation = (
  { __typename?: 'mutation_root' }
  & { tokens?: Maybe<(
    { __typename?: 'SessionTokens' }
    & Pick<SessionTokens, 'accessToken' | 'refreshToken'>
  )> }
);

export type AuthSessionRevokeMutationVariables = Exact<{
  refreshToken: Scalars['String'];
}>;


export type AuthSessionRevokeMutation = (
  { __typename?: 'mutation_root' }
  & Pick<mutation_root, 'authSessionRevoke'>
);

export type ListOrderStatusEnumQueryVariables = Exact<{ [key: string]: never; }>;


export type ListOrderStatusEnumQuery = (
  { __typename?: 'query_root' }
  & { orderStatuses: Array<(
    { __typename?: 'OrderStatusEnum' }
    & Pick<OrderStatusEnum, 'value' | 'label'>
  )> }
);

export type ListRoutesQueryQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Route_order_by> | Route_order_by>;
}>;


export type ListRoutesQueryQuery = (
  { __typename?: 'query_root' }
  & { routes: Array<(
    { __typename?: 'Route' }
    & RouteFullFragment
  )> }
);

export type CountRoutesQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type CountRoutesQueryQuery = (
  { __typename?: 'query_root' }
  & { count: (
    { __typename?: 'Route_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'Route_aggregate_fields' }
      & Pick<Route_aggregate_fields, 'count'>
    )> }
  ) }
);

export type ListSitesQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
}>;


export type ListSitesQuery = (
  { __typename?: 'query_root' }
  & { sites?: Maybe<Array<Maybe<(
    { __typename?: 'Site' }
    & Pick<Site, 'siteId' | 'name' | 'address1' | 'address2' | 'city' | 'state' | 'county' | 'zip'>
  )>>> }
);

export type OrdersFilterOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type OrdersFilterOptionsQuery = (
  { __typename?: 'query_root' }
  & { statuses: (
    { __typename?: 'Order_aggregate' }
    & { nodes: Array<(
      { __typename?: 'Order' }
      & Pick<Order, 'status'>
      & { statusEnum: (
        { __typename?: 'OrderStatusEnum' }
        & Pick<OrderStatusEnum, 'label'>
      ) }
    )> }
  ), siteIds: (
    { __typename?: 'Order_aggregate' }
    & { nodes: Array<(
      { __typename?: 'Order' }
      & Pick<Order, 'siteId'>
      & { site?: Maybe<Array<Maybe<(
        { __typename?: 'Site' }
        & Pick<Site, 'name'>
      )>>> }
    )> }
  ), routeIds: (
    { __typename?: 'Order_aggregate' }
    & { nodes: Array<(
      { __typename?: 'Order' }
      & Pick<Order, 'routeId'>
      & { route?: Maybe<(
        { __typename?: 'Route' }
        & Pick<Route, 'name'>
      )> }
    )> }
  ), truckNumbers: (
    { __typename?: 'Order_aggregate' }
    & { nodes: Array<(
      { __typename?: 'Order' }
      & Pick<Order, 'truckNumber'>
    )> }
  ), mealTypeIds: (
    { __typename?: 'Order_aggregate' }
    & { nodes: Array<(
      { __typename?: 'Order' }
      & Pick<Order, 'mealTypeId'>
      & { mealType: (
        { __typename?: 'MealType' }
        & Pick<MealType, 'name'>
      ) }
    )> }
  ), deliveryDays: (
    { __typename?: 'Order_aggregate' }
    & { nodes: Array<(
      { __typename?: 'Order' }
      & Pick<Order, 'deliveryDay'>
    )> }
  ) }
);

export type GetUserQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;


export type GetUserQuery = (
  { __typename?: 'query_root' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'createdAt'>
  )> }
);

export type GetSitesOptionsQueryVariables = Exact<{
  whereFilters?: Maybe<Scalars['SequelizeJSON']>;
}>;


export type GetSitesOptionsQuery = (
  { __typename?: 'query_root' }
  & { sites?: Maybe<Array<Maybe<(
    { __typename?: 'Site' }
    & { value: Site['siteId'], label: Site['name'] }
  )>>> }
);

export type GetRoutesOptionsQueryVariables = Exact<{
  whereFilters: Route_bool_exp;
}>;


export type GetRoutesOptionsQuery = (
  { __typename?: 'query_root' }
  & { routes: Array<(
    { __typename?: 'Route' }
    & { value: Route['id'], label: Route['name'] }
  )> }
);

export type GetMealTypesOptionsQueryVariables = Exact<{
  whereFilters: MealType_bool_exp;
}>;


export type GetMealTypesOptionsQuery = (
  { __typename?: 'query_root' }
  & { mealTypes: Array<(
    { __typename?: 'MealType' }
    & { value: MealType['id'], label: MealType['name'] }
  )> }
);

export type GetOrderStatusEnumOptionsQueryVariables = Exact<{
  whereFilters?: Maybe<OrderStatusEnum_bool_exp>;
}>;


export type GetOrderStatusEnumOptionsQuery = (
  { __typename?: 'query_root' }
  & { orderStatuses: Array<(
    { __typename?: 'OrderStatusEnum' }
    & Pick<OrderStatusEnum, 'value' | 'label'>
  )> }
);

export type GetOrderByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetOrderByIdQuery = (
  { __typename?: 'query_root' }
  & { order?: Maybe<(
    { __typename?: 'Order' }
    & OrderFullFragment
  )> }
);

export type CountOrdersAndMealsSubscriptionVariables = Exact<{
  where?: Maybe<Order_bool_exp>;
}>;


export type CountOrdersAndMealsSubscription = (
  { __typename?: 'subscription_root' }
  & { orders: (
    { __typename?: 'Order_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'Order_aggregate_fields' }
      & Pick<Order_aggregate_fields, 'count'>
      & { sum?: Maybe<(
        { __typename?: 'Order_sum_fields' }
        & Pick<Order_sum_fields, 'quantityOrdered' | 'quantityLoaded'>
      )> }
    )> }
  ) }
);

export type ListMealTypesSubscriptionVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MealType_order_by> | MealType_order_by>;
}>;


export type ListMealTypesSubscription = (
  { __typename?: 'subscription_root' }
  & { mealTypes: Array<(
    { __typename?: 'MealType' }
    & MealTypeFullFragment
  )> }
);

export type CountMealTypesSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type CountMealTypesSubscription = (
  { __typename?: 'subscription_root' }
  & { count: (
    { __typename?: 'MealType_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'MealType_aggregate_fields' }
      & Pick<MealType_aggregate_fields, 'count'>
    )> }
  ) }
);

export type ListRoutesSubscriptionVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Route_order_by> | Route_order_by>;
}>;


export type ListRoutesSubscription = (
  { __typename?: 'subscription_root' }
  & { routes: Array<(
    { __typename?: 'Route' }
    & { sites: (
      { __typename?: 'RouteDeliverySite_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'RouteDeliverySite_aggregate_fields' }
        & Pick<RouteDeliverySite_aggregate_fields, 'count'>
      )> }
    ) }
    & RouteFullFragment
  )> }
);

export type CountRoutesSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type CountRoutesSubscription = (
  { __typename?: 'subscription_root' }
  & { count: (
    { __typename?: 'Route_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'Route_aggregate_fields' }
      & Pick<Route_aggregate_fields, 'count'>
    )> }
  ) }
);

export type ListRouteSitesSubscriptionVariables = Exact<{
  routeId?: Maybe<Scalars['uuid']>;
  order_by?: Maybe<Array<RouteDeliverySite_order_by> | RouteDeliverySite_order_by>;
}>;


export type ListRouteSitesSubscription = (
  { __typename?: 'subscription_root' }
  & { sites: Array<(
    { __typename?: 'RouteDeliverySite' }
    & Pick<RouteDeliverySite, 'id' | 'siteId' | 'siteDeliveryOrder' | 'routeId'>
  )> }
);

export type ListOrdersSubscriptionVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_order_by> | Order_order_by>;
  where?: Maybe<Order_bool_exp>;
}>;


export type ListOrdersSubscription = (
  { __typename?: 'subscription_root' }
  & { orders: Array<(
    { __typename?: 'Order' }
    & Pick<Order, 'id' | 'applicationId' | 'siteId' | 'deliveryDay' | 'truckNumber' | 'updatedAt'>
    & { status: (
      { __typename?: 'OrderStatusEnum' }
      & Pick<OrderStatusEnum, 'label' | 'value'>
    ), route?: Maybe<(
      { __typename?: 'Route' }
      & Pick<Route, 'name'>
    )>, mealType: (
      { __typename?: 'MealType' }
      & Pick<MealType, 'name'>
    ), user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName'>
    )> }
  )> }
);

export type CountOrdersSubscriptionVariables = Exact<{
  where?: Maybe<Order_bool_exp>;
}>;


export type CountOrdersSubscription = (
  { __typename?: 'subscription_root' }
  & { count: (
    { __typename?: 'Order_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'Order_aggregate_fields' }
      & Pick<Order_aggregate_fields, 'count'>
    )> }
  ) }
);

export type ListUsersSubscriptionVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_order_by> | User_order_by>;
}>;


export type ListUsersSubscription = (
  { __typename?: 'subscription_root' }
  & { users: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
    & { sessionsAggregate: (
      { __typename?: 'Session_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'Session_aggregate_fields' }
        & { max?: Maybe<(
          { __typename?: 'Session_max_fields' }
          & Pick<Session_max_fields, 'lastSeenAt' | 'role'>
        )> }
      )> }
    ) }
  )> }
);

export type CountUsersSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type CountUsersSubscription = (
  { __typename?: 'subscription_root' }
  & { count: (
    { __typename?: 'User_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'User_aggregate_fields' }
      & Pick<User_aggregate_fields, 'count'>
    )> }
  ) }
);

export const MealTypeFullFragmentDoc = gql`
    fragment MealTypeFull on MealType {
  id
  name
  itemsPerPackage
  archivable
  archived
  createdAt
  orderCount: Orders_aggregate {
    aggregate {
      count
    }
  }
}
    `;
export const RouteFullFragmentDoc = gql`
    fragment RouteFull on Route {
  id
  name
  loadingOrder
  defaultTruckNumber
  archived
  createdAt
}
    `;
export const OrderFullFragmentDoc = gql`
    fragment OrderFull on Order {
  id
  applicationId
  archived
  createdAt
  deliveryDay
  lastEditedByUserId
  mealTypeId
  quantityLoaded
  quantityOrdered
  truckNumber
  siteId
  status
  routeId
  updatedAt
}
    `;
export const UserFullFragmentDoc = gql`
    fragment UserFull on User {
  id
  firstName
  lastName
  createdAt
  description
  email
  updatedAt
  Sessions {
    lastSeenAt
    role
  }
}
    `;
export const AddMealTypeDocument = gql`
    mutation AddMealType($data: [MealType_insert_input!]!) {
  insert_MealType(objects: $data) {
    returning {
      ...MealTypeFull
    }
  }
}
    ${MealTypeFullFragmentDoc}`;
export type AddMealTypeMutationFn = ApolloReactCommon.MutationFunction<AddMealTypeMutation, AddMealTypeMutationVariables>;

/**
 * __useAddMealTypeMutation__
 *
 * To run a mutation, you first call `useAddMealTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMealTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMealTypeMutation, { data, loading, error }] = useAddMealTypeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddMealTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddMealTypeMutation, AddMealTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddMealTypeMutation, AddMealTypeMutationVariables>(AddMealTypeDocument, options);
      }
export type AddMealTypeMutationHookResult = ReturnType<typeof useAddMealTypeMutation>;
export type AddMealTypeMutationResult = ApolloReactCommon.MutationResult<AddMealTypeMutation>;
export type AddMealTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<AddMealTypeMutation, AddMealTypeMutationVariables>;
export const EditMealTypeDocument = gql`
    mutation EditMealType($id: uuid, $data: MealType_set_input) {
  update_MealType(where: {id: {_eq: $id}}, _set: $data) {
    returning {
      ...MealTypeFull
    }
  }
}
    ${MealTypeFullFragmentDoc}`;
export type EditMealTypeMutationFn = ApolloReactCommon.MutationFunction<EditMealTypeMutation, EditMealTypeMutationVariables>;

/**
 * __useEditMealTypeMutation__
 *
 * To run a mutation, you first call `useEditMealTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMealTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMealTypeMutation, { data, loading, error }] = useEditMealTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditMealTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditMealTypeMutation, EditMealTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditMealTypeMutation, EditMealTypeMutationVariables>(EditMealTypeDocument, options);
      }
export type EditMealTypeMutationHookResult = ReturnType<typeof useEditMealTypeMutation>;
export type EditMealTypeMutationResult = ApolloReactCommon.MutationResult<EditMealTypeMutation>;
export type EditMealTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<EditMealTypeMutation, EditMealTypeMutationVariables>;
export const ArchiveMealTypeDocument = gql`
    mutation ArchiveMealType($archived: timestamptz!, $id: uuid!) {
  update_MealType(where: {id: {_eq: $id}}, _set: {archived: $archived}) {
    returning {
      name
      id
      createdAt
      archived
    }
  }
}
    `;
export type ArchiveMealTypeMutationFn = ApolloReactCommon.MutationFunction<ArchiveMealTypeMutation, ArchiveMealTypeMutationVariables>;

/**
 * __useArchiveMealTypeMutation__
 *
 * To run a mutation, you first call `useArchiveMealTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveMealTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveMealTypeMutation, { data, loading, error }] = useArchiveMealTypeMutation({
 *   variables: {
 *      archived: // value for 'archived'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveMealTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ArchiveMealTypeMutation, ArchiveMealTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ArchiveMealTypeMutation, ArchiveMealTypeMutationVariables>(ArchiveMealTypeDocument, options);
      }
export type ArchiveMealTypeMutationHookResult = ReturnType<typeof useArchiveMealTypeMutation>;
export type ArchiveMealTypeMutationResult = ApolloReactCommon.MutationResult<ArchiveMealTypeMutation>;
export type ArchiveMealTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<ArchiveMealTypeMutation, ArchiveMealTypeMutationVariables>;
export const CreateOrderDocument = gql`
    mutation CreateOrder($data: [Order_insert_input!]!) {
  insert_Order(objects: $data) {
    returning {
      id
    }
  }
}
    `;
export type CreateOrderMutationFn = ApolloReactCommon.MutationFunction<CreateOrderMutation, CreateOrderMutationVariables>;

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOrderMutation, CreateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(CreateOrderDocument, options);
      }
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>;
export type CreateOrderMutationResult = ApolloReactCommon.MutationResult<CreateOrderMutation>;
export type CreateOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOrderMutation, CreateOrderMutationVariables>;
export const EditOrderDocument = gql`
    mutation EditOrder($id: uuid!, $data: Order_set_input!) {
  update_Order(where: {id: {_eq: $id}}, _set: $data) {
    returning {
      id
    }
  }
}
    `;
export type EditOrderMutationFn = ApolloReactCommon.MutationFunction<EditOrderMutation, EditOrderMutationVariables>;

/**
 * __useEditOrderMutation__
 *
 * To run a mutation, you first call `useEditOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOrderMutation, { data, loading, error }] = useEditOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditOrderMutation, EditOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditOrderMutation, EditOrderMutationVariables>(EditOrderDocument, options);
      }
export type EditOrderMutationHookResult = ReturnType<typeof useEditOrderMutation>;
export type EditOrderMutationResult = ApolloReactCommon.MutationResult<EditOrderMutation>;
export type EditOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<EditOrderMutation, EditOrderMutationVariables>;
export const ArchiveOrderDocument = gql`
    mutation ArchiveOrder($archived: timestamptz!, $id: uuid!) {
  update_Order(where: {id: {_eq: $id}}, _set: {archived: $archived}) {
    returning {
      id
      archived
    }
  }
}
    `;
export type ArchiveOrderMutationFn = ApolloReactCommon.MutationFunction<ArchiveOrderMutation, ArchiveOrderMutationVariables>;

/**
 * __useArchiveOrderMutation__
 *
 * To run a mutation, you first call `useArchiveOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveOrderMutation, { data, loading, error }] = useArchiveOrderMutation({
 *   variables: {
 *      archived: // value for 'archived'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ArchiveOrderMutation, ArchiveOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ArchiveOrderMutation, ArchiveOrderMutationVariables>(ArchiveOrderDocument, options);
      }
export type ArchiveOrderMutationHookResult = ReturnType<typeof useArchiveOrderMutation>;
export type ArchiveOrderMutationResult = ApolloReactCommon.MutationResult<ArchiveOrderMutation>;
export type ArchiveOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<ArchiveOrderMutation, ArchiveOrderMutationVariables>;
export const AddRouteDocument = gql`
    mutation AddRoute($name: String!, $loadingOrder: Int!, $truckNumber: Int!) {
  insert_Route(
    objects: {name: $name, loadingOrder: $loadingOrder, defaultTruckNumber: $truckNumber}
  ) {
    returning {
      archived
      createdAt
      id
      name
    }
  }
}
    `;
export type AddRouteMutationFn = ApolloReactCommon.MutationFunction<AddRouteMutation, AddRouteMutationVariables>;

/**
 * __useAddRouteMutation__
 *
 * To run a mutation, you first call `useAddRouteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRouteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRouteMutation, { data, loading, error }] = useAddRouteMutation({
 *   variables: {
 *      name: // value for 'name'
 *      loadingOrder: // value for 'loadingOrder'
 *      truckNumber: // value for 'truckNumber'
 *   },
 * });
 */
export function useAddRouteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddRouteMutation, AddRouteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddRouteMutation, AddRouteMutationVariables>(AddRouteDocument, options);
      }
export type AddRouteMutationHookResult = ReturnType<typeof useAddRouteMutation>;
export type AddRouteMutationResult = ApolloReactCommon.MutationResult<AddRouteMutation>;
export type AddRouteMutationOptions = ApolloReactCommon.BaseMutationOptions<AddRouteMutation, AddRouteMutationVariables>;
export const ArchiveRouteDocument = gql`
    mutation ArchiveRoute($id: uuid!, $loadingOrder: Int!, $archived: timestamptz!) {
  archiveRoute: update_Route(
    where: {id: {_eq: $id}}
    _set: {loadingOrder: null, archived: $archived}
  ) {
    affected_rows
  }
  updateLoadingOrder: update_Route(
    where: {loadingOrder: {_gt: $loadingOrder, _is_null: false}}
    _inc: {loadingOrder: -1}
  ) {
    affected_rows
  }
}
    `;
export type ArchiveRouteMutationFn = ApolloReactCommon.MutationFunction<ArchiveRouteMutation, ArchiveRouteMutationVariables>;

/**
 * __useArchiveRouteMutation__
 *
 * To run a mutation, you first call `useArchiveRouteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveRouteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveRouteMutation, { data, loading, error }] = useArchiveRouteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      loadingOrder: // value for 'loadingOrder'
 *      archived: // value for 'archived'
 *   },
 * });
 */
export function useArchiveRouteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ArchiveRouteMutation, ArchiveRouteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ArchiveRouteMutation, ArchiveRouteMutationVariables>(ArchiveRouteDocument, options);
      }
export type ArchiveRouteMutationHookResult = ReturnType<typeof useArchiveRouteMutation>;
export type ArchiveRouteMutationResult = ApolloReactCommon.MutationResult<ArchiveRouteMutation>;
export type ArchiveRouteMutationOptions = ApolloReactCommon.BaseMutationOptions<ArchiveRouteMutation, ArchiveRouteMutationVariables>;
export const EditRouteTruckNumberDocument = gql`
    mutation EditRouteTruckNumber($id: uuid!, $truckNumber: Int!) {
  update_Route(where: {id: {_eq: $id}}, _set: {defaultTruckNumber: $truckNumber}) {
    affected_rows
  }
}
    `;
export type EditRouteTruckNumberMutationFn = ApolloReactCommon.MutationFunction<EditRouteTruckNumberMutation, EditRouteTruckNumberMutationVariables>;

/**
 * __useEditRouteTruckNumberMutation__
 *
 * To run a mutation, you first call `useEditRouteTruckNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRouteTruckNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRouteTruckNumberMutation, { data, loading, error }] = useEditRouteTruckNumberMutation({
 *   variables: {
 *      id: // value for 'id'
 *      truckNumber: // value for 'truckNumber'
 *   },
 * });
 */
export function useEditRouteTruckNumberMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditRouteTruckNumberMutation, EditRouteTruckNumberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EditRouteTruckNumberMutation, EditRouteTruckNumberMutationVariables>(EditRouteTruckNumberDocument, options);
      }
export type EditRouteTruckNumberMutationHookResult = ReturnType<typeof useEditRouteTruckNumberMutation>;
export type EditRouteTruckNumberMutationResult = ApolloReactCommon.MutationResult<EditRouteTruckNumberMutation>;
export type EditRouteTruckNumberMutationOptions = ApolloReactCommon.BaseMutationOptions<EditRouteTruckNumberMutation, EditRouteTruckNumberMutationVariables>;
export const IncrementRouteLoadingOrderDocument = gql`
    mutation IncrementRouteLoadingOrder($curr: Int, $prev: Int) {
  setPrev: update_Route(
    where: {loadingOrder: {_eq: $prev}}
    _set: {loadingOrder: 0}
  ) {
    affected_rows
  }
  updateCurr: update_Route(
    where: {loadingOrder: {_eq: $curr}}
    _inc: {loadingOrder: -1}
  ) {
    affected_rows
  }
  updatePrev: update_Route(
    where: {loadingOrder: {_eq: 0}}
    _set: {loadingOrder: $curr}
  ) {
    affected_rows
  }
}
    `;
export type IncrementRouteLoadingOrderMutationFn = ApolloReactCommon.MutationFunction<IncrementRouteLoadingOrderMutation, IncrementRouteLoadingOrderMutationVariables>;

/**
 * __useIncrementRouteLoadingOrderMutation__
 *
 * To run a mutation, you first call `useIncrementRouteLoadingOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIncrementRouteLoadingOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [incrementRouteLoadingOrderMutation, { data, loading, error }] = useIncrementRouteLoadingOrderMutation({
 *   variables: {
 *      curr: // value for 'curr'
 *      prev: // value for 'prev'
 *   },
 * });
 */
export function useIncrementRouteLoadingOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IncrementRouteLoadingOrderMutation, IncrementRouteLoadingOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<IncrementRouteLoadingOrderMutation, IncrementRouteLoadingOrderMutationVariables>(IncrementRouteLoadingOrderDocument, options);
      }
export type IncrementRouteLoadingOrderMutationHookResult = ReturnType<typeof useIncrementRouteLoadingOrderMutation>;
export type IncrementRouteLoadingOrderMutationResult = ApolloReactCommon.MutationResult<IncrementRouteLoadingOrderMutation>;
export type IncrementRouteLoadingOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<IncrementRouteLoadingOrderMutation, IncrementRouteLoadingOrderMutationVariables>;
export const DecrementRouteLoadingOrderDocument = gql`
    mutation DecrementRouteLoadingOrder($curr: Int, $next: Int) {
  setNext: update_Route(
    where: {loadingOrder: {_eq: $next}}
    _set: {loadingOrder: 0}
  ) {
    affected_rows
  }
  updateCurr: update_Route(
    where: {loadingOrder: {_eq: $curr}}
    _inc: {loadingOrder: 1}
  ) {
    affected_rows
  }
  updateNext: update_Route(
    where: {loadingOrder: {_eq: 0}}
    _set: {loadingOrder: $curr}
  ) {
    affected_rows
  }
}
    `;
export type DecrementRouteLoadingOrderMutationFn = ApolloReactCommon.MutationFunction<DecrementRouteLoadingOrderMutation, DecrementRouteLoadingOrderMutationVariables>;

/**
 * __useDecrementRouteLoadingOrderMutation__
 *
 * To run a mutation, you first call `useDecrementRouteLoadingOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDecrementRouteLoadingOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [decrementRouteLoadingOrderMutation, { data, loading, error }] = useDecrementRouteLoadingOrderMutation({
 *   variables: {
 *      curr: // value for 'curr'
 *      next: // value for 'next'
 *   },
 * });
 */
export function useDecrementRouteLoadingOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DecrementRouteLoadingOrderMutation, DecrementRouteLoadingOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DecrementRouteLoadingOrderMutation, DecrementRouteLoadingOrderMutationVariables>(DecrementRouteLoadingOrderDocument, options);
      }
export type DecrementRouteLoadingOrderMutationHookResult = ReturnType<typeof useDecrementRouteLoadingOrderMutation>;
export type DecrementRouteLoadingOrderMutationResult = ApolloReactCommon.MutationResult<DecrementRouteLoadingOrderMutation>;
export type DecrementRouteLoadingOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<DecrementRouteLoadingOrderMutation, DecrementRouteLoadingOrderMutationVariables>;
export const IncrementSiteDeliveryOrderDocument = gql`
    mutation IncrementSiteDeliveryOrder($curr: Int, $prev: Int, $routeId: uuid!) {
  setPrev: update_RouteDeliverySite(
    where: {routeId: {_eq: $routeId}, siteDeliveryOrder: {_eq: $prev}}
    _set: {siteDeliveryOrder: 0}
  ) {
    affected_rows
  }
  updateCurr: update_RouteDeliverySite(
    where: {routeId: {_eq: $routeId}, siteDeliveryOrder: {_eq: $curr}}
    _inc: {siteDeliveryOrder: -1}
  ) {
    affected_rows
  }
  updatePrev: update_RouteDeliverySite(
    where: {routeId: {_eq: $routeId}, siteDeliveryOrder: {_eq: 0}}
    _set: {siteDeliveryOrder: $curr}
  ) {
    affected_rows
  }
}
    `;
export type IncrementSiteDeliveryOrderMutationFn = ApolloReactCommon.MutationFunction<IncrementSiteDeliveryOrderMutation, IncrementSiteDeliveryOrderMutationVariables>;

/**
 * __useIncrementSiteDeliveryOrderMutation__
 *
 * To run a mutation, you first call `useIncrementSiteDeliveryOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIncrementSiteDeliveryOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [incrementSiteDeliveryOrderMutation, { data, loading, error }] = useIncrementSiteDeliveryOrderMutation({
 *   variables: {
 *      curr: // value for 'curr'
 *      prev: // value for 'prev'
 *      routeId: // value for 'routeId'
 *   },
 * });
 */
export function useIncrementSiteDeliveryOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IncrementSiteDeliveryOrderMutation, IncrementSiteDeliveryOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<IncrementSiteDeliveryOrderMutation, IncrementSiteDeliveryOrderMutationVariables>(IncrementSiteDeliveryOrderDocument, options);
      }
export type IncrementSiteDeliveryOrderMutationHookResult = ReturnType<typeof useIncrementSiteDeliveryOrderMutation>;
export type IncrementSiteDeliveryOrderMutationResult = ApolloReactCommon.MutationResult<IncrementSiteDeliveryOrderMutation>;
export type IncrementSiteDeliveryOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<IncrementSiteDeliveryOrderMutation, IncrementSiteDeliveryOrderMutationVariables>;
export const DecrementSiteDeliveryOrderDocument = gql`
    mutation DecrementSiteDeliveryOrder($curr: Int, $next: Int, $routeId: uuid!) {
  setNext: update_RouteDeliverySite(
    where: {routeId: {_eq: $routeId}, siteDeliveryOrder: {_eq: $next}}
    _set: {siteDeliveryOrder: 0}
  ) {
    affected_rows
  }
  updateCurr: update_RouteDeliverySite(
    where: {routeId: {_eq: $routeId}, siteDeliveryOrder: {_eq: $curr}}
    _inc: {siteDeliveryOrder: 1}
  ) {
    affected_rows
  }
  updateNext: update_RouteDeliverySite(
    where: {routeId: {_eq: $routeId}, siteDeliveryOrder: {_eq: 0}}
    _set: {siteDeliveryOrder: $curr}
  ) {
    affected_rows
  }
}
    `;
export type DecrementSiteDeliveryOrderMutationFn = ApolloReactCommon.MutationFunction<DecrementSiteDeliveryOrderMutation, DecrementSiteDeliveryOrderMutationVariables>;

/**
 * __useDecrementSiteDeliveryOrderMutation__
 *
 * To run a mutation, you first call `useDecrementSiteDeliveryOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDecrementSiteDeliveryOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [decrementSiteDeliveryOrderMutation, { data, loading, error }] = useDecrementSiteDeliveryOrderMutation({
 *   variables: {
 *      curr: // value for 'curr'
 *      next: // value for 'next'
 *      routeId: // value for 'routeId'
 *   },
 * });
 */
export function useDecrementSiteDeliveryOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DecrementSiteDeliveryOrderMutation, DecrementSiteDeliveryOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DecrementSiteDeliveryOrderMutation, DecrementSiteDeliveryOrderMutationVariables>(DecrementSiteDeliveryOrderDocument, options);
      }
export type DecrementSiteDeliveryOrderMutationHookResult = ReturnType<typeof useDecrementSiteDeliveryOrderMutation>;
export type DecrementSiteDeliveryOrderMutationResult = ApolloReactCommon.MutationResult<DecrementSiteDeliveryOrderMutation>;
export type DecrementSiteDeliveryOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<DecrementSiteDeliveryOrderMutation, DecrementSiteDeliveryOrderMutationVariables>;
export const AssignSiteToRouteDocument = gql`
    mutation AssignSiteToRoute($siteDeliveryOrder: Int!, $siteId: String!, $routeId: uuid!) {
  insert_RouteDeliverySite(
    objects: {routeId: $routeId, siteId: $siteId, siteDeliveryOrder: $siteDeliveryOrder}
  ) {
    affected_rows
  }
}
    `;
export type AssignSiteToRouteMutationFn = ApolloReactCommon.MutationFunction<AssignSiteToRouteMutation, AssignSiteToRouteMutationVariables>;

/**
 * __useAssignSiteToRouteMutation__
 *
 * To run a mutation, you first call `useAssignSiteToRouteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignSiteToRouteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignSiteToRouteMutation, { data, loading, error }] = useAssignSiteToRouteMutation({
 *   variables: {
 *      siteDeliveryOrder: // value for 'siteDeliveryOrder'
 *      siteId: // value for 'siteId'
 *      routeId: // value for 'routeId'
 *   },
 * });
 */
export function useAssignSiteToRouteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignSiteToRouteMutation, AssignSiteToRouteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AssignSiteToRouteMutation, AssignSiteToRouteMutationVariables>(AssignSiteToRouteDocument, options);
      }
export type AssignSiteToRouteMutationHookResult = ReturnType<typeof useAssignSiteToRouteMutation>;
export type AssignSiteToRouteMutationResult = ApolloReactCommon.MutationResult<AssignSiteToRouteMutation>;
export type AssignSiteToRouteMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignSiteToRouteMutation, AssignSiteToRouteMutationVariables>;
export const UnassignSiteToRouteDocument = gql`
    mutation UnassignSiteToRoute($id: uuid!, $siteDeliveryOrder: Int!) {
  delete_RouteDeliverySite_by_pk(id: $id) {
    id
  }
  update_RouteDeliverySite(
    where: {siteDeliveryOrder: {_gt: $siteDeliveryOrder, _is_null: false}}
    _inc: {siteDeliveryOrder: -1}
  ) {
    affected_rows
  }
}
    `;
export type UnassignSiteToRouteMutationFn = ApolloReactCommon.MutationFunction<UnassignSiteToRouteMutation, UnassignSiteToRouteMutationVariables>;

/**
 * __useUnassignSiteToRouteMutation__
 *
 * To run a mutation, you first call `useUnassignSiteToRouteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignSiteToRouteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignSiteToRouteMutation, { data, loading, error }] = useUnassignSiteToRouteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      siteDeliveryOrder: // value for 'siteDeliveryOrder'
 *   },
 * });
 */
export function useUnassignSiteToRouteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnassignSiteToRouteMutation, UnassignSiteToRouteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UnassignSiteToRouteMutation, UnassignSiteToRouteMutationVariables>(UnassignSiteToRouteDocument, options);
      }
export type UnassignSiteToRouteMutationHookResult = ReturnType<typeof useUnassignSiteToRouteMutation>;
export type UnassignSiteToRouteMutationResult = ApolloReactCommon.MutationResult<UnassignSiteToRouteMutation>;
export type UnassignSiteToRouteMutationOptions = ApolloReactCommon.BaseMutationOptions<UnassignSiteToRouteMutation, UnassignSiteToRouteMutationVariables>;
export const AuthAzureAdSignInDocument = gql`
    mutation AuthAzureAdSignIn($azureAdAccessToken: String!, $azureAdIdToken: String!) {
  tokens: authAzureAdSignIn(
    azureAdAccessToken: $azureAdAccessToken
    azureAdIdToken: $azureAdIdToken
  ) {
    accessToken
    refreshToken
  }
}
    `;
export type AuthAzureAdSignInMutationFn = ApolloReactCommon.MutationFunction<AuthAzureAdSignInMutation, AuthAzureAdSignInMutationVariables>;

/**
 * __useAuthAzureAdSignInMutation__
 *
 * To run a mutation, you first call `useAuthAzureAdSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthAzureAdSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authAzureAdSignInMutation, { data, loading, error }] = useAuthAzureAdSignInMutation({
 *   variables: {
 *      azureAdAccessToken: // value for 'azureAdAccessToken'
 *      azureAdIdToken: // value for 'azureAdIdToken'
 *   },
 * });
 */
export function useAuthAzureAdSignInMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AuthAzureAdSignInMutation, AuthAzureAdSignInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AuthAzureAdSignInMutation, AuthAzureAdSignInMutationVariables>(AuthAzureAdSignInDocument, options);
      }
export type AuthAzureAdSignInMutationHookResult = ReturnType<typeof useAuthAzureAdSignInMutation>;
export type AuthAzureAdSignInMutationResult = ApolloReactCommon.MutationResult<AuthAzureAdSignInMutation>;
export type AuthAzureAdSignInMutationOptions = ApolloReactCommon.BaseMutationOptions<AuthAzureAdSignInMutation, AuthAzureAdSignInMutationVariables>;
export const AuthSessionRenewDocument = gql`
    mutation AuthSessionRenew($refreshToken: String!) {
  tokens: authSessionRenew(refreshToken: $refreshToken) {
    accessToken
    refreshToken
  }
}
    `;
export type AuthSessionRenewMutationFn = ApolloReactCommon.MutationFunction<AuthSessionRenewMutation, AuthSessionRenewMutationVariables>;

/**
 * __useAuthSessionRenewMutation__
 *
 * To run a mutation, you first call `useAuthSessionRenewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthSessionRenewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authSessionRenewMutation, { data, loading, error }] = useAuthSessionRenewMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useAuthSessionRenewMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AuthSessionRenewMutation, AuthSessionRenewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AuthSessionRenewMutation, AuthSessionRenewMutationVariables>(AuthSessionRenewDocument, options);
      }
export type AuthSessionRenewMutationHookResult = ReturnType<typeof useAuthSessionRenewMutation>;
export type AuthSessionRenewMutationResult = ApolloReactCommon.MutationResult<AuthSessionRenewMutation>;
export type AuthSessionRenewMutationOptions = ApolloReactCommon.BaseMutationOptions<AuthSessionRenewMutation, AuthSessionRenewMutationVariables>;
export const AuthSessionRevokeDocument = gql`
    mutation AuthSessionRevoke($refreshToken: String!) {
  authSessionRevoke(refreshToken: $refreshToken)
}
    `;
export type AuthSessionRevokeMutationFn = ApolloReactCommon.MutationFunction<AuthSessionRevokeMutation, AuthSessionRevokeMutationVariables>;

/**
 * __useAuthSessionRevokeMutation__
 *
 * To run a mutation, you first call `useAuthSessionRevokeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthSessionRevokeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authSessionRevokeMutation, { data, loading, error }] = useAuthSessionRevokeMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useAuthSessionRevokeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AuthSessionRevokeMutation, AuthSessionRevokeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AuthSessionRevokeMutation, AuthSessionRevokeMutationVariables>(AuthSessionRevokeDocument, options);
      }
export type AuthSessionRevokeMutationHookResult = ReturnType<typeof useAuthSessionRevokeMutation>;
export type AuthSessionRevokeMutationResult = ApolloReactCommon.MutationResult<AuthSessionRevokeMutation>;
export type AuthSessionRevokeMutationOptions = ApolloReactCommon.BaseMutationOptions<AuthSessionRevokeMutation, AuthSessionRevokeMutationVariables>;
export const ListOrderStatusEnumDocument = gql`
    query ListOrderStatusEnum {
  orderStatuses: OrderStatusEnum {
    value
    label
  }
}
    `;

/**
 * __useListOrderStatusEnumQuery__
 *
 * To run a query within a React component, call `useListOrderStatusEnumQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOrderStatusEnumQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOrderStatusEnumQuery({
 *   variables: {
 *   },
 * });
 */
export function useListOrderStatusEnumQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListOrderStatusEnumQuery, ListOrderStatusEnumQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ListOrderStatusEnumQuery, ListOrderStatusEnumQueryVariables>(ListOrderStatusEnumDocument, options);
      }
export function useListOrderStatusEnumLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListOrderStatusEnumQuery, ListOrderStatusEnumQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ListOrderStatusEnumQuery, ListOrderStatusEnumQueryVariables>(ListOrderStatusEnumDocument, options);
        }
export type ListOrderStatusEnumQueryHookResult = ReturnType<typeof useListOrderStatusEnumQuery>;
export type ListOrderStatusEnumLazyQueryHookResult = ReturnType<typeof useListOrderStatusEnumLazyQuery>;
export type ListOrderStatusEnumQueryResult = ApolloReactCommon.QueryResult<ListOrderStatusEnumQuery, ListOrderStatusEnumQueryVariables>;
export function refetchListOrderStatusEnumQuery(variables?: ListOrderStatusEnumQueryVariables) {
      return { query: ListOrderStatusEnumDocument, variables: variables }
    }
export const ListRoutesQueryDocument = gql`
    query ListRoutesQuery($limit: Int, $offset: Int, $order_by: [Route_order_by!]) {
  routes: Route(
    where: {archived: {_is_null: true}}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...RouteFull
  }
}
    ${RouteFullFragmentDoc}`;

/**
 * __useListRoutesQueryQuery__
 *
 * To run a query within a React component, call `useListRoutesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useListRoutesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListRoutesQueryQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useListRoutesQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListRoutesQueryQuery, ListRoutesQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ListRoutesQueryQuery, ListRoutesQueryQueryVariables>(ListRoutesQueryDocument, options);
      }
export function useListRoutesQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListRoutesQueryQuery, ListRoutesQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ListRoutesQueryQuery, ListRoutesQueryQueryVariables>(ListRoutesQueryDocument, options);
        }
export type ListRoutesQueryQueryHookResult = ReturnType<typeof useListRoutesQueryQuery>;
export type ListRoutesQueryLazyQueryHookResult = ReturnType<typeof useListRoutesQueryLazyQuery>;
export type ListRoutesQueryQueryResult = ApolloReactCommon.QueryResult<ListRoutesQueryQuery, ListRoutesQueryQueryVariables>;
export function refetchListRoutesQueryQuery(variables?: ListRoutesQueryQueryVariables) {
      return { query: ListRoutesQueryDocument, variables: variables }
    }
export const CountRoutesQueryDocument = gql`
    query CountRoutesQuery {
  count: Route_aggregate(where: {archived: {_is_null: true}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useCountRoutesQueryQuery__
 *
 * To run a query within a React component, call `useCountRoutesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountRoutesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountRoutesQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountRoutesQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CountRoutesQueryQuery, CountRoutesQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CountRoutesQueryQuery, CountRoutesQueryQueryVariables>(CountRoutesQueryDocument, options);
      }
export function useCountRoutesQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CountRoutesQueryQuery, CountRoutesQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CountRoutesQueryQuery, CountRoutesQueryQueryVariables>(CountRoutesQueryDocument, options);
        }
export type CountRoutesQueryQueryHookResult = ReturnType<typeof useCountRoutesQueryQuery>;
export type CountRoutesQueryLazyQueryHookResult = ReturnType<typeof useCountRoutesQueryLazyQuery>;
export type CountRoutesQueryQueryResult = ApolloReactCommon.QueryResult<CountRoutesQueryQuery, CountRoutesQueryQueryVariables>;
export function refetchCountRoutesQueryQuery(variables?: CountRoutesQueryQueryVariables) {
      return { query: CountRoutesQueryDocument, variables: variables }
    }
export const ListSitesDocument = gql`
    query ListSites($limit: Int, $offset: Int, $order: String = "name") {
  sites: Site(offset: $offset, limit: $limit, order: $order) {
    siteId
    name
    address1
    address2
    city
    state
    county
    zip
  }
}
    `;

/**
 * __useListSitesQuery__
 *
 * To run a query within a React component, call `useListSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSitesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useListSitesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListSitesQuery, ListSitesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ListSitesQuery, ListSitesQueryVariables>(ListSitesDocument, options);
      }
export function useListSitesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListSitesQuery, ListSitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ListSitesQuery, ListSitesQueryVariables>(ListSitesDocument, options);
        }
export type ListSitesQueryHookResult = ReturnType<typeof useListSitesQuery>;
export type ListSitesLazyQueryHookResult = ReturnType<typeof useListSitesLazyQuery>;
export type ListSitesQueryResult = ApolloReactCommon.QueryResult<ListSitesQuery, ListSitesQueryVariables>;
export function refetchListSitesQuery(variables?: ListSitesQueryVariables) {
      return { query: ListSitesDocument, variables: variables }
    }
export const OrdersFilterOptionsDocument = gql`
    query OrdersFilterOptions {
  statuses: Order_aggregate(distinct_on: status) {
    nodes {
      status
      statusEnum: OrderStatusEnum {
        label
      }
    }
  }
  siteIds: Order_aggregate(distinct_on: siteId) {
    nodes {
      siteId
      site: Site {
        name
      }
    }
  }
  routeIds: Order_aggregate(distinct_on: routeId) {
    nodes {
      routeId
      route: Route {
        name
      }
    }
  }
  truckNumbers: Order_aggregate(distinct_on: truckNumber) {
    nodes {
      truckNumber
    }
  }
  mealTypeIds: Order_aggregate(distinct_on: mealTypeId) {
    nodes {
      mealTypeId
      mealType: MealType {
        name
      }
    }
  }
  deliveryDays: Order_aggregate(distinct_on: deliveryDay) {
    nodes {
      deliveryDay
    }
  }
}
    `;

/**
 * __useOrdersFilterOptionsQuery__
 *
 * To run a query within a React component, call `useOrdersFilterOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersFilterOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersFilterOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrdersFilterOptionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrdersFilterOptionsQuery, OrdersFilterOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<OrdersFilterOptionsQuery, OrdersFilterOptionsQueryVariables>(OrdersFilterOptionsDocument, options);
      }
export function useOrdersFilterOptionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrdersFilterOptionsQuery, OrdersFilterOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<OrdersFilterOptionsQuery, OrdersFilterOptionsQueryVariables>(OrdersFilterOptionsDocument, options);
        }
export type OrdersFilterOptionsQueryHookResult = ReturnType<typeof useOrdersFilterOptionsQuery>;
export type OrdersFilterOptionsLazyQueryHookResult = ReturnType<typeof useOrdersFilterOptionsLazyQuery>;
export type OrdersFilterOptionsQueryResult = ApolloReactCommon.QueryResult<OrdersFilterOptionsQuery, OrdersFilterOptionsQueryVariables>;
export function refetchOrdersFilterOptionsQuery(variables?: OrdersFilterOptionsQueryVariables) {
      return { query: OrdersFilterOptionsDocument, variables: variables }
    }
export const GetUserDocument = gql`
    query GetUser($userId: uuid!) {
  user: User_by_pk(id: $userId) {
    id
    firstName
    lastName
    createdAt
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = ApolloReactCommon.QueryResult<GetUserQuery, GetUserQueryVariables>;
export function refetchGetUserQuery(variables: GetUserQueryVariables) {
      return { query: GetUserDocument, variables: variables }
    }
export const GetSitesOptionsDocument = gql`
    query GetSitesOptions($whereFilters: SequelizeJSON) {
  sites: Site(where: $whereFilters, order: "name") {
    value: siteId
    label: name
  }
}
    `;

/**
 * __useGetSitesOptionsQuery__
 *
 * To run a query within a React component, call `useGetSitesOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSitesOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSitesOptionsQuery({
 *   variables: {
 *      whereFilters: // value for 'whereFilters'
 *   },
 * });
 */
export function useGetSitesOptionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSitesOptionsQuery, GetSitesOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetSitesOptionsQuery, GetSitesOptionsQueryVariables>(GetSitesOptionsDocument, options);
      }
export function useGetSitesOptionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSitesOptionsQuery, GetSitesOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetSitesOptionsQuery, GetSitesOptionsQueryVariables>(GetSitesOptionsDocument, options);
        }
export type GetSitesOptionsQueryHookResult = ReturnType<typeof useGetSitesOptionsQuery>;
export type GetSitesOptionsLazyQueryHookResult = ReturnType<typeof useGetSitesOptionsLazyQuery>;
export type GetSitesOptionsQueryResult = ApolloReactCommon.QueryResult<GetSitesOptionsQuery, GetSitesOptionsQueryVariables>;
export function refetchGetSitesOptionsQuery(variables?: GetSitesOptionsQueryVariables) {
      return { query: GetSitesOptionsDocument, variables: variables }
    }
export const GetRoutesOptionsDocument = gql`
    query GetRoutesOptions($whereFilters: Route_bool_exp!) {
  routes: Route(where: {_and: [$whereFilters, {archived: {_is_null: true}}]}) {
    value: id
    label: name
  }
}
    `;

/**
 * __useGetRoutesOptionsQuery__
 *
 * To run a query within a React component, call `useGetRoutesOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoutesOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoutesOptionsQuery({
 *   variables: {
 *      whereFilters: // value for 'whereFilters'
 *   },
 * });
 */
export function useGetRoutesOptionsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetRoutesOptionsQuery, GetRoutesOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetRoutesOptionsQuery, GetRoutesOptionsQueryVariables>(GetRoutesOptionsDocument, options);
      }
export function useGetRoutesOptionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRoutesOptionsQuery, GetRoutesOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetRoutesOptionsQuery, GetRoutesOptionsQueryVariables>(GetRoutesOptionsDocument, options);
        }
export type GetRoutesOptionsQueryHookResult = ReturnType<typeof useGetRoutesOptionsQuery>;
export type GetRoutesOptionsLazyQueryHookResult = ReturnType<typeof useGetRoutesOptionsLazyQuery>;
export type GetRoutesOptionsQueryResult = ApolloReactCommon.QueryResult<GetRoutesOptionsQuery, GetRoutesOptionsQueryVariables>;
export function refetchGetRoutesOptionsQuery(variables: GetRoutesOptionsQueryVariables) {
      return { query: GetRoutesOptionsDocument, variables: variables }
    }
export const GetMealTypesOptionsDocument = gql`
    query GetMealTypesOptions($whereFilters: MealType_bool_exp!) {
  mealTypes: MealType(
    where: {_and: [$whereFilters, {archived: {_is_null: true}}]}
  ) {
    value: id
    label: name
  }
}
    `;

/**
 * __useGetMealTypesOptionsQuery__
 *
 * To run a query within a React component, call `useGetMealTypesOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMealTypesOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMealTypesOptionsQuery({
 *   variables: {
 *      whereFilters: // value for 'whereFilters'
 *   },
 * });
 */
export function useGetMealTypesOptionsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetMealTypesOptionsQuery, GetMealTypesOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetMealTypesOptionsQuery, GetMealTypesOptionsQueryVariables>(GetMealTypesOptionsDocument, options);
      }
export function useGetMealTypesOptionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMealTypesOptionsQuery, GetMealTypesOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetMealTypesOptionsQuery, GetMealTypesOptionsQueryVariables>(GetMealTypesOptionsDocument, options);
        }
export type GetMealTypesOptionsQueryHookResult = ReturnType<typeof useGetMealTypesOptionsQuery>;
export type GetMealTypesOptionsLazyQueryHookResult = ReturnType<typeof useGetMealTypesOptionsLazyQuery>;
export type GetMealTypesOptionsQueryResult = ApolloReactCommon.QueryResult<GetMealTypesOptionsQuery, GetMealTypesOptionsQueryVariables>;
export function refetchGetMealTypesOptionsQuery(variables: GetMealTypesOptionsQueryVariables) {
      return { query: GetMealTypesOptionsDocument, variables: variables }
    }
export const GetOrderStatusEnumOptionsDocument = gql`
    query GetOrderStatusEnumOptions($whereFilters: OrderStatusEnum_bool_exp) {
  orderStatuses: OrderStatusEnum(where: $whereFilters) {
    value
    label
  }
}
    `;

/**
 * __useGetOrderStatusEnumOptionsQuery__
 *
 * To run a query within a React component, call `useGetOrderStatusEnumOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderStatusEnumOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderStatusEnumOptionsQuery({
 *   variables: {
 *      whereFilters: // value for 'whereFilters'
 *   },
 * });
 */
export function useGetOrderStatusEnumOptionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOrderStatusEnumOptionsQuery, GetOrderStatusEnumOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetOrderStatusEnumOptionsQuery, GetOrderStatusEnumOptionsQueryVariables>(GetOrderStatusEnumOptionsDocument, options);
      }
export function useGetOrderStatusEnumOptionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOrderStatusEnumOptionsQuery, GetOrderStatusEnumOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetOrderStatusEnumOptionsQuery, GetOrderStatusEnumOptionsQueryVariables>(GetOrderStatusEnumOptionsDocument, options);
        }
export type GetOrderStatusEnumOptionsQueryHookResult = ReturnType<typeof useGetOrderStatusEnumOptionsQuery>;
export type GetOrderStatusEnumOptionsLazyQueryHookResult = ReturnType<typeof useGetOrderStatusEnumOptionsLazyQuery>;
export type GetOrderStatusEnumOptionsQueryResult = ApolloReactCommon.QueryResult<GetOrderStatusEnumOptionsQuery, GetOrderStatusEnumOptionsQueryVariables>;
export function refetchGetOrderStatusEnumOptionsQuery(variables?: GetOrderStatusEnumOptionsQueryVariables) {
      return { query: GetOrderStatusEnumOptionsDocument, variables: variables }
    }
export const GetOrderByIdDocument = gql`
    query GetOrderById($id: uuid!) {
  order: Order_by_pk(id: $id) {
    ...OrderFull
  }
}
    ${OrderFullFragmentDoc}`;

/**
 * __useGetOrderByIdQuery__
 *
 * To run a query within a React component, call `useGetOrderByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrderByIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetOrderByIdQuery, GetOrderByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetOrderByIdQuery, GetOrderByIdQueryVariables>(GetOrderByIdDocument, options);
      }
export function useGetOrderByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOrderByIdQuery, GetOrderByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetOrderByIdQuery, GetOrderByIdQueryVariables>(GetOrderByIdDocument, options);
        }
export type GetOrderByIdQueryHookResult = ReturnType<typeof useGetOrderByIdQuery>;
export type GetOrderByIdLazyQueryHookResult = ReturnType<typeof useGetOrderByIdLazyQuery>;
export type GetOrderByIdQueryResult = ApolloReactCommon.QueryResult<GetOrderByIdQuery, GetOrderByIdQueryVariables>;
export function refetchGetOrderByIdQuery(variables: GetOrderByIdQueryVariables) {
      return { query: GetOrderByIdDocument, variables: variables }
    }
export const CountOrdersAndMealsDocument = gql`
    subscription CountOrdersAndMeals($where: Order_bool_exp = {archived: {_is_null: true}}) {
  orders: Order_aggregate(where: $where) {
    aggregate {
      count
      sum {
        quantityOrdered
        quantityLoaded
      }
    }
  }
}
    `;

/**
 * __useCountOrdersAndMealsSubscription__
 *
 * To run a query within a React component, call `useCountOrdersAndMealsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCountOrdersAndMealsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountOrdersAndMealsSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCountOrdersAndMealsSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<CountOrdersAndMealsSubscription, CountOrdersAndMealsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<CountOrdersAndMealsSubscription, CountOrdersAndMealsSubscriptionVariables>(CountOrdersAndMealsDocument, options);
      }
export type CountOrdersAndMealsSubscriptionHookResult = ReturnType<typeof useCountOrdersAndMealsSubscription>;
export type CountOrdersAndMealsSubscriptionResult = ApolloReactCommon.SubscriptionResult<CountOrdersAndMealsSubscription>;
export const ListMealTypesDocument = gql`
    subscription ListMealTypes($limit: Int, $offset: Int, $order_by: [MealType_order_by!]) {
  mealTypes: MealType(
    where: {archived: {_is_null: true}}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...MealTypeFull
  }
}
    ${MealTypeFullFragmentDoc}`;

/**
 * __useListMealTypesSubscription__
 *
 * To run a query within a React component, call `useListMealTypesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListMealTypesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMealTypesSubscription({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useListMealTypesSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<ListMealTypesSubscription, ListMealTypesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<ListMealTypesSubscription, ListMealTypesSubscriptionVariables>(ListMealTypesDocument, options);
      }
export type ListMealTypesSubscriptionHookResult = ReturnType<typeof useListMealTypesSubscription>;
export type ListMealTypesSubscriptionResult = ApolloReactCommon.SubscriptionResult<ListMealTypesSubscription>;
export const CountMealTypesDocument = gql`
    subscription CountMealTypes {
  count: MealType_aggregate(where: {archived: {_is_null: true}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useCountMealTypesSubscription__
 *
 * To run a query within a React component, call `useCountMealTypesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCountMealTypesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountMealTypesSubscription({
 *   variables: {
 *   },
 * });
 */
export function useCountMealTypesSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<CountMealTypesSubscription, CountMealTypesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<CountMealTypesSubscription, CountMealTypesSubscriptionVariables>(CountMealTypesDocument, options);
      }
export type CountMealTypesSubscriptionHookResult = ReturnType<typeof useCountMealTypesSubscription>;
export type CountMealTypesSubscriptionResult = ApolloReactCommon.SubscriptionResult<CountMealTypesSubscription>;
export const ListRoutesDocument = gql`
    subscription ListRoutes($limit: Int, $offset: Int, $order_by: [Route_order_by!]) {
  routes: Route(
    where: {archived: {_is_null: true}}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...RouteFull
    sites: RouteDeliverySites_aggregate {
      aggregate {
        count
      }
    }
  }
}
    ${RouteFullFragmentDoc}`;

/**
 * __useListRoutesSubscription__
 *
 * To run a query within a React component, call `useListRoutesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListRoutesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListRoutesSubscription({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useListRoutesSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<ListRoutesSubscription, ListRoutesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<ListRoutesSubscription, ListRoutesSubscriptionVariables>(ListRoutesDocument, options);
      }
export type ListRoutesSubscriptionHookResult = ReturnType<typeof useListRoutesSubscription>;
export type ListRoutesSubscriptionResult = ApolloReactCommon.SubscriptionResult<ListRoutesSubscription>;
export const CountRoutesDocument = gql`
    subscription CountRoutes {
  count: Route_aggregate(where: {archived: {_is_null: true}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useCountRoutesSubscription__
 *
 * To run a query within a React component, call `useCountRoutesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCountRoutesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountRoutesSubscription({
 *   variables: {
 *   },
 * });
 */
export function useCountRoutesSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<CountRoutesSubscription, CountRoutesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<CountRoutesSubscription, CountRoutesSubscriptionVariables>(CountRoutesDocument, options);
      }
export type CountRoutesSubscriptionHookResult = ReturnType<typeof useCountRoutesSubscription>;
export type CountRoutesSubscriptionResult = ApolloReactCommon.SubscriptionResult<CountRoutesSubscription>;
export const ListRouteSitesDocument = gql`
    subscription ListRouteSites($routeId: uuid, $order_by: [RouteDeliverySite_order_by!]) {
  sites: RouteDeliverySite(where: {routeId: {_eq: $routeId}}, order_by: $order_by) {
    id
    siteId
    siteDeliveryOrder
    routeId
  }
}
    `;

/**
 * __useListRouteSitesSubscription__
 *
 * To run a query within a React component, call `useListRouteSitesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListRouteSitesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListRouteSitesSubscription({
 *   variables: {
 *      routeId: // value for 'routeId'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useListRouteSitesSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<ListRouteSitesSubscription, ListRouteSitesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<ListRouteSitesSubscription, ListRouteSitesSubscriptionVariables>(ListRouteSitesDocument, options);
      }
export type ListRouteSitesSubscriptionHookResult = ReturnType<typeof useListRouteSitesSubscription>;
export type ListRouteSitesSubscriptionResult = ApolloReactCommon.SubscriptionResult<ListRouteSitesSubscription>;
export const ListOrdersDocument = gql`
    subscription ListOrders($limit: Int, $offset: Int, $order_by: [Order_order_by!], $where: Order_bool_exp = {archived: {_is_null: true}}) {
  orders: Order(
    where: $where
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    id
    applicationId
    siteId
    status: OrderStatusEnum {
      label
      value
    }
    deliveryDay
    truckNumber
    route: Route {
      name
    }
    updatedAt
    mealType: MealType {
      name
    }
    user: User {
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useListOrdersSubscription__
 *
 * To run a query within a React component, call `useListOrdersSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListOrdersSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOrdersSubscription({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useListOrdersSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<ListOrdersSubscription, ListOrdersSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<ListOrdersSubscription, ListOrdersSubscriptionVariables>(ListOrdersDocument, options);
      }
export type ListOrdersSubscriptionHookResult = ReturnType<typeof useListOrdersSubscription>;
export type ListOrdersSubscriptionResult = ApolloReactCommon.SubscriptionResult<ListOrdersSubscription>;
export const CountOrdersDocument = gql`
    subscription CountOrders($where: Order_bool_exp = {archived: {_is_null: true}}) {
  count: Order_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useCountOrdersSubscription__
 *
 * To run a query within a React component, call `useCountOrdersSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCountOrdersSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountOrdersSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCountOrdersSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<CountOrdersSubscription, CountOrdersSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<CountOrdersSubscription, CountOrdersSubscriptionVariables>(CountOrdersDocument, options);
      }
export type CountOrdersSubscriptionHookResult = ReturnType<typeof useCountOrdersSubscription>;
export type CountOrdersSubscriptionResult = ApolloReactCommon.SubscriptionResult<CountOrdersSubscription>;
export const ListUsersDocument = gql`
    subscription ListUsers($limit: Int, $offset: Int, $order_by: [User_order_by!]) {
  users: User(offset: $offset, limit: $limit, order_by: $order_by) {
    id
    firstName
    lastName
    email
    sessionsAggregate: Sessions_aggregate {
      aggregate {
        max {
          lastSeenAt
          role
        }
      }
    }
  }
}
    `;

/**
 * __useListUsersSubscription__
 *
 * To run a query within a React component, call `useListUsersSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListUsersSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUsersSubscription({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useListUsersSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<ListUsersSubscription, ListUsersSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<ListUsersSubscription, ListUsersSubscriptionVariables>(ListUsersDocument, options);
      }
export type ListUsersSubscriptionHookResult = ReturnType<typeof useListUsersSubscription>;
export type ListUsersSubscriptionResult = ApolloReactCommon.SubscriptionResult<ListUsersSubscription>;
export const CountUsersDocument = gql`
    subscription CountUsers {
  count: User_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useCountUsersSubscription__
 *
 * To run a query within a React component, call `useCountUsersSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCountUsersSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountUsersSubscription({
 *   variables: {
 *   },
 * });
 */
export function useCountUsersSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<CountUsersSubscription, CountUsersSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<CountUsersSubscription, CountUsersSubscriptionVariables>(CountUsersDocument, options);
      }
export type CountUsersSubscriptionHookResult = ReturnType<typeof useCountUsersSubscription>;
export type CountUsersSubscriptionResult = ApolloReactCommon.SubscriptionResult<CountUsersSubscription>;