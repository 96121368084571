import { observable } from 'mobx';
import { create } from 'mobx-persist';

import { isProduction, LS_HYDRATE_PREFIX } from '../config';

import { authentication } from './authentication';

export const hydrate = create({
  jsonify: true,
  debounce: 100, //ms
});

export const isReady = observable.box<boolean>(false);

export const readyPromise = hydrate(
  `${LS_HYDRATE_PREFIX}-authentication`,
  authentication,
)
  .then(async (states) => {
    await authentication.setup();
  })
  .then(() => isReady.set(true));

export { authentication };

if (!isProduction) {
  Object.defineProperty(window, 'stores', {
    get() {
      return {
        authentication,
      };
    },
  });
}
