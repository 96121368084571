import 'whatwg-fetch';
import 'react-app-polyfill/stable';
import 'fontsource-roboto';
import 'fontsource-montserrat';
import 'fontsource-raleway';
import 'react-toastify/dist/ReactToastify.css';
import DateIoMomentUtils from '@date-io/moment';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { hot } from 'react-hot-loader/root';
import { ToastContainer, Slide, toast } from 'react-toastify';

import { ModalProvider } from './components/Modal/ModalContext';
import ModalRoot from './components/Modal/ModalRoot';
import { DEVELOPMENT_MODE } from './config';
import { apolloClient } from './hasura/client';
import { Router } from './router';
const theme = createTheme({
  palette: {
    primary: {
      main: '#76b900',
    },
    secondary: {
      main: '#442c79',
    },
  },
  overrides: {
    MUIDataTable: {
      responsiveBase: {
        overflow: 'visible',
      },
    },
    MuiTableRow: {
      root: {
        cursor: 'pointer',
      },
    },
    MUIDataTableHeadCell: {
      sortAction: {
        alignItems: 'center',
      },
    },
    MUIDataTableResize: {
      resizer: {
        border: '0.1px solid rgba(224, 224, 224, .3)',
      },
    },
    MuiInputLabel: {
      outlined: {
        backgroundColor: '#FFF',
        paddingLeft: 2,
        paddingRight: 2,
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: '0.7rem',
      },
    },
  } as any,
});

const App: React.FC = () => {
  return (
    <ApolloProvider client={apolloClient}>
      <ModalProvider>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateIoMomentUtils}>
            <ModalRoot />
            <Router />
          </MuiPickersUtilsProvider>
          <ToastContainer
            autoClose={2000}
            position={toast.POSITION.BOTTOM_RIGHT}
            transition={Slide}
            hideProgressBar
            closeButton={false}
            draggable={false}
            className="toast-container"
            toastClassName="toast"
          />
        </ThemeProvider>
      </ModalProvider>
    </ApolloProvider>
  );
};

export default DEVELOPMENT_MODE ? hot(App) : App;
