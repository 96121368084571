import { useFormik } from 'formik';
import React, { FC } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import {
  useEditOrderMutation,
  OrderFullFragment,
  OrderStatusEnum_enum,
} from '../../../hasura/generated';
import handleToastError from '../../../helpers/handleToastError';

import { OrderForm } from './common/order-form';

export type IOrderEdit = Pick<
  OrderFullFragment,
  | 'deliveryDay'
  | 'id'
  | 'mealTypeId'
  | 'quantityOrdered'
  | 'routeId'
  | 'siteId'
  | 'status'
  | 'truckNumber'
>;

// Edit Entire Order
const validationSchema = Yup.object({
  status: Yup.string().required('Required'),
  mealTypeId: Yup.string().required('Required'),
  siteId: Yup.string().required('Required'),
  routeId: Yup.string().nullable(),
  deliveryDay: Yup.date().required('Required'),
  truckNumber: Yup.number().nullable(),
  quantityOrdered: Yup.number().required('Required'),
});

const initialValues: Partial<IOrderEdit> = {
  status: OrderStatusEnum_enum.draft,
  deliveryDay: new Date(),
  routeId: null,
};

export const EditOrderForm: FC<{
  open: boolean;
  order: Partial<IOrderEdit>;
  onClose: () => void;
}> = ({ order, ...props }) => {
  const [mutation] = useEditOrderMutation({
    onCompleted() {
      toast('Order edited successfully');
    },
    onError(error) {
      handleToastError(error);
    },
  });

  const formik = useFormik<Partial<IOrderEdit>>({
    initialValues: {
      ...initialValues,
      ...order,
    },
    validationSchema,
    onSubmit: async ({
      deliveryDay,
      mealTypeId,
      routeId,
      siteId,
      status,
      truckNumber,
    }) => {
      await mutation({
        variables: {
          id: order?.id || '',
          data: {
            deliveryDay,
            mealTypeId,
            routeId,
            siteId,
            status,
            truckNumber,
          },
        },
      });

      await props.onClose();
    },
  });

  return <OrderForm formik={formik} title="Editing Order" {...props} />;
};
