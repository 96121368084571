import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  CardActions,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AddCircleOutline, Group } from '@material-ui/icons';
import React, { useState } from 'react';

import Header from '../../components/CalendarDatePicker';
import { CreateOrderForm } from '../../components/Forms/Orders/create-order-form';
import { EditOrderForm } from '../../components/Forms/Orders/edit-order-form';
import { Layout } from '../../components/Layout';
import {
  useCountOrdersSubscription,
  useGetOrderByIdQuery,
} from '../../hasura/generated';
import { authentication } from '../../stores';

import { OrdersTable } from './orders-table';

const Orders = () => {
  const currentDate = new Date();
  const oneWeeksDate = new Date(Date.now() + 604800000);
  const [startDate, setStartDate] = useState<any>(currentDate);
  const [endDate, setEndDate] = useState<any>(oneWeeksDate);
  const { data: countRes, loading } = useCountOrdersSubscription({
    variables: {
      where: {
        archived: { _is_null: true },
        deliveryDay: { _gte: startDate, _lte: endDate },
      },
    },
  });

  const orderCount = countRes?.count?.aggregate?.count || 0;

  // const modal = useContext(ModalContext);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editOrderId, setEditOrderId] = useState<string | undefined>(undefined);

  const { data: editOrderData } = useGetOrderByIdQuery({
    variables: { id: editOrderId },
    fetchPolicy: 'network-only',
    skip: !editOrderId,
  });

  const useStyles = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
    cardRoot: {
      height: '100%',
      minHeight: 100,
    },
    cardTitle: {
      fontWeight: 700,
    },
    cardAvatar: {
      backgroundColor: theme.palette.primary.main,
      height: 56,
      width: 56,
    },
    cardIcon: {
      height: 32,
      width: 32,
    },
  }));
  const classes = useStyles();

  return (
    <>
      {createModalOpen && (
        <CreateOrderForm open onClose={() => setCreateModalOpen(false)} />
      )}

      {editOrderData?.order && (
        <EditOrderForm
          open
          order={editOrderData.order}
          onClose={() => setEditOrderId(undefined)}
        />
      )}

      <Layout>
        <div className={classes.root}>
          <Header
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Card className="">
                <CardContent>
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Typography
                        className={classes.cardTitle}
                        color="textSecondary"
                        gutterBottom
                        variant="body2"
                      >
                        ORDERS
                      </Typography>
                      <Typography variant="h3">
                        {loading ? '-' : orderCount}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Avatar className={classes.cardAvatar}>
                        <Group className={classes.cardIcon} />
                      </Avatar>
                    </Grid>
                  </Grid>
                </CardContent>

                <CardActions>
                  {authentication.currentRole === 'KIDS_CAFE_ADMIN' && (
                    <Button
                      color="primary"
                      startIcon={<AddCircleOutline />}
                      onClick={() => setCreateModalOpen(true)}
                    >
                      Add Order
                    </Button>
                  )}
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <OrdersTable
                count={orderCount}
                startDate={startDate}
                endDate={endDate}
                onEdit={(id) => setEditOrderId(id)}
              />
            </Grid>
          </Grid>
        </div>
      </Layout>
    </>
  );
};

export default Orders;
