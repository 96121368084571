import { setContext } from 'apollo-link-context';

import { authentication, readyPromise } from '../../stores';

const authLink = setContext(async (operation, { headers }) => {
  // in case we're sending over a refresh token, we don't want to send any auth headers
  if (operation?.variables?.refreshToken) {
    return {
      headers: {},
    };
  }

  await readyPromise;

  return {
    headers: await authentication.getHeaders(headers),
  };
});

export default authLink;
